.block-contactinfo {
  &.contact-info {
    @include breakpoint($desktop) {
      padding: 32px;
    }
  }

  .contact-info {
    &__title {
      @include fontSize(24px);
      line-height: 32px;
      color: $headingText;
      @include breakpoint($desktop) {
        @include fontSize(32px);
        line-height: 40px;
      }
    }
    &__row {
      color: $text;
      margin-top: 32px;
      .contact-info__title {
        @include fontSize(16px);
        font-weight: $font-bold;
        letter-spacing: 0;
        line-height: 24px;
        margin-bottom: 8px;
      }
    }
    &__info {
      margin-bottom: 8px;
      &,
      a {
        @include fontSize(20px);
        @include breakpoint($desktop) {
          @include fontSize(24px);
        }
        letter-spacing: 0;
        line-height: 32px;
      }
    }
    &__text {
      @include fontSize(14px);
      letter-spacing: 0;
      line-height: 22px;
    }
  }
}
