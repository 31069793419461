.cms-no-route {
  .noroute-container {
    margin: 80px 0;
    @include breakpoint($desktop-mid) {
      margin: 150px 0;
    }
    text-align: center;
    h2 {
      @include fontSize(32px);
      @include breakpoint($desktop-mid) {
        @include fontSize(48px);
      }
    }
    p {
      margin: 24px 0 0;
    }
    a {
      text-decoration: underline;
    }
  }
  .popular-categories-wrapper {
    h3 {
      margin: 0 0 32px;
      @include breakpoint($desktop-mid) {
        margin: 0 0 48px;
      }
    }
  }
}
