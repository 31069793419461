.mageplaza {
  &-frequently-bought-together-block {
    margin: 64px 0 0;
    background: get-color(ui-dark, dark-10);
    border-radius: 4px;
    padding: 32px 27px;

    @include breakpoint($desktop-mid) {
      padding: 32px;
    }

    h4 {
      text-align: center;
      margin: 0 0 24px;
    }
  }

  &-fbt-rows {
    display: none;
  }

  &-fbt-image-box {
    .mageplaza-grid-parent {
      display: grid;
      grid-auto-flow: row;
 
      @include breakpoint($desktop) {
        grid-auto-flow: column;
      }
    }

    .item {
      background: get-color(ui-white);
      padding: 16px;
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      @include fontSize(12px);
      color: $primary;
      letter-spacing: 0;
      border: solid 1px get-color(ui-dark, dark-20);
      line-height: 16px;

      @include breakpoint($desktop-mid) {
        min-height: 112px;
        max-height: 115px;
        padding: 16px 22px;
      }

      a {
        @include fontSize(12px);
        line-height: 16px;
      }
    }

    .product-item-plus {
      align-self: center;

      &:last-of-type {
        margin-right: 0;
      }
    }

    .product-image-photo {
      width: 64px;
      height: auto;
      max-height: 64px;
      margin: 0 16px 0 0;
    }
  }

  &-fbt-price-box {
    margin: 0;
    background: get-color(ui-white);
    padding: 16px;
    border-radius: 4px;
    border: solid 1px get-color(ui-dark, dark-20);
    text-align: center;
    width: 100%;
  
    @include breakpoint($desktop-mid) {
      padding: 16px 22px;
    }

    @include breakpoint($desktop) {
      margin-top: 10px;
      width: 50%;
      margin: 10px auto 0;
    }

    button {
      width: 100%;
      margin: 12px 0 0;
      padding: 12px 22px;
      
      @include breakpoint($desktop-mid) {
        padding: 12px 32px;
      }
    }
  }

  &-fbt-total-price {
    font-weight: $font-bold;
  }

  &-fbt-plus {
    @include fontSize(16px);
    margin: 4px 8px;
    text-align: center;
  }
}
