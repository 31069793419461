//
// Just UI Sheet specific styles excluding elements.
// General clean up for the page only
//

.uisheet-index-index {
  .page-header,
  .nav-sections,
  .page-footer {
    display: none;
  }

  .columns {
    margin-bottom: 64px;
  }
}

.section {
  &--header {
    @include fontSize(24px);
    color: #999c9f;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  &--row {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    border-top: 1px solid #dedede;
    padding: 16px 0;

    @include breakpoint($desktop) {
      align-items: center;
      flex-direction: row;
    }

    &:first-of-type {
      border-bottom: 1px solid #dedede;
    }

    button {
      margin-right: 8px;
      margin-bottom: 16px;

      &:last-of-type {
        margin-right: 0;
        margin-bottom: 0;
      }
    }
  }

  &--label {
    @include fontSize(14px);
    color: #C4CDD5;
    text-transform: uppercase;
    margin-bottom: 8px;

    @include breakpoint($desktop) {
      @include fontSize(12px);
      display: flex;
      justify-content: flex-end;
      margin-bottom: 0;
    }
  }

  &--links {
    a:first-child {
      padding: 8px 24px 8px 0;
    }

    a:last-child {
      padding: 8px 8px 8px 0;
    }
  }
}

.section--row {
  @include breakpoint($desktop) {
    > :first-child {
      width: 80%;
    }

    > :last-child {
      width: 20%;
    }
  }

  &.buttons .actions-toolbar > div {
    margin-bottom: 16px;

    &:first-of-type {
      margin-top: 16px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
