$block-padding: 32px;

//delete before final push

.nav-sections {
  display: none;
}

.mob-hide {
  display: none;
  @include breakpoint($desktop-mid) {
    display: block;
  }
}

.desktop-hide {
  display: block;
  @include breakpoint($desktop-mid) {
    display: none;
  }
}

.customer-account-index,
.customer-address-index,
.sales-order-history,
.magento_rma-returns-history {

  h2 {
    @include breakpoint($tablet) {
      @include fontSize(28px);
    }
    @include breakpoint($desktop-mid) {
      @include fontSize(32px);
    }
    margin: 0;
  }

  h2.recent-orders {
    padding-bottom: 32px;
    border-bottom: 1px solid $border;
    @include breakpoint($desktop-mid) {
      padding-bottom: 0;
      border-bottom: 0;
    }
  }

  .page-main .block {
    border: 1px solid get-color(ui-dark, dark-20);
    padding: $block-padding;
    margin-bottom: 24px;
  }

  .block-dashboard-addresses,
  .block-dashboard-orders {
    @include breakpoint($desktop-mid) {
      position: relative;
    }
  }

  .block-dashboard-info,
  .block-dashboard-addresses,
  .block-addresses-default {
    .block-content {
      @include breakpoint($desktop-mid) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 24px;
      }
    }
  }

  .block-dashboard-info {
    p {
      @include fontSize(14px);
    }
    .box-title {
      display: block;
      margin: 0 0 16px;
    }
  }

  .block-content,
  .table-wrapper {
    .box,
    table {
      margin-top: 32px;
      padding: 0;
      border: 0;
    }
  }

  address {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: normal;
    color: $text;
    font-style: normal;
    margin: 16px 0;
  }

  .action.edit {
    color: $primary;
    font-size: 14px;
    line-height: 22px;
    margin-top: 16px;
    display: block;
    text-decoration: underline;
  }

  .block-dashboard-addresses {
    position: relative;
  }

  .action.action-button,
  .acc-view-all {
    width: 100%;
    padding: 9px;
    box-sizing: border-box;
    border: 1px solid get-color(ui-dark, base);
    border-radius: 4px;
    background-color: $white;
    box-shadow: 0 1px 2px 0 rgba(33, 43, 54, .1);
    font-size: 14px;
    font-weight: $font-bold;
    letter-spacing: 0;
    line-height: 22px;
    color: $text;
    text-align: center;
    text-decoration: none;
    @include breakpoint($desktop-mid) {
      position: absolute;
      top: 32px;
      width: unset;
      right: 32px;
      margin-top: 0;
    }
  }

  .acc-view-all {
    display: block;
    margin-top: 32px;
    @include breakpoint($desktop-mid) {
      margin-top: 0;
      min-width: 127px;
    }
  }

  .order-mob-list {
    margin-top: 15px;
    color: $text;
    font-size: 14px;
    line-height: 22px;
    border-bottom: 1px solid $border;

    .strong {
      font-weight: $font-bold;
    }

    div {
      margin-bottom: 8px;
    }

    div.status-line {
      margin-bottom: 16px;
    }

    div.actions-line {
      margin-bottom: 16px;

      a {
        color: $primary;
        text-decoration: none;
        margin-right: 16px;
      }
    }

    .ship-to-line {
      text-transform: capitalize;
    }

    .order-status {
      padding: 3.5px 6px;
      text-transform: uppercase;
      border-radius: 3px;
      display: inline-block;
    }
    .order-closed {
      background-color: get-color(ui-dark, dark-10);
      color: get-color(ui-dark, dark-90);
    }
    .order-processing {
      background-color: get-color(ui-success, success-10);
      color: get-color(ui-success, success-40);
    }
  }
}

.magento_customerbalance-info-index {
  .storecredit {
    .block-balance-giftcard {
      .block-content {
        a {
          text-decoration: underline;
        }
      }
    }
  }
  .block-balance-history {
    .block-title {
      margin-bottom: .5rem;
    }
  }
}

.magento_giftcardaccount-customer-index {
  .giftcard-account {
    .form-giftcard-redeem {
      .fieldset {
        border: solid 1px get-color(ui-dark, dark-20);
        margin-bottom: 1rem;
      }
      .actions-toolbar {
        .primary {
          margin-bottom: .5rem;
        }
      }
    }
  }
}

.account-menu__wrapper {
  margin: -16px 0 32px;
  @include breakpoint($desktop-mid) {
    margin: 0;
  }
}

.account-nav {
  border: 0;
  padding: 0;
  margin: 0;
  @include breakpoint($desktop-mid) {
    margin: 0 32px 0 0;
    padding: 24px;
  }
  background: get-color(ui-dark, dark-10);
  .title {
    background: get-color(ui-white);
    padding: 14px 24px;
    border-bottom: solid 1px get-color(ui-dark, dark-20);
    position: relative;
    @include breakpoint($desktop-mid) {
      display: none;
    }
    &::after {
      content: "\f077";
      font-size: 16px;
      font-family: "font Awesome 5 Pro";
      position: absolute;
      right: 24px;
    }
    &.active::after {
      content: "\f078";
      font-size: 16px;
      font-family: "font Awesome 5 Pro";
      position: absolute;
      right: 24px;
    }
  }
  .content {
    padding: 24px;
    display: none;
    @include breakpoint($desktop-mid) {
      padding: 0;
      display: block;
    }
    &.active {
      display: block;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  li {
    padding: 0 0 16px;
    &:last-child {
      padding: 0;
    }
  }
  .delimiter {
    display: block;
    border-bottom: solid 1px get-color(ui-dark, dark-20);
  }
}

.customer-address-form,
.customer-account-edit,
.magento_advancedcheckout-sku-index,
.newsletter-manage-index,
.magento_rma-returns-create {
  .form-address-edit,
  .form-edit-account,
  .form-addbysku,
  .form-newsletter-manage,
  .form-create-return {
    border: solid 1px get-color(ui-dark, dark-20);
    padding: 32px;
    fieldset {
      border: 0;
      padding: 0;
      margin: 0;
      &:nth-child(2) {
        margin: 16px 0 0;
      }
      &:nth-child(2) legend {
        margin: 0 0 16px;
      }
    }
    legend {
      @include fontSize(24px);
      font-weight: $font-bold;
      line-height: 24px;
      letter-spacing: 0;
      @include breakpoint($tablet) {
        @include fontSize(28px);
      }
      @include breakpoint($desktop-mid) {
        @include fontSize(32px);
      }
      margin: 0 0 32px;
    }
    .field.choice {
      label {
        display: inline-block;
      }
    }
    label {
      display: none;
    }
    address {
      @include fontSize(14px);
      font-style: normal;
    }
  }
  .password-info {
    p {
      @include fontSize(14px);
    }
  }
  .deletableItem {
    display: flex;
    flex-direction: row;
    .field {
      flex-basis: calc(100% / 3);
      margin: 0 16px 16px 0;
    }
    .actions-toolbar {
      button {
        padding: 8px 32px;
      }
    }
  }
  .box-upload {
    margin: 32px 0 0;
  }
  .box-upload .reset {
    margin: 16px 0;
  }
  .box-upload .note {
    margin: 16px 0 0;
    p {
      @include fontSize(14px);
    }
  }
}

.nested {
  margin: 16px 0 0;
}

.customer-addresses-toolbar,
.order-products-toolbar {
  margin: 16px 0;
  .pager {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }
  .limiter {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }
  .limiter-label,
  .limiter-text {
    display: none;
  }
  .toolbar-amount {
    @include fontSize(14px);
    margin: 0;
  }
}

.table-order-items {
  .status {
    &-Processing,
    &--Pending,
    &--Hold {
      display: inline-block;
      border-radius: 3px;
      padding: 3.5px 6px;
      background-color: get-color(ui-success, success-10);
      color: get-color(ui-success, success-40);
    }
    &-Complete,
    &-Closed,
    &-Refunded {
      display: inline-block;
      border-radius: 3px;
      padding: 3.5px 6px;
      background-color: get-color(ui-dark, dark-10);
      color: get-color(ui-dark, dark-90);
    }
  }
}

.order-view {
  &__container {
    border: solid 1px get-color(ui-dark, dark-20);
    border-bottom: 0;
    padding: 32px;
    table {
      border: 0;
      padding: 0;
    }
    thead {
      display: none;
    }
    td {
      @include breakpoint($desktop-mid) {
        padding: 10px 48px 18px 0;
        vertical-align: top;
      }
      &.col.subtotal {
        padding-right: 0;
      }
    }
    .order-totals {
      @include breakpoint($desktop-mid) {
        width: 40%;
        margin: 0 0 0 auto;
      }
      tr {
        box-shadow: none;
      }
      td {
        text-align: right;
        padding: 0 0 12px;
      }
      td:first-of-type {
        border: 0;
      }
      .grand_total {
        strong {
          @include fontSize(16px);
        }
      }
      .mark {
        font-weight: $font-bold;
        display: none;
        @include breakpoint($desktop-mid) {
          display: unset;
        }
      }
    }
  }
  &__header {
    @include breakpoint($desktop-mid) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 36px;
    }
    h3 {
      @include fontSize(24px);
      margin: 0 0 32px;
      @include breakpoint($desktop-mid) {
        @include fontSize(32px);
        margin: 0;
      }
    }
    .btn {
      width: 100%;
      margin: 0 0 16px;
      display: block;
      text-align: center;
      @include breakpoint($desktop-mid) {
        display: inline-block;
        margin: 0 0 0 6px;
        width: auto;
        padding: 9px 25px;
      }
    }
  }
}

.block-order-details-view {
  .block-content {
    @include breakpoint($desktop-mid) {
      display: flex;
      flex-direction: row;
    }
    padding: 32px 16px;
    background: get-color(ui-dark, dark-10);
    border: solid 1px get-color(ui-dark, dark-20);
    border-top: 0;
  }
  .box {
    border: 0;
    padding: 0 16px;
    &-title {
      display: block;
      margin: 0 0 16px;
    }
    &-content {
      @include fontSize(14px);
    }
    address {
      @include fontSize(14px);
      font-style: normal;
      line-height: 1.6;
      color: get-color(ui-dark, dark-80);
    }
  }
}

.block-return-details {
  .block-content {
    @include fontSize(14px);
    line-height: 1.6;
    @include breakpoint($desktop-mid) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 24px;
    }
    .box {
      margin: 0 0 16px;
    }
    .box-title {
      display: block;
      margin: 0 0 16px;
    }
    address {
      @include fontSize(14px);
      font-style: normal;
      color: get-color(ui-dark, base);
      margin: 0;
      line-height: 1.6;
    }
  }
}

.block-returns-items,
.block-returns-comments {
  margin: 0 0 16px;
  border: solid 1px $border;
  padding: 32px;
  .block-title {
    margin: 0 0 32px;
  }
  .fieldset {
    border: 0;
    padding: 32px 0 0;
  }
  .table {
    border: 0;
    padding: 0;
    thead {
      display: none;
    }
  }
}

.return-status {
  display: none;
}

.resolution0,
.condition0,
.reason0,
.return-comment {
  label {
    // sass-lint:disable-all
    display: inline-block !important;
    // sass-lint:enable-all
  }
}

.return-comment {
  margin: 16px 0;
}

.form-create-return {
  .label {
    font-weight: $font-bold;
    margin: 0 0 5px;
    display: block;
  }
}

.box.box-newsletter {
  display: none;
}

.main-title-container h1 {
    font-size: 2rem;
}
