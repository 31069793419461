.form.contact {
  background: $shade;
  padding: 32px;
  fieldset {
    margin: 0 0 16px;
    padding: 0;
    border: 0;
    .field:last-of-type {
      margin-bottom: 0;
    }
  }
  legend {
    margin-bottom: 32px;
    span {
      @include fontSize(24px);
      font-weight: $font-bold;
      line-height: 32px;
      color: $headingText;
      margin-bottom: 32px;
      @include breakpoint($desktop) {
        @include fontSize(32px);
        line-height: 40px;
      }
    }
  }
  .field.note {
    @include fontSize(14px);
    line-height: 22px;
    color: $headingText;
    margin-bottom: 32px;
  }
}
