.custom-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-size: cover;

  &--family {
    background-image: url(/static/frontend/DonaghyBros/default/en_GB/images/svg/family-v1.svg);
  }
}

