//
// Table / Boxes on Account area
//

table,
.block-content .box {
  width: 100%;
  padding: 32px;
  border: 1px solid $border;
}

.table-caption {
  display: none;
}

.table-order-items thead {
  display: none;

  @include breakpoint($desktop) {
    display: table-header-group;
  }
}

th,
td {
  @include fontSize(14px);
  text-align: left;
  color: $text;
  line-height: 22px;
}

th {
  font-weight: $font-bold;
  padding: 0 0 9px 0;
  display: none;
  @include breakpoint($desktop) {
    padding: 9px 0;
    display: table-cell;
  }
}

td {
  display: block;
  padding: 10px 0 0;
  @include breakpoint($desktop) {
    padding: 10px 0;
  }

  &::before {
    content: attr(data-th) ": ";
    font-weight: $font-bold;

    @include breakpoint($desktop) {
      content: none;
    }
  }

  &:first-of-type {
    border-top: 1px solid $border;
  }

  &.actions::before,
  &.view-all::before {
    display: none;
  }

  @include breakpoint($desktop) {
    display: table-cell;
  }
}

tr:first-of-type {
  td:first-of-type {
    border-top: 0;
  }
}

td.status {
  span {
    @include fontSize(12px);
    border-radius: 3px;
    padding: 1px 6px;
    text-transform: uppercase;
    line-height: 20px;
    font-weight: $font-bold;
  }

  &.closed span {
    background-color: get-color(ui-dark, dark-10);
    color: get-color(ui-dark, dark-90);
  }

  &.processing span {
    background-color: get-color(ui-success, success-10);
    color: get-color(ui-success, success-40);
  }
}

td.actions {
  a {
    @include fontSize(14px);
    text-decoration: underline;
    &.view {
      margin: 0 16px 0 0;
    }
  }
}

td.view-all {
  padding-top: 32px;
  margin-top: 12px;
  border-top: 1px solid $border;
  width: 100%;

  @include breakpoint($desktop) {
    display: none;
  }

  button {
    width: 100%;
  }
}

tbody tr {
  @include breakpoint($desktop) {
    box-shadow: inset 0 1px 0 0 $border;
  }
}
