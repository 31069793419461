.page.messages,
.page-main .message {
  @include breakpoint($desktop) {
    max-width: var(--grid-max);
    margin: 0 auto;
  }

  .success,
  .error,
  .warning,
  .notice,
  &.info {
    @include fontSize(14px);
    padding: 13px 16px;
    position: relative;
    display: flex;
    justify-content: left;
    margin: 0 0 16px;

    p {
      position: relative;
      padding-left: 24px;

      &::before {
        content: "";
        position: absolute;
        top: 3px;
        left: 0;
        height: 16px;
        width: 16px;
      }
    }
  }

  .success {
    background: get-color(ui-success, success-20);
    border: solid 1px get-color(ui-success, success-30);
    color: get-color(ui-success, base);

    p {
      color: get-color(ui-success, base);

      &::before {
        background: url("../images/icons/icon-success.svg");
        background-size: 16px;
      }
    }
    a {
      color: get-color(ui-success, base);
    }
  }

  .error {
    background: get-color(ui-error, error-20);
    border: solid 1px get-color(ui-error, error-30);
    color: get-color(ui-error, base);

    p {
      color: get-color(ui-error, base);

      &::before {
        background: url("../images/icons/icon-success.svg");
        background-size: 16px;
      }
    }
    a {
      color: get-color(ui-error, base);
    }
  }

  .warning,
  .notice,
  &.info {
    background: $accent;

    p,
    p {
      color: $headingText;

      &::before {
        background: url("../images/icons/icon-warning.svg");
        background-size: 16px;
      }
    }
  }
}

.message {
  @include fontSize(14px);
  padding: 13px 16px;
  position: relative;
  display: block;
  justify-content: left;
  margin: 0 0 16px;
  &.error {
    background: get-color(ui-error, error-20);
    border: solid 1px get-color(ui-error, error-30);
    color: get-color(ui-error, base);

    p {
      color: get-color(ui-error, base);

      &::before {
        background: url("../images/icons/icon-success.svg");
        background-size: 16px;
      }
    }
    a {
      color: get-color(ui-error, base);
    }
  }
}
