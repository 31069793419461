.catalog-product-view {
  .column.main {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    align-items: flex-start;
  }

  .reevoo-product-badge {
    width: 160px;
  }

  .product-recommend {
    width: 100%;
    order: 3;
    .rec-products {
      .slick-track {
        display: flex;
        flex-direction: row;
      }
      .action.tocart.primary {
        display: none;
      }
      .price-label {
        display: none;
      }
    }
  }

    .amazon-pdp {
        padding: 16px;
        border-left: 1px solid #d3d2d2;
        border-right: 1px solid #d3d2d2;
        #ui-id-2 {
            width: 100% !important;
        }
    }

  .product-backto {
    flex-basis: 100%;
    margin: 0 0 32px;
    @include breakpoint($desktop-mid) {
      margin: 0 0 48px;
    }

    i {
      margin: 0 8px 0 0;
    }

    a {
      color: get-color(ui-dark, dark-80);
    }
  }

  .page-title-wrapper {
    margin: 0;

    h1 {
      margin: 16px 0;
      line-height: 36px;
    }
  }

  .product-info-main,
  .product.media {
    width: 100%;
    @include breakpoint($tablet) {
      width: calc(100% / 2);
    }
  }

  .product-info {
    &-main {
      order: 2;
      @include breakpoint($tablet) {
        padding: 0 0 0 50px;
      }
      .brand-logo {
        width: 108px;
      }
    }

    &-price {
      margin: 0 0 16px;
    }

    &-stock-sku {
      @include fontSize(14px);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 16px;

      .stock {
        font-weight: $font-bold;
        text-transform: capitalize;
      }

      .only {
        @include fontSize(16px);
        background-color: $error;
        border: 1px solid $error;
        border-radius: 2px;
        color: #fff;
        text-align: center;
        padding: 12px 18px;
        display: flex;
        min-width: 38%;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &::before {
          content: "";
          height: 18px;
          width: 18px;
          display: inline-block;
          background-image: url("../images/svg/warning.svg");
          background-size: cover;
          background-repeat: no-repeat;
          margin-right: 5px;
        }

        strong {
          margin: 0 3px;
        }
      }

      .available::before {
        content: "\f00c";
        font-family: "Font Awesome 5 Pro";
        color: get-color(ui-success, success-40);
        font-weight: 300;
        margin: 0 10px 0 0;
      }

      .unavailable::before {
        content: "\f00d";
        font-family: "Font Awesome 5 Pro";
        color: get-color(ui-red, base);
        font-weight: 300;
        margin: 0 10px 0 0;
      }

      .type {
        display: none;
      }

      .value {
        color: get-color(ui-dark, dark-80);
      }
    }

    &-paybyfinance {
      margin: 8px 0 0;
    }
  }

  .price-final_price,
  .energy-rating {
    display: inline-block;
  }

  .price-final_price {
    width: 49%;
    .call-for-price-list {
      display: none;
      text-align: center;
    }
    .call-for-price-view {
      display: block;
      h5 {
        margin: 0 0 16px;
      }
      .button {
        width: 100%;
        text-align: center;
        font-weight: $font-bold;
      }
    }
  }

  .energy-rating {
    width: 100%;
    text-align: right;
  }

  .warranty-options {
    border-radius: 4px;
    background: get-color(ui-dark, dark-10);
    padding: 12px;
    margin: 16px 0 0;
    label:first-child {
      &::before {
        content: "\f058";
        font-family: "Font Awesome 5 Pro";
        font-weight: 400;
        margin: 0 8px 0 0;
        color: get-color(ui-red, base);
        }
      }
    .options-list {
      .field {
        display: flex;
          }
      }

    input[type="radio"] {
      min-width: 20px;
      }
  }

  .product {
    &-add-form {
      margin: 32px 0 0;

      #product_quoterequest_form {
        margin-bottom: 16px;
      }
    }

    &-info-footer {
      clear: both;
      padding: 16px;
      border: solid 1px get-color(ui-dark, dark-20);
      border-radius: 4px;
      position: relative;
      top: -2px;
      z-index: 0;

      a {
        z-index: 9;
      }

      .catalog-sub-actions {
        @include breakpoint($desktop-mid) {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
        .btn {
          height: 48px;
          margin: 0 0 16px;
          @include breakpoint($desktop-mid) {
            width: 49%;
          }
        }
      }

    }

    &-usps {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        @include fontSize(12px);
        display: flex;
        align-items: center;
        padding: 12px 16px;
        margin: 0 0 2px;
        background: get-color(ui-dark, dark-10);
        position: relative;

        &.warranty-extend,
        &.promos-usp {
          cursor: pointer;

          &::after {
            @include fontSize(16px);
            position: absolute;
            right: 12px;
            content: "\f054";
            font-family: "Font Awesome 5 Pro";
            font-weight: $font-bold;
          }
        }
      }

      .promos-usp {
        &__content,
        &__imgs {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          align-items: center;

          img {
            display: none;
            &:first-of-type {
              display: block;
            }
          }

          @include breakpoint($desktop) {
            img {
              display: block;
            }
          }

        }

        &__text {
          margin-right: 1rem;
        }

        &__imgs {
          img {
            margin-right: .5rem;
          }
        }
      }

      Strong {
        @include fontSize(14px);
      }

      i {
        @include fontSize(16px);
        color: get-color(ui-red, base);
        margin: 0 16px 0 0;
      }
    }
  }

  .qty-wrapper {
    display: flex;
    width: 132px;
    margin: 8px 0 0;

    button {
      padding: 10px 16px;
      background: get-color(ui-white);
      border: solid 1px get-color(ui-dark, dark-20);
    }

    input {
      appearance: textfield;
      height: 44px;
      width: 44px;
      border-left: 0;
      border-right: 0;
      display: inline-block;
    }
  }

  .box-tocart .actions {
    background: get-color(ui-white);
    padding: 16px 16px 0;
    border: solid 1px get-color(ui-dark, dark-20);
    border-bottom: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    position: relative;
    z-index: 9;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    #paypal-smart-button {
      flex: 100%;
      margin: 16px 0 0;
      @include breakpoint($desktop-mid) {
        margin: 0 0 0 16px;
      }
    }

    .paypal.checkout {
        width: 100%;
        margin-bottom: 5px;
    }

    button.tocart {
      min-width: 100%;
      margin: 0 0 10px;
    }

    button.paypal {
      float: right;
      min-width: 245px;
      min-height: 56px;
    }

    button.tocart::before {
      @include fontSize(16px);
      content: "\f291";
      font-family: "Font Awesome 5 Pro";
      font-weight: 300;
      margin: 0 5px 0 0;
    }

    .instant-purchase {
      display: none;
    }
	
	.amazon-checkout-button {
	  box-sizing: border-box;
	  position: relative;
	  overflow: hidden;
	  cursor: pointer;
	  border: solid 1px #231F20;
	  min-width: 100%;
	  height: 3.7em;
	  padding: 16px 28px;
    }

	.amazon-checkout-button .amazonpay-button-parent-container, .amazon-checkout-button > [role=button] {
	  bottom: 0;
	  height: 100% !important;
	  left: 0;
	  opacity: 0;
	  position: absolute !important;
	  right: 0;
	  top: 0;
	  width: 100% !important;
	}

	.amazon-button-column.amazon-button-column-tooltip {
	  display: none;
	}

	.amazon-checkout-button:before,.amazon-checkout-button:after {
	  content: "";
	  bottom: 0;
	  left: 0;
	  position: absolute;
	  right: 0;
	  top: 0;
	  width: 100%;
	  height: 100%;
	  pointer-events: none;
	}

	.amazon-checkout-button:before {
	  background: #f5d584;
	}

	.amazon-checkout-button:after {
	  background: url('../images/svg/amazon-pay-button-logo.svg') no-repeat center 57%;
	  background-size: auto 1.1em
	}

	.amazon-checkout-button:hover:before,.amazon-checkout-button:focus:before {
	  background-color: #615d63
	}
	
	.amazon-product-button .amazon-checkout-button:active {
		pointer-events: none;
	}
  }

  .product.media {
    order: 1;
    margin: 0 0 80px;
  }

  .product.info.detailed {
    width: 100%;
    order: 4;
    margin: 64px 0;
    @include breakpoint($desktop-mid) {
      margin: 80px 0;
    }

    .data.item.title {
      a {
        @include fontSize(24px);
        display: block;
        font-weight: $font-bold;
        padding: 26px 0;
        border-top: solid 1px get-color(ui-dark, dark-20);
        position: relative;

        &:hover {
          text-decoration: none;
          color: get-color(ui-red, base);
        }

        &::after {
          @include fontSize(19.2px);
          content: "\f067";
          font-family: "Font Awesome 5 Pro";
          font-weight: 400;
          position: absolute;
          right: 0;
        }
      }

      &.active {
        a::after {
          @include fontSize(19.2px);
          content: "\f068";
          font-family: "Font Awesome 5 Pro";
          font-weight: 400;
          position: absolute;
          right: 0;
        }
      }
      &:first-child {
        a::after {
          display: none;
        }
      }
    }

    .data.item.content {
      @include fontSize(14px);
      padding: 12px 0 32px 0;
      color: get-color(ui-dark, dark-80);
      max-width: 866px;
      position: relative;

      @include breakpoint($desktop-mid) {
        .key-features {
          position: relative;
          padding: 0 0 0 600px;
          top: -47px;
        }
      }

      p {
        @include fontSize(14px);
        color: get-color(ui-dark, dark-80);
      }

      table {
        padding: 0;
        border-top: 0;
        width: 100%;
        border-left: solid 1px $border;
        border-right: solid 1px $border;
        border-bottom: solid 1px $border;
        @include breakpoint($desktop-mid) {
          width: 868px;
        }

        th {
          padding: 13px 15px;
          font-weight: $font-bold;

          .field-tooltip .field-tooltip-action::before {
            content: "\f05a";
            font-family: "Font Awesome 5 Pro";
            color: get-color(ui-red, base);
            margin: 0 10px 0 0;
            font-weight: $font-bold;
            position: relative;
            top: 3px;
          }
        }

        td {
          text-align: right;
          padding: 13px 15px;

          &::before {
            display: none;
          }
        }
      }

    }

    div[aria-labelledby="tab-label-keyfeatures-tab"] {
      // sass-lint:disable-all
      display: block !important;
      padding: 0 !important;
      // sass-lint:enable-all
    }

    div[aria-labelledby="tab-label-delivery-tab"] {
      h6 {
        margin: 0 0 16px;
      }
      p {
        @include fontSize(14px);
      }
      .content {
        border: solid 1px get-color(ui-dark, dark-20);
        border-radius: 4px;
        padding: 14px 16px 0;
        margin: 0 0 32px;
      }
    }

  }

  .mageplaza-frequently-bought-together-block {
    order: 3;
    width: 100%;
  }

  .block.related {
    flex-basis: 100%;
    order: 8;
  }

  .block.upsell {
    flex-basis: 100%;
    order: 3;
    .block-title {
      margin-top: 22px;
      padding-top: 42px;
    }
    .products-grid.products-upsell {
      margin-bottom: 0;
      .product-items {
        .product-item {
          .product-item-info {
            .product-item-photo {
              height: 100px !important;
              min-height: unset;
              .product-image-photo {
                height: 100px;
              }
            }
          }
        }
      }
    }
  }

  .reviews-slider-wrapper {
    flex-basis: 100%;
    order: 10;
    margin: 80px auto 0;
  }

}

.expected-in-text {
  @include fontSize(16px);
  padding: 16px;
  margin: 0 0 16px;
  text-align: center;
  background: #f3f8fc;
  color: #0072bf;
  border: 0;
  span {
    font-weight: $font-bold;
  }
}

.discontinued-text {
  margin: 32px 0 0;
  border: solid 1px get-color(ui-dark, dark-20);
  border-radius: 4px;
  padding: 16px;
  &-inner {
    padding: 12px;
    background: get-color(ui-dark, dark-10);
    border-radius: 4px;
  }
  h6 {
    @include fontSize(14px);
    color: get-color(ui-dark, dark-80);
  }
  p {
    @include fontSize(12px);
    color: get-color(ui-dark, dark-70);
    margin: 0;
  }
}

#flix_hotspots {
  display: none !important; // sass-lint:disable-line no-important
}

.product-description {
  position: relative;
  left: 0;
  top: 0;
  right: 0;
}

.modal-warranty {
  h1 {
    &::before {
      content: "\f2f7";
      font-family: "Font Awesome 5 Pro";
      font-weight: $font-regular;
      margin: 0 16px 0 0;
    }
  }
}

.modal-delivery {
  h1 {
    &::before {
      content: "\f7d9";
      font-family: "Font Awesome 5 Pro";
      font-weight: $font-regular;
      margin: 0 16px 0 0;
    }
  }
}

.warranty-popup {
  margin: 0 0 20px;
  &__intro {
    background: get-color(ui-dark, dark-10);
    padding: 24px 24px 8px;
    p {
      @include fontSize(14px);
    }
  }
  &__image {
    height: 143px;
    margin: 0 0 16px;
    overflow: hidden;
  }
  &__item {
    margin: 24px;
    padding: 16px;
    border: solid 1px $border;
    border-radius: 4px;
    h6 {
      margin: 0 0 12px;
      position: relative;
      i {
        @include fontSize(32px);
        position: absolute;
        top: 0;
        right: 0;
      }
    }
    ul {
      margin: 0 0 0 34px;
      padding: 0;
    }
    li {
      display: block;
      padding: 14px 0;
      border-bottom: solid 1px $border;
      font-weight: $font-bold;
      position: relative;
      line-height: 18px;
      &::before {
        @include fontSize(14px);
        content: "\f00c";
        font-family: "Font Awesome 5 Pro";
        font-weight: $font-bold;
        color: get-color(ui-success, success-50);
        position: absolute;
        left: -30px;
        top: 14px;
      }
      &:last-child {
        border-bottom: 0;
      }
    }
    .silver-shade {
      color: get-color(ui-dark, dark-0);
    }
    .silver {
      color: get-color(ui-dark, dark-70);
    }
    .gold {
      color: get-color(ui-gold);
    }
  }
}

.delivery-popup {
  margin: 0 0 20px;
  &__intro {
    background: get-color(ui-dark, dark-10);
    padding: 24px 24px 8px;
    p {
      @include fontSize(14px);
    }
  }
  &__image {
    height: 143px;
    margin: 0 0 16px;
    overflow: hidden;
  }
  &__item {
    margin: 24px;
    border: solid 1px $border;
    border-radius: 4px;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      display: block;
      padding: 9px 16px;
      border-bottom: solid 1px $border;
      position: relative;
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

//Egift voucher open amount styles
.page-product-giftcard {
  .price-final_price {
    width: 100%;

    .giftcard-amount {
      border: 1px solid #d3d2d2;
      border-radius: 4px;
      margin-bottom: 10px;
    }
  }

  .giftcard.send {
    border: 1px solid #d3d2d2;
    border-radius: 4px;
    margin-bottom: 10px;
  }

  .page-main-details {
    display: none;
  }
}
