//
// Generic Form styles
//

.field {
  position: relative;
  margin-bottom: 16px;

  label {
    @include fontSize(14px);
    color: $headingText;
    font-weight: $font-bold;
    letter-spacing: 0;
    line-height: 22px;
    cursor: pointer;
  }

  &.required {
    .label::after {
      content: "*";
      padding: 0 0 0 5px;
    }
  }

  &-error {
    @include fontSize(13px);
    color: get-color(ui-red, base);
  }
}

._error {
  file,
  input,
  select,
  textarea,
  .mage-error {
    border: solid 1px get-color(ui-red, base);
  }
}

.password-wrapper {
  position: relative;

  .toggle-password {
    position: absolute;
    top: 9px;
    right: 40px;
  }
}

textarea,
input,
select,
file {
  @include fontSize(14px);
  box-sizing: border-box;
  width: 100%;
  border: 1px solid get-color(ui-dark, dark-20);
  border-radius: 2px;
  line-height: 22px;
  appearance: none;
  background: get-color(ui-white);

  // sass-lint:disable-all
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  // sass-lint:enable-all

}

// margin effects other inputs can this be more specific ?
textarea,
input:not([type=checkbox]):not([type=radio]),
select {
  padding: 9px 16px;

  &:focus,
  &.selected {
    border: 1px solid get-color(ui-dark, dark-90);
    border-radius: 2px;
    outline: none;
  }

  &.mage-error {
    box-shadow: 0 0 0 3px $fieldErrorShadow;
    border: 1px solid $error;
    border-radius: 4px;
    outline: none;
  }
}

div.mage-error {
  @include fontSize(12px);
  margin-top: 8px;
  text-align: left;
  color: $error;
  letter-spacing: 0;
  line-height: 20px;
}

.control {
  &._with-tooltip {
    position: relative;

    .field-tooltip {
      position: absolute;
      top: 10px;
      right: 12px;
    }

    .field-tooltip-content {
      top: -38%;
    }
  }
}

input[type="radio"] {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border-bottom-width: 1px;
  outline: none;
  margin-right: 8px;
  box-shadow: 0 1px 2px 0 rgba(35, 31, 32, .1);

  &:checked {
    border-width: 4px;
    border-color: $white;
    background: $black;

    + .label::before {
      content: "";
      position: absolute;
      left: -1px;
      top: -1px;
      height: 20px;
      width: 20px;
      border: 1px solid $fieldBorder;
      border-radius: 50%;
    }
  }
}

input[type="checkbox"] {
  width: 20px;
  height: 20px;
  min-width: 20px;
  outline: none;
  border-radius: 4px;
  margin-right: 8px;
  box-shadow: 0 1px 2px 0 rgba(35, 31, 32, .1);

  &:checked {
    background: url(../images/icons/icon-check.svg) 50% 50% no-repeat;
    background-size: 11px;
  }
}


input[type="radio"],
input[type="checkbox"] {
  & + .label {
    @include fontSize(14px);
    display: inline-block;
    text-transform: none;
    margin-bottom: 0;
    vertical-align: top;
    line-height: 22px;
    font-weight: $font-regular;
  }
}

textarea,
input {
  color: $text;
}

select {
  color: #929EAA;
}

input,
select {
  height: 40px;
}

textarea {
  height: 80px;
}
