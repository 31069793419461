.amxnotif-block {
  border-bottom: solid 1px get-color(ui-dark, dark-20);
  padding: 0 0 16px;
  margin: 0 0 16px;

  .notification-container {
    display: block;
  }

  .input-fields {
    display: block;
    width: 100%;
    @include breakpoint($desktop-mid) {
      display: inline-block;
      width: 66%;
    }
  }

  .amxnotif_guest_action {
    display: block;
    max-width: 100%;
    min-width: 100%;
    margin: 16px 0;
    @include breakpoint($desktop-mid) {
      display: inline-block;
      margin: 0;
      max-width: 32%;
      min-width: 120px;
    }
  }

  .field.required {
    display: flex;

    label {
      @include fontSize(12px);
    }
  }

  label {
    margin: 0 0 16px;
    display: flex;
  }

  button.secondary {
    padding: 8px 28px;
  }
}

.expected-in-text {
  @include fontSize(14px);
  border-bottom: solid 1px get-color(ui-dark, dark-20);
  padding: 0 0 16px;
  margin: 0 0 16px;
  background: get-color(ui-blue, ctabackground);
  color: get-color(ui-blue, ctablue);
  text-align: center;

  span {
    @include fontSize(16px);
    font-weight: $font-bold;
  }
}

#gdrp-error {
  margin: 3px 0 0 5px;
}

.action.alert {
  @include fontSize(16px);
  padding: 12px 16px 12px 12px;
  margin: 0 0 16px;
  border: solid 1px get-color(ui-dark, dark-20);
  display: block;
  font-weight: $font-bold;
  position: relative;

  &:hover {
    text-decoration: none;
    background: get-color(ui-dark, dark-10);
  }

  &::before {
    content: "\f1d8";
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    margin: 0 5px 0 0;
  }

  &::after {
    position: absolute;
    right: 12px;
    content: "\f054";
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
  }
}