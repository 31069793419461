.toolbar-products {
  display: flex;
  flex-direction: row;
  @include breakpoint($desktop) {
    flex-wrap: nowrap;
  }
  justify-content: space-between;
  align-items: center;
  margin: 0 0 24px;

  .pages {
    display: none;
  }

  .toolbar-sorter {
    @include fontSize(14px);
    flex-basis: calc(100% / 2);
    margin: 0 0 0 12px;
    color: get-color(ui-dark, dark-80);
    @include breakpoint($desktop) {
      flex-basis: span(4);
    }
    .sorter-label {
      @extend %visually-hidden;
    }
  }

  .toolbar-amount {
    @include fontSize(14px);
    display: none;
    color: get-color(ui-dark, dark-80);
    @include breakpoint($desktop) {
      // sass-lint:disable-all
      display: block !important;
      // sass-lint:enable-all
    }
  }

  .toolbar-filter-toggle {
    flex-basis: calc(100% / 2);
    margin: 0 12px 0 0;
    padding: 8px 10px;
    @include breakpoint($desktop) {
      display: none;
    }
  }
}

.products-grid + .toolbar-products {
  .pages {
    display: block;
  }
  .toolbar-sorter,
  .toolbar-filter-toggle {
    display: none;
  }
}

.toolbar-footer {
  .toolbar-filter-toggle,
  .toolbar-amount,
  .toolbar-sorter {
    display: none;
  }
}

.product-amount {
  @include fontSize(14px);
  color: get-color(ui-dark, dark-80);
  text-align: center;
  margin: 0 0 24px;
  display: none;
}

// sass-lint:disable-all
#amscroll-page-num1 {
  display: none;
}
// sass-lint:enable-all
