.newsletter__container {
  // sass-lint:disable-all
  button.needsclick {
    font-weight: $font-bold !important;
    padding: 11px 35px !important;
  }

  input.needsclick {
    font-size: 14px !important;
    padding: 9px 16px !important;
    &::placeholder {
      font-size: 14px !important;
    }
  }
  // sass-lint:enable-all
}