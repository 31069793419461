.contact-index-index {
  .column.main {
    display: flex;
    flex-flow: column-reverse nowrap;
    margin: 30px 0 0;
    @include breakpoint($desktop) {
      flex-flow: row-reverse nowrap;
      justify-content: space-between;
    }
    .block-contactinfo,
    .form.contact {
      width: 100%;
      flex-basis: span(4);
      @include breakpoint($desktop) {
        flex-basis: span(8);
      }
    }
    .form.contact {
      margin-bottom: 24px;
      @include breakpoint($desktop) {
        margin-bottom: 0;
      }
      .comment {
        margin-top: 16px;
      }
    }
  }
}
