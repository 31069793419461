// sass-lint:disable no-duplicate-properties

// Convert px to rem
@mixin fontSize($pix) {
  $remVal: $pix / 16px;
  font-size: $pix;
  font-size: $remVal + rem;
}

@mixin mainHeader {
  @include fontSize(28px);
  color: $headingText;
  line-height: 40px;
  font-weight: $font-bold;
  letter-spacing: 0;

  @include breakpoint($desktop) {
    @include fontSize(80px);
    line-height: 82px;
  }
}

@mixin subHeader {
  @include fontSize(24px);
  color: $headingText;
  line-height: 32px;
  font-weight: $font-bold;
  letter-spacing: 0;

  @include breakpoint($desktop) {
    @include fontSize(48px);
    line-height: 50px;
  }
}

@mixin thirdHeader {
  @include fontSize(16px);
  color: $text;
  font-weight: $font-bold;
  line-height: 24px;
  letter-spacing: 0;

  @include breakpoint($desktop) {
    @include fontSize(32px);
    line-height: 36px;
  }
}

@mixin forthHeader {
  @include fontSize(16px);
  color: $text;
  font-weight: $font-bold;
  line-height: 24px;
  letter-spacing: 0;

  @include breakpoint($desktop) {
    @include fontSize(24px);
    line-height: 28px;
  }
}

@mixin fifthHeader {
  @include fontSize(16px);
  color: $text;
  font-weight: $font-bold;
  line-height: 24px;
  letter-spacing: 0;

  @include breakpoint($desktop) {
    @include fontSize(20px);
    line-height: 26px;
  }
}

@mixin sixthHeader {
  @include fontSize(16px);
  color: $text;
  font-weight: $font-bold;
  line-height: 24px;
  letter-spacing: 0;

  @include breakpoint($desktop) {
    @include fontSize(16px);
    line-height: 20px;
  }
}

@mixin paragraph {
  @include fontSize(16px);
  color: $text;
  letter-spacing: -.2px;
  line-height: 22px;
  margin: 0 0 16px;
}

@mixin paragraphSmall {
  @include fontSize(12px);
  line-height: 20px;
  color: $headingText;
  letter-spacing: 0;
}

@mixin paragraphLarge {
  @include paragraph;
  @include fontSize(24px);
  color: $textLight;
  line-height: 32px;
}

@mixin inputQuantity {
  input.qty,
  input.item-qty {
    @include fontSize(14px);
    height: 40px;
    width: 40px;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    box-shadow: none;
    line-height: 1;
    text-align: center;
    font-family: $inter;
  }
}

@mixin quantityBox {
  .qty-box,
  .qty-click-event {
    @include fontSize(24px);
    display: inline-block;
    height: 40px;
    text-align: center;
    vertical-align: middle;
    line-height: 37px;
    width: 40px;
    border: 1px solid $border;
    font-family: sans-serif;
    font-weight: $font-regular;
    cursor: pointer;
    color: $textLight;

    &.subtract {
      border-radius: 4px 0 0 4px;
    }

    &.add {
      border-radius: 0 4px 4px 0;
    }
  }
}

@mixin resetQuantity {
  input[type=number],
  input[type=number] {
    appearance: none;
    box-sizing: border-box;
    margin: 0;
  }
}
