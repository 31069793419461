.page-bottom .newsletter {
  $this: &;
  padding: 32px 0;
  background: get-color(ui-dark, base);
  color: get-color(ui-white);
  border-bottom: solid 1px get-color(ui-dark, dark-80);
  margin: 64px 0 0;
  @include breakpoint($desktop) {
    display: flex;
    align-items: center;
    padding: 48px 0;
    margin: 80px 0 0;
  }
  &__container {
    padding: 0 24px;
    @include breakpoint($desktop) {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
    }
  }
  &__content {
    @include breakpoint($desktop) {
      width: 100%;
    }
  }
  &__heading {
    @include fontSize(24px);
    margin: 0 0 16px;
    color: get-color(ui-white);
    line-height: 1;
    @include breakpoint($desktop) {
      @include fontSize(32px);
    }
  }
  &__sub-heading {
    @include fontSize(14px);
    margin: 0;
    color: get-color(ui-white);
  }
  &__label {
    flex-grow: 3;

    &-text {
      display: none;
    }
  }
  &__form {
    display: flex;
    margin: 25px 0 0;
    @include breakpoint($desktop) {
      margin: 0;
      width: 50%;
    }
  }
  &__input {
    @include fontSize(14px);
    width: 100%;
    margin: 0;
    border: 1px solid $border;
  }
  &__button {
    margin: 0 0 0 8px;
    min-height: 40px;
    min-width: 120px;
    flex-grow: 1;
    width: auto;
    padding: 0;
    @include breakpoint($desktop) {
      height: 40px;
    }
  }
  // Column Variation
  &--column {
    display: block;
    padding: 48px 20px;
    background: $shade;
    #{$this}__content {
      margin: 0 0 24px;
      width: 100%;
    }
    #{$this}__form {
      width: 100%;
    }
  }
}

.newsletter-item {
  display: flex;
  flex-direction: row;
  padding: 10px 0 0;
  .newsletter-label {
    @include fontSize(14px);
    font-weight: $font-regular;
    margin: 0;
  }
  input[type="checkbox"] {
    width: 20px;
    height: 20px;
    padding: 10px;
  }
}
