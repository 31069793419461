// sass-lint:disable-all
.catalog-category-view {
  .finance-summary {
    @include fontSize(12px);
    margin: 8px 0 16px;
    line-height: 16px;
    display: flex;
    flex-direction: column;
    img {
      width: 50px;
      margin: 0 0 5px;
    }
  }
}

.catalog-product-view {
  .finance-summary {
    @include fontSize(12px);
    margin: 8px 0 16px;
    line-height: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      width: 50px;
      margin: 0 5px 0 0;
    }
  }
}

.modal-hitachi-finance {
  .modal-title::before {
    content: "\f51e";
    font-family: "Font Awesome 5 Pro";
    font-weight: $font-regular;
    margin: 0 18px 0 0;
  }

  .paybyfinance-info {
    padding: 24px 24px 8px;
    background: get-color(ui-dark, dark-10);

    p {
      @include fontSize(14px);
    }

    h5 {
      margin: 0 0 16px;
    }
  }

  .paybyfinance-content {
    padding: 24px 0;
    @include breakpoint($tablet) {
      padding: 24px;
    }
  }

}

#paybyfinance-main-block {
  margin-bottom: 30px;

  p {
    color: get-color(ui-dark, dark-75);
  }

  @media all and (max-width: 768px) {
    padding: 15px;
  }

  .slider {
    margin: 0;
  }

  .variable-data {
    color: get-color(ui-blue);
    font-weight: $font-bold;
  }

  .ui-slider-range {
    background: get-color(ui-blue);
    background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
    border-radius: 10px;
    height: 100%;
    background-size: 50px 50px;
    animation: move 2s linear infinite;
  }

  .invalid-reason {
    margin-bottom: 5px;
    padding: 7px 10px 7px 20px;
    background: #F4F4F4;
    border-left: 5px solid #FF6600;
  }

  .ui-slider-horizontal {
    height: 20px;
    box-shadow: inset 6px 6px 5px 1px rgb(167 167 167 / 50%);
  }

  .ruler {
    margin: 25px 0 0;

    .ruler-item {
      float: left;
      text-align: center;
      min-height: 1em;

      span {
        display: inline-block;
      }
    }
  }

.paybyfinance-content .type-widget.switcher div.ruler {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .ruler-item {
        width: 32% !important;
        font-size: 15px;
        padding: 0;
        margin: 0;

        &:first-child {
            text-align: left;
        }
    }
}

  .deposit-widget {
    margin: 25px 14px 40px;

    &.three,
    &.two {
      margin: 25px 15% 40px;
    }
  }

  .term-widget {
    margin: 47px 14px 15px;

    &.three,
    &.two {
      margin: 47px 15% 15px;
    }
  }

  .deposit-widget,
  .type-widget,
  .term-widget {
    &::after {
      content: "";
      display: table;
      clear: both;
    }
  }

  .term-widget {
    margin-top: 20px;

    &.three, &.two {
      margin-top: 20px;
    }
  }

  .ui-slider-horizontal .ui-slider-handle {
    margin-left: -10px;
  }

  .deposit-widget {
    margin-bottom: 10px;

    .ruler {
      clear: both;
      min-height: 1em;
      color: #666666;

      .ruler-item span {
        display: none;

        &::before {
          content: "";
          width: 15px;
          height: 15px;
          border-radius: 100%;
          background: #cfcfcf;
          display: block;
          position: relative;
          box-shadow: inset 0px 6px 5px 0px rgba(198,198,198,1);
          top: -10px;
          left: 5px;
        }

        &::after {
          content: "%";
          font-weight: normal;
        }
      }

      &.twentieth {
        .ruler-item:nth-child(20n+1) span {
          display: inline;
        }
      }

      &.tenth {
        .ruler-item:nth-child(10n+1) span {
          display: inline;
        }
      }

      &.fifth {
        .ruler-item:nth-child(5n+1) span {
          display: inline;
        }
      }

      &.second {
        .ruler-item:nth-child(2n+1) span {
          display: inline;
        }
      }

      &.all {
        .ruler-item span {
          display: inline;
        }
      }
    }
  }

  .type-widget.switcher {
    .slider {
      width: 25%;
      margin: 0 auto;
    }

    .ruler {
      margin: -26px 0 0 0;

      .ruler-item:nth-child(2) {
          text-align: right;
      }
    }
  }

  .type-widget:not(.switcher) .ruler {
    .ruler-item:first-child {
      text-align: left;

      span {
        width: 45%;
        padding-left: 45%;
      }
    }

    .ruler-item:last-child {
      text-align: right;

      span {
        width: 45%;
        padding-right: 45%;
      }
    }
  }

  .paybyfinance-sum {
    background: #f4f4f4;
    border: 1px solid #ccc;
    margin: 10px 0;
    padding: 10px;

    td {
      @include fontSize(16px);
      background: get-color(ui-white);
      padding: 10px;
      border: 0;
      display: table-cell;
      &::before {
        display: none;
      }
    }

    .variable-data {
      color: #666666;
    }

    .finance-total {
      font-weight: $font-bold;
    }
  }

  .footnote {
    p {
      @include fontSize(14px);
    }
  }

  .ui-slider-handle {
    transition-property: left;
    transition-duration: 0.1s;
    width: 25px;
    height: 25px;
    border-radius: 13px;
    border: 1px solid #999;
    margin-top: -2px;
    box-shadow: inset 0px 2px 2px #555;
    background: #797979;
    cursor: pointer;
    top: 0px;
  }

  .variable-deposit {
    margin: 0 0 20px 0;

    .label {
      display: block;
    }

    .input-text.deposit-value {
      width: 150px;
    }

    .control {
      display: inline-block;
      margin-right: 10px;
    }

    .actions {
      display: inline-block;

      button {
        margin-top: -2px;
      }
    }
  }

  &.small-widget {
    .variable-deposit {
      margin-bottom: 20px;
    }

    .deposit-widget {
      margin: 27px 14px 13px;

      &.three, &.two {
        margin: 27px 15% 13px;
      }
    }

    .type-widget {
      background: get-color(ui-dark, dark-05);
      padding: 30px 20px 20px;
      margin: 16px 0;
    }

    .term-widget {
      margin: 20px 14px 20px;

      &.three, &.two {
        margin: 20px 15% 20px;
      }
    }
  }
}

table.table-totals.finance-enabled,
table.totals.finance-enabled {
  &::after {
    @include fontSize(12px);
    content: "* Order Total - amount to be paid by credit/debit card";
    clear: both;
    display: block;
  }

  .grand.totals th::before {
    @include fontSize(12px);
    content: "*";
  }
}

.pbffinance-address-on .new-address-popup,
.pbffinance-on .payment-method-billing-address .action-edit-address,
.pbffinance-on .payment-method-billing-address .billing-address-same-as-shipping-block {
  display: none;
}

.paybyfinance-representative-example {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline;

    li {
      display: inline;
      margin-bottom: 0;
    }
  }

  p {
    display: inline;
  }
}

#pbf-address-not-possible-error {
  margin-bottom: 20px;
}

button.addFinance {
  width: 100%;
  padding: 16px 32px;
}

@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}
#pbf-address-not-possible-error-shipping {
  @include fontSize(14px);
  padding: 13px 16px;
  position: relative;
  display: block;
  justify-content: left;
  margin: 0 0 16px;
  background: get-color(ui-error, error-20);
  border: solid 1px get-color(ui-error, error-30);
  color: get-color(ui-error, base);
  border-radius: 4px;
}
// sass-lint:enable-all
