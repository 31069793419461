.slick-prev,
.slick-next {
  color: get-color(ui-dark, base);
  z-index: 9;

  &::before {
    color: get-color(ui-dark, base);
  }
}

.slick-next {
  right: 25px;

  &::before {
    content: "\f054";
    font-family: "Font Awesome 5 Pro";
  }
}

.slick-prev {
  left: 25px;

  &::before {
    content: "\f053";
    font-family: "Font Awesome 5 Pro";
  }
}

.slick-dots {
  display: flex !important;
  position: relative;
  bottom: -12px;
  width: 243px;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;

  li {
    width: 100%;
    height: 2px;
    padding: 0;
    margin: 0;

    button {
      width: 100%;
      height: 2px;
      background: get-color(ui-dark, dark-20);
      padding: 0;
      margin: 0;
    }

    button::before {
      display: none;
    }

    &.slick-active {
      button {
        background: get-color(ui-dark, dark-70);
        border-radius: 1px;
      }
    }
  }
}
