//
// Media elements generic styles
//

img {
  max-width: 100%;
  height: auto;
}

figure {
  margin: 0;
}

figure img {
  width: 100%;
}

figcaption {
  @include paragraph;
  padding-top: 8px;
}
