.block-search {
  @include breakpoint($desktop-large) {
    max-width: 389px;
  }

  form.minisearch {
    position: relative;
    display: flex;
    flex-direction: row;
  }

  .field {
    width: 100%;
    margin: 0;
  }

  input {
    padding: 9px 40px 9px 16px;
    width: 100%;
    border: solid 1px get-color(ui-dark, base);
    border-radius: 2px 0 0 2px;

    &:focus,
    &:active {
      box-shadow: 0;
      outline: 0;
    }
    &.focus-visible {
      // sass-lint:disable-all
      border: solid 1px get-color(ui-red, base) !important;
      // sass-lint:enable-all
    }
  }

  .block-content {

    .actions {

      button {
        @include fontSize(19.2px);
        border: 0;
        border-radius: 0 2px 2px 0;
        color: get-color(ui-white);
        padding: 9px 10.5px;
        background: get-color(ui-red, base);

        i {
          padding: 0;
        }

        &:disabled {
          background: get-color(ui-dark, dark-80);
        }

      }
    }
  }
}
