.cms-index-index {

  .page-top {
    display: flex;
    flex-direction: column;
    .homepage-banner__wrapper {
      order: 2;
      @include breakpoint($desktop-mid) {
        order: 1;
      }
    }
    .usps-container {
      order: 1;
      @include breakpoint($desktop-mid) {
        order: 2;
      }
    }
  }

  .homepage-banner {
    position: relative;

    .slick-dotted.slick-slider {
      margin: 0;
    }

    .slick-list {
      @include breakpoint($desktop-mid) {
        height: unset;
      }
    }

    &__wrapper {
      position: relative;
    }

    &__container {
      position: relative;
      left: 0;
      right: 0;
      z-index: 9;
    }

    &__inner {
      max-width: 418px;
      margin: 0 24px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -700px;
      padding: 32px 42px 80px;
      @include breakpoint($tablet) {
        margin: 0 auto;
        padding: 32px 42px;
      }
      @include breakpoint($desktop-mid) {
        margin: 178px auto 64px;
        bottom: unset;
      }
      background: get-color(ui-dark, dark-10);
      border-radius: 4px;
      box-shadow: 0 12px 24px -4px rgba(35, 31, 32, .2), 0 8px 16px -8px rgba(35, 31, 32, .2);
      text-align: center;
      min-height: 292px;
    }

    &__heading {
      @include fontSize(24px);
      color: get-color(ui-dark, base);
      font-weight: $font-bold;
      line-height: 28px;
      margin: 0 0 16px;
    }

    &__content {
      @include fontSize(16px);
      color: get-color(ui-dark, dark-80);
      margin: 0 0 24px;
      line-height: 22px;
    }

    &__button {
      .btn {
        display: inline-block;
      }
    }

    &__image {
      display: block;
      width: 100%;
      height: auto;
      text-align: center;
      @include breakpoint($desktop-mid) {
        height: 534px;
      }
      img {
        height: auto;
        margin: 0 auto;
      }
      @include breakpoint($desktop-mid) {
        img {
          height: 534px;
        }
      }
    }
    &__controls {
      width: 243px;
      max-width: 100%;
      padding: 0;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: 110px;
      @include breakpoint($desktop-mid) {
        width: 334px;
        margin: 0 auto;
        padding: 0;
        bottom: 135px;
      }
      .nav-prev {
        position: absolute;
        left: 0;
        top: 5px;
        z-index: 9;
      }
      .nav-next {
        position: absolute;
        right: 0;
        top: 5px;
        z-index: 9;
      }
    }
  }

  .homepage-promo-slider {
    width: 100%;
    max-width: 100%;
    padding: 0;
    @include breakpoint($desktop-mid) {
      max-width: var(--grid-max);
      margin: 0 auto;
      padding: 0;
    }
    &__wrapper {
      display: none;
      background: linear-gradient(180deg, #FFFFFF 0%, #F6F6F6 100%);
      padding: 0 32px 32px;
      @include breakpoint($desktop-mid) {
        padding: 64px 32px;
      }
    }
    .slick-slide {
      @include breakpoint($tablet) {
        margin: 0 16px;
      }
    }
    &__controls {
      position: relative;
      margin: 20px auto 0;
      width: 90%;
      padding: 0;
      @include breakpoint($desktop-mid) {
        width: 568px;
        margin: 40px auto 0;
        padding: 0;
      }
    }
    &__dots {
      .slick-dots {
        @include breakpoint($desktop-mid) {
          width: 504px;
        }
        li {
          width: 81px;
          @include breakpoint($desktop-mid) {
            width: 142px;
          }
          button {
            width: 100%;
          }
          &.slick-active {
            button {
              background: get-color(ui-red, base);
            }
          }
        }
      }
    }
    &__nav {

      .nav-prev {
        position: absolute;
        left: 0;
        top: 5px;
        z-index: 9;
      }

      .nav-next {
        position: absolute;
        right: 0;
        top: 5px;
        z-index: 9;
      }
    }
  }

  .columns {
    max-width: 100%;
    margin: 0;
    .column.main {
       .home-banner-usp > div[data-content-type="html"] {
         display: flex;
         flex-direction: column;
         .usps-container {
           order: -1;
         }
        }
      @include breakpoint($desktop-mid) {
        .home-banner-usp > div[data-content-type="html"] {
          .usps-container {
            order: 2;
          }
        }
      }
        [data-content-type='row'][data-appearance='contained'] {
            max-width: 1600px;
        }
    }
  }

  .shop-by-brand {
    margin: 80px 0;

    h3 {
      margin: 0 0 16px;
      text-align: center;
    }
  }

  .page-main {
    order: 4;
    padding: 0;
    @include breakpoint($tablet) {
      margin: 0 auto;
    }
  }

  .reviews-slider-wrapper {
    margin: 64px auto;
    @include breakpoint($desktop-mid) {
      margin: 80px auto;
    }
  }

  div[data-content-type="text"] {
    max-width: 868px;
    // sass-lint:disable-all
    margin: 0 auto !important;
    // sass-lint:enable-all
  }

  .block-products-list {
    border-top: solid 1px $border;
    .block-title {
      margin: 80px 0 48px;
    }
    .call-for-price-view {
      display: none;
    }
    .finance-summary {
      flex-direction: row;
      margin: 10px 0 0;
      img {
        width: 50px;
        margin: 0 5px 0 0;
      }
    }
  }

}
