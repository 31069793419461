//
// Breakpoint variables
//
$mobile: 480px;
$tablet: 753px;
$desktop: 992px;
$desktop-mid: 1200px;
$desktop-large: 1440px;
//

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap"); //sass-lint:disable-line no-url-domains

$inter: "Inter", sans-serif;
$font-regular: 400;
$font-medium: 500;
$font-bold: 600;

//
// CSS variables for Grid
//
:root {
  --gutter: 16px;
  --columns: 16;
  --window-buffer: 24px;
  --grid-max: 1440px;
}

@include breakpoint($tablet) {
  :root {
    --gutter: 22px;
    --columns: 12;
    --window-buffer: 20px;
    --grid-max: 1440px;
  }
}

@include breakpoint($desktop) {
  :root {
    --gutter: 32px;
    --columns: 12;
    --window-buffer: 16px;
    --grid-max: 1440px;
  }
}
