//
// Breadcrumb
//

.breadcrumbs {
  padding: 0 24px 32px;
  @include breakpoint($desktop) {
    padding: 0 0 48px;
  }
  .items {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .item {
      &::after {
        @include fontSize(11.2px);
        font-family: "Font Awesome 5 Pro";
        padding: 0 8px;
        content: "\f054";
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
      strong {
        font-weight: $font-regular;
      }
    }
  }
}
