.add-to-cart-stocky {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: inset 0 -1px 0 0 #D3D2D2, 0 12px 24px -4px rgba(35, 31, 32, .1), 0 8px 16px -8px rgba(35, 31, 32, .1);
  padding: 16px 0;
  @include breakpoint($desktop) {
    padding: 8px 0;
  }
  background: get-color(ui-white);
  z-index: 99;

  &__wrapper {
    @include fontSize(20px);
    font-weight: $font-bold;
    width: 100%;
    max-width: 100%;
    padding: 0 16px;
    @include breakpoint($desktop-mid) {
      max-width: var(--grid-max);
      margin: 0 auto;
      padding: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__details {
    display: none;
    @include breakpoint($desktop) {
      display: flex;
      flex-basis: 50%;
    }
    align-items: center;

    img {
      width: 64px;
      float: left;
      margin: 0 16px 0 0;
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    button {
      padding: 8px 28px;
      min-width: 203px;
      @include breakpoint($desktop) {
        margin: 0 0 0 24px;
      }
    }
  }

}
