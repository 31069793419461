.field-tooltip {
  display: inline-block;
  cursor: pointer;
  position: relative;
  right: 0;
  top: -4px
}

.field-tooltip._active {
  z-index: 100
}

.field-tooltip._active .field-tooltip-content {
  display: block
}

.field-tooltip._active .field-tooltip-action:before {
  color: #333
}

.field-tooltip .label {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px
}

.field-tooltip .field-tooltip-action {
  display: inline-block;
  text-decoration: none
}

.field-tooltip .field-tooltip-action>span {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px
}

.field-tooltip .field-tooltip-action:before {
  @include fontSize(14px);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: inherit;
  color: get-color(ui-dark, base);
  content: "\f059";
  font-family: "Font Awesome 5 Pro";
  vertical-align: middle;
  display: inline-block;
  font-weight: normal;
  overflow: hidden;
  speak: none;
  text-align: center
}

.field-tooltip .field-tooltip-action:hover:before {
  color: #333
}

.field-tooltip .field-tooltip-action:before {
  padding-left: 1px
}

._keyfocus .field-tooltip .field-tooltip-action:focus {
  z-index: 100
}

._keyfocus .field-tooltip .field-tooltip-action:focus+.field-tooltip-content {
  display: block
}

._keyfocus .field-tooltip .field-tooltip-action:focus:before {
  color: #333
}

.field-tooltip .field-tooltip-content {
  @include fontSize(14px);
  font-weight: $font-regular;
  background: get-color(ui-white);
  border: 1px solid get-color(ui-dark, dark-20);
  border-radius: 4px;
  padding: 12px;
  width: 270px;
  display: none;
  left: 38px;
  position: absolute;
  text-transform: none;
  top: -9px;
  word-wrap: break-word;
  z-index: 2;
  box-shadow: 0 12px 24px -4px rgba(35,31,32,0.1), 0 8px 16px -8px rgba(35,31,32,0.1);
}

.field-tooltip .field-tooltip-content:before,.field-tooltip .field-tooltip-content:after {
  border: 10px solid transparent;
  height: 0;
  width: 0;
  border-right-color: get-color(ui-white);
  left: -21px;
  top: 12px;
  content: '';
  display: block;
  position: absolute;
  z-index: 3
}

.field-tooltip .field-tooltip-content:before {
  border-right-color: get-color(ui-dark, dark-20)
}

.field-tooltip .field-tooltip-content:after {
  border-right-color: get-color(ui-white);
  width: 1px;
  z-index: 4
}

@media only screen and (max-width: 768px) {
  .field-tooltip .field-tooltip-content {
    left:auto;
    right: -10px;
    top: 40px
  }

  .field-tooltip .field-tooltip-content::before,.field-tooltip .field-tooltip-content::after {
    border: 10px solid transparent;
    height: 0;
    left: auto;
    margin-top: -21px;
    right: 10px;
    top: 0;
    width: 0
  }

  .field-tooltip .field-tooltip-content::before {
    border-bottom-color: #999;
  }

  .field-tooltip .field-tooltip-content::after {
    border-bottom-color: #f4f4f4;
    top: 1px
  }
}