.cart-container {
  .cart-summary {
    margin: 24px 0 0;
    background: get-color(ui-dark, dark-10);
    padding: 22px;
    display: flex;
    flex-direction: column;
    @include breakpoint($desktop) {
      width: span(6);
      margin: 0;
    }

    .catalog-sub-actions,
    #paybyfinance-not-shown,
    .summary.title,
    .block.giftcard,
    .action.multicheckout,
    .totals-tax {
      display: none;
    }

    &__inner {
      background: get-color(ui-dark, dark-10);
      padding: 22px 14px;
      @include breakpoint($desktop) {
        padding: 32px;
      }
    }

    &__items {
      @include fontSize(16px);
      margin: 0 0 10px;

      span {
        font-weight: $font-bold;
      }
    }

    .shipping {

    }

    &__count {
      display: block;
      width: 50%;
      float: left;
      font-weight: $font-bold;
      padding: 7px 0;
    }

    .cart-totals {
      display: block;
      float: right;
      text-align: right;
      order: 1;
    }

    .table-caption,
    .grand.totals {
      display: none;
    }

    .grand.totals {
      th,
      td {
        @include fontSize(18px);
        font-weight: $font-bold;
      }
    }

    // Show grand total if discount enabled
    .sub + .totals + .grand {
      display: table-row;
    }

    .totals {
      width: 100%;
      border: 0;
      padding: 0;
      margin: 0 0 24px;

      tr {
        box-shadow: none;
      }

      th {
        text-align: left;
        font-weight: $font-regular;
        display: table-cell;
        .discount.coupon {
          display: none;
        }
      }

      td {
        text-align: right;
        font-weight: $font-bold;
        .price {
          display: block;
          padding-left: 12px;
        }
      }

      strong {
        font-weight: $font-bold;
      }

      th,
      td {
        @include fontSize(16px);
        padding: 8px 0;
        border: 0;

        &::before {
          display: none;
        }
      }
    }
  }

  .basket-continue {
    @include fontSize(16px);
    border: 1px solid get-color(ui-dark, base);
    border-radius: 2px;
    font-weight: $font-bold;
    text-align: center;
    padding: 12px;
    margin: 24px 20px;
    display: block;
  }
}

.checkout-methods-items {
  list-style: none;
  margin: 0 0 24px;
  padding: 0 0 8px;
  order: 2;
  border-bottom: solid 1px $border;

  li {
    margin: 0 0 16px;
  }

  button {
    width: 100%;
    padding: 17px;
  }
  #minicart-amazon-pay-button {
    .amazon-divider {
        display: none;
    }
    .amazon-button-column {
       margin-top: 16px;
       #PayWithAmazon-Cart > div {
         width: 100% !important;
         height: 80px !important;
       }
       .amazonpay-button-container {
         .amazonpay-button-view1 {
             height: 50px !important;
         }
         .amazonpay-button-view3 {
             width: 50%;
             margin: 0 auto;
         }
       }
    }
  }
}

.basket-discount,
.block.shipping {
  border: 1px solid $border;
  border-radius: 2px;
  margin: 0 0 16px;
  order: 3;
  $this: &;

  &.active {
    #{$this}__heading {
      &::before {
        background-image: url("../images/icons/minus.svg");
      }
    }
  }

  &__heading,
  .title {
    @include fontSize(14px);
    color: $textLight;
    font-weight: $font-bold;
    padding: 11px 16px;
    position: relative;
    cursor: pointer;
    background: none;
    width: 100%;
    text-align: left;

    &::before {
      content: "";
      background-image: url("../images/icons/plus.svg");
      background-repeat: no-repeat;
      background-position: center;
      display: block;
      width: 10px;
      height: 10px;
      position: absolute;
      right: 16px;
      top: 50%;
      margin: -5px 0 0;
    }
  }

  &__content,
  .content {
    background: get-color(ui-white);
    padding: 11px 16px;
    border-top: 1px solid $border;
  }

  &__fieldset {
    display: flex;

    .field {
      margin: 0 7px 0 0;
      width: 100%;
    }
  }

  .input-text {
    appearance: none;
  }

  .input-text,
  .action {
    height: 43px;
  }

  div.mage-error {
    @include fontSize(14px);
    margin: 10px 0 2px;
    color: #ff0000;
  }
}

.block.shipping {
  order: 4;
  br {
    display: none;
  }
  fieldset {
    border: 0;
    padding: 0;
    margin: 0;
  }
  legend {
    display: none;
  }
}

.basket-icon-wrapper {
  order: 99;
  text-align: center;
}

.basket-logos {
  width: 100%;
  margin: 24px auto 0;
  display: block;
  order: 4;
  text-align: center;
  img {
    max-width: 220px;
    margin: 0 auto;
  }
}

.paypal-review .basket-logos {
  display: none;
}
