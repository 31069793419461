.popular-categories {
  &__wrapper {
    margin: 0 auto 32px;
    @include breakpoint($desktop-mid) {
      margin: 0 auto 80px;
    }
    .slick-slide {
      margin: 0 12px;
      @include breakpoint($desktop-mid) {
        margin: 0 20px 0 0;
      }
    }
    .item-wrapper {
      padding: 20px 0 0;
      @include breakpoint($desktop-mid) {
        padding: 50px 0 0;
      }
    }
    .item {
      padding: 20px;
      @include breakpoint($desktop-mid) {
        padding: 32px;
        min-height: 244px;
      }
      background: get-color(ui-dark, dark-10);
      border-radius: 4px;
      position: relative;
      &-image-wrapper {
        z-index: 999;
        margin: -30px auto 0;
        min-height: 140px;
        @include breakpoint($desktop-mid) {
          margin: -80px auto 0;
        }
      }
      img {
        width: 139px;
        height: auto;
        margin: 0 auto;
        @include breakpoint($desktop-mid) {
          width: 220px;
        }
      }
    }
  }
  &__title {
    @include fontSize(14px);
    text-align: center;
    font-weight: $font-bold;
    padding: 24px 0 0;
    @include breakpoint($desktop-mid) {
      @include fontSize(20px);
      padding: 32px 0 0;
    }
  }
  &__controls {
    position: relative;
    margin: 20px auto 0;
    width: 90%;
    padding: 0;
    @include breakpoint($desktop-mid) {
      width: 568px;
      margin: 40px auto 0;
      padding: 0;
    }
  }
  &__dots {
    .slick-dots {
      @include breakpoint($desktop-mid) {
        width: 504px;
      }
      li {
        button {
          width: 100%;
        }
        &.slick-active {
          button {
            background: get-color(ui-dark, base);
          }
        }
      }
    }
  }
  &__nav {
    .nav-prev {
      position: absolute;
      left: 0;
      top: 5px;
      z-index: 9;
    }
    .nav-next {
      position: absolute;
      right: 0;
      top: 5px;
      z-index: 9;
    }
  }
}

.products-grid {
  @extend %vertical-spacer-top;
  @extend %vertical-spacer-bottom;

  .items,
  .product-items {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-left: 0;
    list-style: none outside;
  }

  .item,
  .product-item {
    flex-basis: span(8);
    margin-bottom: 24px;
    border: solid 1px get-color(ui-dark, dark-20);
    border-radius: 4px;
    padding: 16px;
    @include breakpoint($tablet) {
      flex-basis: span(4);
    }
    @include breakpoint($desktop-mid) {
      flex-basis: span(3);
    }

    &:nth-of-type(odd) {
      margin-right: gutter();
    }

    &:nth-of-type(4n + 2) {
      @include breakpoint($tablet) {
        margin-right: gutter();
      }
    }

    &:nth-of-type(4n + 3) {
      @include breakpoint($tablet) {
        margin-right: 0;
      }
      @include breakpoint($desktop-mid) {
        margin-right: gutter();
      }
    }
  }

  .product-item-info {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
  }

  .product-image-container {
    width: 100%; // to override inline style added by default Magento JS
    margin-bottom: 12px;
  }

  .product-image-wrapper {
    padding-bottom: 0; // to override inline style added by default Magento JS
  }

  .product-item-photo {
    display: flex;
    position: relative;
    align-items: center;

    img {
      width: auto;
      max-height: 248px;
      margin: 0 auto;
    }

    .stock {
      @include fontSize(14px);
      position: absolute;
      top: -12px;
      left: -12px;
      border-radius: 2px;
      border: solid 1px get-color(ui-dark, dark-20);
      background: get-color(ui-white);
      font-weight: $font-bold;
      padding: 3px 8px;
    }

    .energy-rating {
      position: absolute;
      left: 0;
      bottom: 0;
      width: auto;

      img {
        margin: 0;
      }

    }

    .sale-badge {
      @include fontSize(14px);
      background: get-color(ui-red, base);
      color: get-color(ui-white);
      padding: 4px 9.5px;
      position: absolute;
      top: -12px;
      left: -12px;
      border-radius: 2px;
      font-weight: $font-bold;
    }

  }

  .product-item-details {
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
    margin: 16px 0 0;
  }

  .product-item-name {
    @include fontSize(14px);
    display: block;
    line-height: 18px;
    font-weight: $font-regular;

    .product-item-link {
      @include fontSize(14px);
      line-height: 18px;
      display: block;
    }
  }

  .finance-summary {
    @include fontSize(12px);
    display: flex;
    flex-direction: column;
    img {
      width: 50px;
      margin: 0 0 10px;
    }
  }

  .product-item-sku {
    @include fontSize(12px);
    line-height: 20px;
    color: $textLight;
  }

  .product-item-description {
    @include fontSize(12px);
    display: none;
    @include breakpoint($desktop) {
      display: block;
      li {
        @include fontSize(12px);
      }
    }
  }

  .price-box,
  .swatch-attribute,
  .product-reviews-summary {
    padding-top: 8px;
    margin-top: 8px;
  }

  .price-box {
    @include fontSize(14px);
    line-height: 22px;
    font-weight: $font-bold;
    color: $headingText;
    // margin-top: auto;
    display: flex;
    flex-flow: row nowrap;

    .price-label {
      @include fontSize(12px);
      line-height: 20px;
      color: $textLight;
      text-transform: uppercase;
      font-weight: $font-regular;
      margin-right: 8px;
    }

    :not(.normal-price) .price-final_price {

      .price-label {
        display: none;
      }
    }

    .price-from {
      margin-right: 8px;

      &::after {
        content: "-";
        margin-left: 4px;
      }
    }

    .old-price {
      order: 1;
      color: $text;
      font-weight: $font-regular;
      text-decoration: line-through;
      margin-right: 8px;
    }

    .special-price {
      color: $primary;
    }
  }

  .product-item-inner {
    margin-top: auto; // to stick it to the bottom
    padding-top: 12px;
  }

  .product-item-actions {
    display: flex;
    flex-flow: row nowrap;

    .super-attribute-select {
      display: none;
    }

    .actions-primary {
      flex-grow: 1;

      button {
        width: 100%;
      }
    }

    .actions-secondary {
      flex-basis: 40px;
      height: 40px;
      margin-left: 8px;
	  display:none;
    }

    .towishlist {
      display: block;
      border: 1px solid $fieldBorder;
      border-radius: 4px;
      box-shadow: 0 1px 2px rgba(33, 43, 54, .1);
      width: 100%;
      height: 100%;

      span {
        @extend %visually-hidden;
      }

      &::after {
        @include icon-svg-reset;
        width: 40px;
        height: 40px;
        background: url(../images/icons/icon-wishlist.svg) 50% 50% no-repeat;
        background-size: 20px;
      }
    }
  }

  .product-reviews-summary {

    .rating-summary {

      .label {
        @extend %visually-hidden;
      }
    }

    .reviews-actions {
      display: none;
    }

    .rating-result {
      background-color: $border;
      height: 20px;
      width: 100px;
      position: relative;

      &::before {
        content: "";
        width: 100px;
        height: 20px;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: url(../images/icons/star-stencil2.svg) 50% 50% no-repeat;
        background-size: contain;
        z-index: 10;
      }

      span {
        background-color: $primary;
        height: 20px;
        display: block;
        position: relative;
        top: 0;
        left: 0;

        span {
          @extend %visually-hidden;
        }
      }
    }
  }
}

.products-list {
  ol {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  li {
    margin: 0 0 16px;
    border: solid 1px $border;
    padding: 16px;
    border-radius: 4px;
    flex-basis: 100%;
    @include breakpoint($tablet) {
      flex-basis: span(6);
    }
    @include breakpoint($desktop-mid) {
      flex-basis: span(16);
    }

    &:nth-of-type(odd) {
      @include breakpoint($tablet) {
        margin-right: gutter();
      }
      @include breakpoint($desktop-mid) {
        margin-right: 0;
      }
    }
  }

  .product-item-info {
    @include breakpoint($desktop-mid) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .product-image__wrapper {
    width: 108px;
    position: relative;
    margin: 0 16px 0 0;
    display: inline-block;
    vertical-align: top;
    @include breakpoint($desktop-mid) {
      flex-basis: 192px;
      width: 192px;
      margin: 0 24px 0 0;
    }

    img {
      width: auto;
      max-height: 219px;
    }

    a {
      display: block;
      position: relative;
    }

    .stock {
      @include fontSize(14px);
      position: absolute;
      top: -12px;
      left: -12px;
      border-radius: 2px;
      border: solid 1px get-color(ui-dark, dark-20);
      background: get-color(ui-white);
      font-weight: $font-bold;
      padding: 3px 8px;
    }

    .energy-rating {
      position: relative;
      left: 0;
      bottom: 0;
    }

    .sale-badge {
      @include fontSize(14px);
      background: get-color(ui-red, base);
      color: get-color(ui-white);
      padding: 4px 9.5px;
      position: absolute;
      top: -12px;
      left: -12px;
      border-radius: 2px;
      font-weight: $font-bold;
    }
    .product-item-photo {
      position: relative;
    }

    .black-friday-image {
      width: 35px;
      position: absolute;
      right: 0;
      bottom: 0;
      @include breakpoint($desktop-mid) {
        width: 60px;
      }
    }
	
	.quiet-mark-image {
      width: 35px;
      position: absolute;
      left: 0;
      top: 0;
      @include breakpoint($desktop-mid) {
        width: 60px;
      }
    }
  }

  .product-item-details {
    width: 165px;
    display: inline-block;
    .brand-logo {
      width: 100px;
      margin-bottom: 16px;
    }
    @include breakpoint($desktop-mid) {
      flex-basis: 404px;
      margin: 0 24px 0 0;
    }
  }

  .promos-usp {
    &__text {
      i {
        margin-right: 0.5rem;
      }
    }
    &__imgs {
      img {
        margin-right: 0.5rem;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  .product-price-wrapper {
    width: 100%;
    @include breakpoint($desktop-mid) {
      flex-basis: 192px;
    }
	.product-item-inner {
	  .actions-primary {
			.amquote-addto-button {
					  min-width: 260px;
					  padding: 16px 28px;
					  margin-top: 5px;
			}
	  }        
	}
  }

  h5 {
    a {
      @include fontSize(16px);
      display: block;
      font-weight: $font-regular;
      margin: 0 0 16px;
      color: get-color(ui-dark, dark-80);
    }
  }

  .product-item-description-desktop,
  .product-item-description-mobile {
    @include fontSize(12px);
    color: get-color(ui-dark, dark-80);

    p {
      @include fontSize(12px);
      color: get-color(ui-dark, dark-80);
    }

    p:last-child {
      margin: 0;
    }
  }

  .product-item-description-mobile,
  .product-mobile-pricing {
    display: block;
    @include breakpoint($desktop-mid) {
      display: none;
    }

    &.out-of-stock {
      color: get-color(ui-dark, dark-20);
    }
  }

  .product-item-description-desktop,
  .product-desktop-pricing {
    display: none;
    @include breakpoint($desktop-mid) {
      display: block;
      ul {
        list-style: disc;
      }
      li {
        @include fontSize(12px);
        margin: 0 0 4px;
        padding: 0;
        border: 0;
      }
    }

    &.out-of-stock {
      opacity: 0.5;
    }

    .finance-summary {
      @include fontSize(12px);
      display: flex;
      flex-direction: column;
      img {
        width: 50px;
        margin: 15px 0 5px;
      }
    }
  }

  .summary-overview {
    border-radius: 4px;
    background: get-color(ui-dark, dark-10);
    padding: 12px;
    margin: 10px 0 0;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li {
      @include fontSize(12px);
      border: 0;
      padding: 0;
      margin: 0 0 6px;
      font-weight: $font-bold;

      &:last-child {
        margin: 0;
      }

      i {
        @include fontSize(16px);
        color: get-color(ui-red, base);
        margin: 0 8px 0 0;
      }
    }
  }
  .availability.only {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    text-align: center;
    color: white;
    background-color: #ef1c24;
    margin-top: 0.5rem;
    strong {
      margin-left: 2px;
      margin-right: 2px;
    }
    &::before {
      content: "";
      height: 18px;
      width: 18px;
      display: inline-block;
      background-image: url("../images/svg/warning.svg");
      background-size: cover;
      background-repeat: no-repeat;
      margin-right: 5px;
    }
  }
}

.category-view {
  @include breakpoint($desktop-mid) {
    margin: 128px 0 0;
  }
}

.category-brand {
  // sass-lint:disable-all
  margin: 0 0 32px !important;
  @include breakpoint($desktop-mid) {
    margin: 0 0 80px !important;
  }
  // sass-lint:enable-all
  h3 {
    display: none;
  }
}

.category-promoslots {
  .pagebuilder-column-group {
    // sass-lint:disable-all
    margin: 0 0 32px !important;
    @include breakpoint($desktop-mid) {
      margin: 0 0 80px !important;
    }
    // sass-lint:enable-all
    .pagebuilder-column:first-child {
      // sass-lint:disable-all
      margin: 0 0 16px !important;
      @include breakpoint($desktop-mid) {
        margin: 0 16px 0 0 !important;
      }
      // sass-lint:enable-all
    }

    .pagebuilder-column:last-child {
      // sass-lint:disable-all
      @include breakpoint($desktop-mid) {
        margin: 0 0 16px !important;
      }
      // sass-lint:enable-all
    }
  }
}

.block-viewed-products-grid,
.block.related,
.block.upsell,
.block-products-list {
  padding: 0 24px;
  @include breakpoint($desktop-mid) {
    padding: 0;
  }

  .product-item-photo {
    min-height: 246px;
  }

  .block-title {
    @include fontSize(24px);
    margin: 65px 0 32px;
    padding: 64px 0 0;
    border-top: solid 1px get-color(ui-dark, dark-20);
    @include breakpoint($desktop) {
      @include fontSize(32px);
      margin: 80px 0 48px;
    }
    text-align: center;
  }

  .finance-summary {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: $font-regular;
    img {
      width: 50px;
      margin: 0 5px 0 0;
    }
  }

  .slick-slide {
    min-width: 157px;
  }

  .product-item:nth-child(4) {
    @include breakpoint($tablet) {
      display: none;
    }
    @include breakpoint($desktop-mid) {
      display: block;
    }
  }

  .product-item-details {
    .price {
      @include fontSize(16px);
    }
    .out-of-stock,
    .out-of-stock .price {
      opacity: 0.5;
    }
  }

  .product-item-description {
    margin: 16px 0 0;
    p {
      @include fontSize(12px);
      margin: 0;
    }
  }

  .widget-viewed-grid__controls,
  .widget-product-list__controls {
    position: relative;
    margin: 20px auto 0;
    width: 90%;
    padding: 0;
    @include breakpoint($desktop-mid) {
      width: 568px;
      margin: 40px auto 0;
      padding: 0;
    }
  }

  .widget-product-list__dots {
    li {
      width: calc(100%/8) !important;
    }
  }

  .widget-viewed-grid__dots,
  .widget-product-list__dots {
    .slick-dots {
      @include breakpoint($desktop-mid) {
        width: 504px;
      }

      li {
        button {
          width: 100%;
        }

        &.slick-active {
          button {
            background: get-color(ui-dark, base);
          }
        }
      }
    }
  }

  .widget-viewed-grid__nav,
  .widget-product-list__nav {
    .nav-prev {
      position: absolute;
      left: 0;
      top: 5px;
      z-index: 9;
    }

    .nav-next {
      position: absolute;
      right: 0;
      top: 5px;
      z-index: 9;
    }
  }

  .slick-initialized .slick-slide {
    margin: 0 7px 0 0;
  }
}

.block-products-list {
  .block-title {
    border-top: 0;
    margin: 0 0 48px;
    padding: 0;
  }
}

.block.related {
  .block-title {
    border-top: 0;
    padding-top: 0;
    margin-top: 0;
  }
}

.catalog-category-view {
  .reviews-slider-wrapper {
    margin-top: 32px;
  }
}
