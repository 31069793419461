// sass-lint:disable-all
@font-face {
  font-family: 'pagebuilder-font';
  font-style: normal;
  font-weight: normal;
  src: url('../Magento_PageBuilder/fonts/pagebuilder-icons/pagebuilder-icons.eot');
  src: url('../Magento_PageBuilder/fonts/pagebuilder-icons/pagebuilder-icons.eot?#iefix') format('embedded-opentype'), url('../Magento_PageBuilder/fonts/pagebuilder-icons/pagebuilder-icons.woff') format('woff'), url('../Magento_PageBuilder/fonts/pagebuilder-icons/pagebuilder-icons.ttf') format('truetype');
}

.pagebuilder-icon,
.slick-prev:before,
.slick-next:before,
.slick-prev:after,
.slick-next:after {
  -webkit-font-smoothing: antialiased;
  font-family: 'pagebuilder-font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
}

[class^='pagebuilder-icon-']:before,
[class*=' pagebuilder-icon-']:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-family: 'pagebuilder-font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-decoration: inherit;
  text-rendering: auto;
  text-transform: none;
  vertical-align: middle;
}

.pagebuilder-icon-down:before {
  content: '\f101';
}

.pagebuilder-icon-next:before {
  content: '\f102';
}

.pagebuilder-icon-prev:before {
  content: '\f103';
}

.pagebuilder-icon-up:before {
  content: '\f104';
}

.product-full-width-section {
  padding: .5em 0;
}

.product-full-width-section .block.review-add {
  margin-top: 2.7rem;
}

.page-layout-product-full-width .block.related {
  margin-top: 2.7rem;
}

.page-main-details .product-section-title {
  border-bottom: 1px solid #c6c6c6;
  margin-bottom: 15px;
  padding-bottom: 12px;
}

.additional-attributes-wrapper .additional-attributes {
  border: none;
  width: auto;
}

.additional-attributes-wrapper .additional-attributes > tbody > tr > th {
  border: none;
  padding: 5.5px 30px 10px 0;
}

.additional-attributes-wrapper .additional-attributes > tbody > tr > td {
  border: none;
  padding: 5.5px 5px 10px;
}

.cms-index-index.page-layout-cms-full-width .nav-sections {
  margin-bottom: 0;
}

[dir='rtl'] .slick-slide {
  float: right;
}

[data-content-type='banner'] > [data-element='link'],
[data-content-type='banner'] > [data-element='empty_link'] {
  color: inherit;
  text-decoration: inherit;
}

[data-content-type='banner'] > [data-element='link']:hover,
[data-content-type='banner'] > [data-element='empty_link']:hover {
  color: inherit;
  text-decoration: inherit;
}

.pagebuilder-banner-wrapper {
  background-clip: padding-box;
  border-radius: inherit;
  box-sizing: border-box;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.pagebuilder-banner-wrapper .pagebuilder-overlay {
  -moz-transition: background-color 500ms ease;
  -o-transition: background-color 500ms ease;
  -webkit-transition: background-color 500ms ease;
  box-sizing: border-box;
  padding: 30px;
  position: relative;
  transition: background-color 500ms ease;
}

.pagebuilder-banner-wrapper .pagebuilder-overlay.pagebuilder-poster-overlay {
  align-items: center;
  display: flex;
  justify-content: center;
}

.pagebuilder-banner-wrapper .pagebuilder-overlay:not(.pagebuilder-poster-overlay) {
  max-width: 540px;
}

.pagebuilder-banner-wrapper.jarallax .video-overlay {
  z-index: 0;
}

.pagebuilder-banner-wrapper [data-element='content'] {
  min-height: 50px;
  overflow: auto;
}

.pagebuilder-banner-wrapper .pagebuilder-banner-button {
  -moz-transition: opacity 500ms ease;
  -o-transition: opacity 500ms ease;
  -webkit-transition: opacity 500ms ease;
  margin: 20px 0 0 0;
  max-width: 100%;
  text-align: inherit;
  transition: opacity 500ms ease;
  word-break: break-word;
}

.pagebuilder-banner-wrapper .pagebuilder-poster-content {
  width: 100%;
}

[data-appearance='collage-centered'] .pagebuilder-banner-wrapper .pagebuilder-overlay {
  margin-left: auto;
  margin-right: auto;
}

[data-appearance='collage-left'] .pagebuilder-banner-wrapper .pagebuilder-overlay {
  margin-right: auto;
}

[data-appearance='collage-right'] .pagebuilder-banner-wrapper .pagebuilder-overlay {
  margin-left: auto;
}

[data-content-type$='block'] .block p:last-child {
  margin-bottom: 1rem;
  margin-top: 0;
}

[data-content-type='buttons'] {
  max-width: 100%;
}

[data-content-type='button-item'] {
  max-width: 100%;
}

[data-content-type='button-item'] [data-element='link'],
[data-content-type='button-item'] [data-element='empty_link'] {
  max-width: 100%;
  word-wrap: break-word;
}

[data-content-type='button-item'] [data-element='empty_link'] {
  cursor: default;
}

a.pagebuilder-button-link,
button.pagebuilder-button-link,
div.pagebuilder-button-link,
.cart.table-wrapper .actions-toolbar > .action.pagebuilder-button-link,
.action-gift.pagebuilder-button-link {
  line-height: 2.2rem;
  font-size: 1.8rem;
  line-height: 1.42857143;
  margin: 0 10px 10px 0;
  padding: 14px 17px;
  color: #1979c3;
  text-decoration: none;
  background: none;
  border: 0;
  display: inline;
  font-weight: 600;
  box-shadow: none;
  box-sizing: border-box;
  display: inline-block;
}

a.pagebuilder-button-link:visited,
button.pagebuilder-button-link:visited,
div.pagebuilder-button-link:visited,
.cart.table-wrapper .actions-toolbar > .action.pagebuilder-button-link:visited,
.action-gift.pagebuilder-button-link:visited {
  color: #1979c3;
  text-decoration: none;
}

a.pagebuilder-button-link:hover,
button.pagebuilder-button-link:hover,
div.pagebuilder-button-link:hover,
.cart.table-wrapper .actions-toolbar > .action.pagebuilder-button-link:hover,
.action-gift.pagebuilder-button-link:hover {
  color: #006bb4;
  text-decoration: underline;
}

a.pagebuilder-button-link:active,
button.pagebuilder-button-link:active,
div.pagebuilder-button-link:active,
.cart.table-wrapper .actions-toolbar > .action.pagebuilder-button-link:active,
.action-gift.pagebuilder-button-link:active {
  color: #ff5501;
  text-decoration: underline;
}

a.pagebuilder-button-link:hover,
button.pagebuilder-button-link:hover,
div.pagebuilder-button-link:hover,
a.pagebuilder-button-link:active,
button.pagebuilder-button-link:active,
div.pagebuilder-button-link:active,
a.pagebuilder-button-link:focus,
button.pagebuilder-button-link:focus,
div.pagebuilder-button-link:focus,
.cart.table-wrapper .actions-toolbar > .action.pagebuilder-button-link:hover,
.cart.table-wrapper .actions-toolbar > .action.pagebuilder-button-link:active,
.cart.table-wrapper .actions-toolbar > .action.pagebuilder-button-link:focus,
.action-gift.pagebuilder-button-link:hover,
.action-gift.pagebuilder-button-link:active,
.action-gift.pagebuilder-button-link:focus {
  background: none;
  border: 0;
}

a.pagebuilder-button-link.disabled,
button.pagebuilder-button-link.disabled,
div.pagebuilder-button-link.disabled,
a.pagebuilder-button-link[disabled],
button.pagebuilder-button-link[disabled],
div.pagebuilder-button-link[disabled],
fieldset[disabled] a.pagebuilder-button-link,
fieldset[disabled] button.pagebuilder-button-link,
fieldset[disabled] div.pagebuilder-button-link,
.cart.table-wrapper .actions-toolbar > .action.pagebuilder-button-link.disabled,
.cart.table-wrapper .actions-toolbar > .action.pagebuilder-button-link[disabled],
fieldset[disabled] .cart.table-wrapper .actions-toolbar > .action.pagebuilder-button-link,
.action-gift.pagebuilder-button-link.disabled,
.action-gift.pagebuilder-button-link[disabled],
fieldset[disabled] .action-gift.pagebuilder-button-link {
  color: #1979c3;
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
  text-decoration: underline;
}

a.pagebuilder-button-primary,
button.pagebuilder-button-primary,
div.pagebuilder-button-primary,
.cart.table-wrapper .actions-toolbar > .action.pagebuilder-button-primary,
.action-gift.pagebuilder-button-primary {
  @include fontSize(16px);
  text-decoration: none;
  background-image: none;
  background: get-color(ui-dark, base);
  border: 1px solid get-color(ui-dark, base);
  box-shadow: 0 1px 2px 0 rgba(33, 43, 54, .1);
  color: get-color(ui-white);
  font-weight: $font-bold;
  cursor: pointer;
  display: inline-block;
  margin: 0 10px 10px 0;
  padding: 9px 32px;
  box-sizing: border-box;
  vertical-align: middle;
  line-height: 22px;
  border-radius: 2px;
}

a.pagebuilder-button-primary:hover,
button.pagebuilder-button-primary:hover,
div.pagebuilder-button-primary:hover,
a.pagebuilder-button-primary:active,
button.pagebuilder-button-primary:active,
div.pagebuilder-button-primary:active,
a.pagebuilder-button-primary:focus,
button.pagebuilder-button-primary:focus,
div.pagebuilder-button-primary:focus,
.cart.table-wrapper .actions-toolbar > .action.pagebuilder-button-primary:hover,
.cart.table-wrapper .actions-toolbar > .action.pagebuilder-button-primary:active,
.cart.table-wrapper .actions-toolbar > .action.pagebuilder-button-primary:focus,
.action-gift.pagebuilder-button-primary:hover,
.action-gift.pagebuilder-button-primary:active,
.action-gift.pagebuilder-button-primary:focus {
  text-decoration: none;
}

a.pagebuilder-button-primary:focus,
button.pagebuilder-button-primary:focus,
div.pagebuilder-button-primary:focus,
a.pagebuilder-button-primary:active,
button.pagebuilder-button-primary:active,
div.pagebuilder-button-primary:active,
.cart.table-wrapper .actions-toolbar > .action.pagebuilder-button-primary:focus,
.cart.table-wrapper .actions-toolbar > .action.pagebuilder-button-primary:active,
.action-gift.pagebuilder-button-primary:focus,
.action-gift.pagebuilder-button-primary:active {
  color: get-color(ui-white);
  background-color: get-color(ui-dark, dark-90);
}

a.pagebuilder-button-primary:hover,
button.pagebuilder-button-primary:hover,
div.pagebuilder-button-primary:hover,
.cart.table-wrapper .actions-toolbar > .action.pagebuilder-button-primary:hover,
.action-gift.pagebuilder-button-primary:hover {
  color: get-color(ui-white);
  background-color: get-color(ui-dark, dark-90);
}

a.pagebuilder-button-primary.disabled,
button.pagebuilder-button-primary.disabled,
div.pagebuilder-button-primary.disabled,
a.pagebuilder-button-primary[disabled],
button.pagebuilder-button-primary[disabled],
div.pagebuilder-button-primary[disabled],
fieldset[disabled] a.pagebuilder-button-primary,
fieldset[disabled] button.pagebuilder-button-primary,
fieldset[disabled] div.pagebuilder-button-primary,
.cart.table-wrapper .actions-toolbar > .action.pagebuilder-button-primary.disabled,
.cart.table-wrapper .actions-toolbar > .action.pagebuilder-button-primary[disabled],
fieldset[disabled] .cart.table-wrapper .actions-toolbar > .action.pagebuilder-button-primary,
.action-gift.pagebuilder-button-primary.disabled,
.action-gift.pagebuilder-button-primary[disabled],
fieldset[disabled] .action-gift.pagebuilder-button-primary {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}

a.pagebuilder-button-secondary,
button.pagebuilder-button-secondary,
div.pagebuilder-button-secondary,
.cart.table-wrapper .actions-toolbar > .action.pagebuilder-button-secondary,
.action-gift.pagebuilder-button-secondary {
  text-decoration: none;
  background-image: none;
  background: #f2f2f2;
  border: 1px solid #cdcdcd;
  color: #333333;
  cursor: pointer;
  display: inline-block;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  margin: 0 10px 10px 0;
  padding: 7px 15px;
  font-size: 1.4rem;
  line-height: 1.6rem;
  box-sizing: border-box;
  vertical-align: middle;
  line-height: 2.2rem;
  padding: 14px 17px;
  font-size: 1.8rem;
  border-radius: 3px;
  box-shadow: none;
}

a.pagebuilder-button-secondary:hover,
button.pagebuilder-button-secondary:hover,
div.pagebuilder-button-secondary:hover,
a.pagebuilder-button-secondary:active,
button.pagebuilder-button-secondary:active,
div.pagebuilder-button-secondary:active,
a.pagebuilder-button-secondary:focus,
button.pagebuilder-button-secondary:focus,
div.pagebuilder-button-secondary:focus,
.cart.table-wrapper .actions-toolbar > .action.pagebuilder-button-secondary:hover,
.cart.table-wrapper .actions-toolbar > .action.pagebuilder-button-secondary:active,
.cart.table-wrapper .actions-toolbar > .action.pagebuilder-button-secondary:focus,
.action-gift.pagebuilder-button-secondary:hover,
.action-gift.pagebuilder-button-secondary:active,
.action-gift.pagebuilder-button-secondary:focus {
  text-decoration: none;
}

a.pagebuilder-button-secondary:focus,
button.pagebuilder-button-secondary:focus,
div.pagebuilder-button-secondary:focus,
a.pagebuilder-button-secondary:active,
button.pagebuilder-button-secondary:active,
div.pagebuilder-button-secondary:active,
.cart.table-wrapper .actions-toolbar > .action.pagebuilder-button-secondary:focus,
.cart.table-wrapper .actions-toolbar > .action.pagebuilder-button-secondary:active,
.action-gift.pagebuilder-button-secondary:focus,
.action-gift.pagebuilder-button-secondary:active {
  background: #e2e2e2;
  border: 1px solid #cdcdcd;
  color: #333333;
}

a.pagebuilder-button-secondary:hover,
button.pagebuilder-button-secondary:hover,
div.pagebuilder-button-secondary:hover,
.cart.table-wrapper .actions-toolbar > .action.pagebuilder-button-secondary:hover,
.action-gift.pagebuilder-button-secondary:hover {
  background: #e2e2e2;
  border: 1px solid #cdcdcd;
  color: #555555;
}

a.pagebuilder-button-secondary.disabled,
button.pagebuilder-button-secondary.disabled,
div.pagebuilder-button-secondary.disabled,
a.pagebuilder-button-secondary[disabled],
button.pagebuilder-button-secondary[disabled],
div.pagebuilder-button-secondary[disabled],
fieldset[disabled] a.pagebuilder-button-secondary,
fieldset[disabled] button.pagebuilder-button-secondary,
fieldset[disabled] div.pagebuilder-button-secondary,
.cart.table-wrapper .actions-toolbar > .action.pagebuilder-button-secondary.disabled,
.cart.table-wrapper .actions-toolbar > .action.pagebuilder-button-secondary[disabled],
fieldset[disabled] .cart.table-wrapper .actions-toolbar > .action.pagebuilder-button-secondary,
.action-gift.pagebuilder-button-secondary.disabled,
.action-gift.pagebuilder-button-secondary[disabled],
fieldset[disabled] .action-gift.pagebuilder-button-secondary {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}

.pagebuilder-column {
  box-sizing: border-box;
}

[data-content-type='heading'] {
  word-wrap: break-word;
}

[data-content-type='html'] {
  word-wrap: break-word;
}

figure[data-content-type='image'] {
  box-sizing: border-box;
}

figure[data-content-type='image'] > [data-element='link'],
figure[data-content-type='image'] > [data-element='link'] img {
  border-radius: inherit;
}

figure[data-content-type='image'] figcaption {
  word-wrap: break-word;
}

[data-content-type='map'] {
  box-sizing: border-box;
  height: 300px;
}

[data-content-type='row'] li.product-item {
  text-align: left;
}

.pagebuilder-column .widget-product-grid .product-item {
  max-width: 100%;
  min-width: 50px;
}

.pagebuilder-column .widget-product-grid .product-item .price-box {
  word-wrap: break-word;
}

.pagebuilder-column .widget-product-grid .product-item .price-box .price {
  white-space: normal;
}

.pagebuilder-column .widget-product-grid .product-item .product-item-actions .actions-primary {
  display: inline-block;
  max-width: 100%;
}

.pagebuilder-column .widget-product-grid .product-item .product-item-actions .actions-secondary {
  display: inline-block;
  width: auto;
}

.pagebuilder-column .widget-product-grid .product-item .product-item-actions .tocart {
  max-width: 100%;
  white-space: normal;
}

.pagebuilder-column [data-content-type='products'] .block.widget .products-grid .widget-product-grid.product-items .product-item {
  width: 200px;
}

[data-content-type='products'][data-appearance='carousel'] .slick-initialized li.product-item {
  display: block;
}

[data-content-type='products'][data-appearance='carousel'] .slick-initialized li.product-item:first-child {
  visibility: visible;
}

[data-content-type='products'][data-appearance='carousel'] .product-item-info {
  width: auto;
}

[data-content-type='products'][data-appearance='carousel'] li.product-item {
  display: none;
}

[data-content-type='products'][data-appearance='carousel'] li.product-item:first-child {
  display: block;
  visibility: hidden;
}

[data-content-type='products'][data-appearance='carousel'].center-mode .product-item {
  opacity: .5;
  transition: all 300ms ease;
}

[data-content-type='products'][data-appearance='carousel'].center-mode .product-item:hover {
  opacity: 1;
}

[data-content-type='products'][data-appearance='carousel'].center-mode .slick-current .product-item {
  opacity: 1;
}

[data-content-type='row'][data-appearance='contained'] {
  box-sizing: border-box;
  margin-left: auto !important;
  margin-right: auto !important;
  max-width: 1280px;
}

[data-content-type='row'][data-appearance='contained'] [data-element='inner'] {
  box-sizing: border-box;
}

[data-content-type='row'][data-appearance='full-bleed'] {
  box-sizing: border-box;
}

[data-content-type='row'][data-appearance='full-width'] {
  box-sizing: border-box;
}

[data-content-type='row'][data-appearance='full-width'] > .row-full-width-inner {
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  width: 100%;
}

[data-content-type='slide'] {
  box-sizing: border-box;
  line-height: 20px;
  min-height: inherit;
  overflow: hidden;
}

[data-content-type='slide'] > [data-element='link'],
[data-content-type='slide'] > [data-element='empty_link'] {
  color: inherit;
  min-height: inherit;
  text-decoration: inherit;
}

[data-content-type='slide'] > [data-element='link']:hover,
[data-content-type='slide'] > [data-element='empty_link']:hover {
  color: inherit;
  text-decoration: inherit;
}

[data-content-type='slide'] + [data-content-type='slide'] {
  height: 0;
  min-height: 0;
}

.slick-slider [data-content-type='slide'] + [data-content-type='slide'] {
  height: initial;
  min-height: inherit;
}

.pagebuilder-slide-wrapper {
  border-radius: inherit;
  box-sizing: border-box;
  min-height: inherit;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.pagebuilder-slide-wrapper .jarallax-viewport-element {
  height: 100%;
  left: -15000vw;
  position: absolute;
  top: 0;
  width: 1px;
  z-index: 100;
}

.slick-current .pagebuilder-slide-wrapper .jarallax-viewport-element {
  left: 0;
}

.pagebuilder-slide-wrapper.jarallax .video-overlay {
  -webkit-transform: unset;
  z-index: 1;
}

.pagebuilder-slide-wrapper.jarallax .pagebuilder-overlay {
  position: relative;
  z-index: 2;
}

.pagebuilder-slide-wrapper.jarallax [id*='jarallax-container'] > div,
.pagebuilder-slide-wrapper.jarallax [id*='jarallax-container'] > img,
.pagebuilder-slide-wrapper.jarallax [id*='jarallax-container'] > video,
.pagebuilder-slide-wrapper.jarallax [id*='jarallax-container'] > iframe {
  margin: auto !important;
  transform: none !important;
}

.pagebuilder-slide-wrapper .pagebuilder-overlay {
  -moz-transition: background-color 500ms ease;
  -o-transition: background-color 500ms ease;
  -webkit-transition: background-color 500ms ease;
  border-radius: inherit;
  box-sizing: border-box;
  padding: 30px;
  transition: background-color 500ms ease;
}

.pagebuilder-slide-wrapper .pagebuilder-overlay.pagebuilder-poster-overlay {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: inherit;
}

.pagebuilder-slide-wrapper .pagebuilder-overlay:not(.pagebuilder-poster-overlay) {
  max-width: 540px;
}

.pagebuilder-slide-wrapper [data-element='content'] {
  min-height: 50px;
  overflow: auto;
}

.pagebuilder-slide-wrapper .pagebuilder-slide-button {
  -moz-transition: opacity 500ms ease;
  -o-transition: opacity 500ms ease;
  -webkit-transition: opacity 500ms ease;
  margin: 20px 0 0 0;
  max-width: 100%;
  text-align: inherit;
  transition: opacity 500ms ease;
  word-break: break-word;
}

.pagebuilder-slide-wrapper .pagebuilder-poster-content {
  width: 100%;
}

[data-appearance='collage-centered'] .pagebuilder-slide-wrapper .pagebuilder-overlay {
  margin-left: auto;
  margin-right: auto;
}

[data-appearance='collage-left'] .pagebuilder-slide-wrapper .pagebuilder-overlay {
  margin-right: auto;
}

[data-appearance='collage-right'] .pagebuilder-slide-wrapper .pagebuilder-overlay {
  margin-left: auto;
}

div[data-content-type='slider'] {
  visibility: hidden;
}

div[data-content-type='slider'].slick-initialized {
  visibility: visible;
}

div[data-content-type='slider'] .slick-list,
div[data-content-type='slider'] .slick-track,
div[data-content-type='slider'] .slick-slide {
  min-height: inherit;
}

div[data-content-type='slider'] .slick-list > div,
div[data-content-type='slider'] .slick-track > div,
div[data-content-type='slider'] .slick-slide > div {
  line-height: 0;
  min-height: inherit;
  overflow: hidden;
  width: 100%;
}

a.button {
  -moz-appearance: button;
  -webkit-appearance: button;
  appearance: button;
  color: initial;
  padding: 10px;
  text-decoration: none;
}

div[data-content-type='tabs'] .tabs-navigation {
  display: block;
  font-size: 0;
  margin: 0 0 -1px;
  padding: 0;
}

div[data-content-type='tabs'] .tabs-navigation li:first-child {
  margin-left: 0 !important;
}

div[data-content-type='tabs'] .tabs-navigation li.tab-header {
  background: #f6f6f6;
  border: 1px solid #cccccc;
  border-bottom: 0;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  display: inline-block;
  margin: 0;
  max-width: 100%;
  overflow-wrap: break-word;
  position: relative;
  word-wrap: break-word;
  z-index: 1;
}

div[data-content-type='tabs'] .tabs-navigation li.tab-header:not(:first-child) {
  margin-left: -1px;
}

div[data-content-type='tabs'] .tabs-navigation li.tab-header a.tab-title {
  border-right: 0;
  color: #000000;
  cursor: pointer !important;
  display: block;
  font-size: 14px;
  font-weight: 600;
  padding: 1.4rem 2rem;
  position: relative;
  transition: all .3s;
  vertical-align: middle;
  white-space: normal;
}

div[data-content-type='tabs'] .tabs-navigation li.tab-header a.tab-title span.tab-title {
  display: block;
}

div[data-content-type='tabs'] .tabs-navigation li.tab-header a.tab-title:hover {
  text-decoration: none;
}

div[data-content-type='tabs'] .tabs-navigation li.tab-header:last-child {
  border-right: 1px solid #cccccc;
}

div[data-content-type='tabs'] .tabs-navigation li.tab-header.ui-state-active {
  background: #ffffff;
  z-index: 19;
}

div[data-content-type='tabs'] .tabs-navigation li.tab-header.ui-state-active a.tab-title {
  position: relative;
  transition: all .3s;
}

div[data-content-type='tabs'] .tabs-content {
  border: 1px solid #cccccc;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  z-index: 9;
}

div[data-content-type='tabs'] .tabs-content [data-content-type='tab-item'] {
  box-sizing: border-box;
  min-height: inherit;
}

div[data-content-type='tabs'] .tabs-content [data-content-type='tab-item']:not(:first-child) {
  display: none;
}

div[data-content-type='tabs'].tab-align-left .tabs-content {
  border-top-left-radius: 0 !important;
}

div[data-content-type='tabs'].tab-align-right .tabs-content {
  border-top-right-radius: 0 !important;
}

div[data-content-type='text'] {
  word-wrap: break-word;
}

div[data-content-type='video'] {
  font-size: 0;
}

div[data-content-type='video'] .pagebuilder-video-inner {
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
}

div[data-content-type='video'] .pagebuilder-video-container {
  border-radius: inherit;
  overflow: hidden;
  position: relative;
}

div[data-content-type='video'] iframe,
div[data-content-type='video'] video {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.jarallax {
  position: relative;
  z-index: 0;
}

.jarallax > .jarallax-img {
  font-family: 'object-fit: cover;', sans-serif;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.jarallax-video-fallback {
  transform: scale3d(0, 0, 0);
}

.jarallax .video-overlay {
  -webkit-transform: translate3d(0, 0, 0);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -99;
}

.jarallax [id*='jarallax-container'] video,
.jarallax [id*='jarallax-container'] iframe {
  visibility: hidden;
}

.cms-content-important {
  background-color: #f5f5f5;
  color: #333333;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.1;
  margin: 0 0 35px -20px;
  padding: 20px;
}

.pagebuilder-full-width {
  float: left;
  width: 100%;
}

.pagebuilder-content-type {
  box-sizing: border-box;
  margin-bottom: 20px;
}

.pagebuilder-accordion {
  margin: 0;
  padding: 0;
}

.pagebuilder-accordion > .item.title {
  box-sizing: border-box;
  float: none;
  width: 100%;
}

.pagebuilder-accordion > .item.title > .switch {
  display: block;
}

.pagebuilder-accordion > .item.content {
  box-sizing: border-box;
  display: block;
  float: none;
  margin: 0;
}

.pagebuilder-accordion > .item.content:before,
.pagebuilder-accordion > .item.content:after {
  content: '';
  display: table;
}

.pagebuilder-accordion > .item.content:after {
  clear: both;
}

.pagebuilder-accordion > .item.content.active {
  display: block;
}

.pagebuilder-accordion > .item.title {
  margin: 0 0 5px;
}

.pagebuilder-accordion > .item.title > .switch {
  background: #f0f0f0;
  border-bottom: 1px solid #d1d1d1;
  border-left: 1px solid #d1d1d1;
  border-right: 1px solid #d1d1d1;
  border-top: 1px solid #d1d1d1;
  height: 40px;
  padding: 5px 20px 5px 20px;
  font-weight: 600;
  line-height: 40px;
  font-size: 1.8rem;
  color: #7d7d7d;
  text-decoration: none;
}

.pagebuilder-accordion > .item.title > .switch:visited {
  color: #7d7d7d;
  text-decoration: none;
}

.pagebuilder-accordion > .item.title > .switch:hover {
  color: #7d7d7d;
  text-decoration: none;
}

.pagebuilder-accordion > .item.title > .switch:active {
  color: #333333;
  text-decoration: none;
}

.pagebuilder-accordion > .item.title:not(.disabled) > .switch:focus,
.pagebuilder-accordion > .item.title:not(.disabled) > .switch:hover {
  background: #fcfcfc;
}

.pagebuilder-accordion > .item.title:not(.disabled) > .switch:active,
.pagebuilder-accordion > .item.title.active > .switch,
.pagebuilder-accordion > .item.title.active > .switch:focus,
.pagebuilder-accordion > .item.title.active > .switch:hover {
  background: #ffffff;
  color: #333333;
  text-decoration: none;
  padding-bottom: 5px;
}

.pagebuilder-accordion > .item.content {
  background: #ffffff;
  border: 1px solid #d1d1d1;
  margin: 0 0 5px;
  padding: 20px 20px 20px 20px;
}

// Overrides and extended styles
.pagebuilder {

  &-mobile-hidden {
    display: none;
    @include breakpoint($desktop) {
      display: block;
    }
  }

  &-mobile-only {
    @include breakpoint($desktop) {
      display: none;
    }
  }

  &-video-inner {
    margin: 32px auto 0;
  }

  &-video-container {
    position: relative;
    padding-bottom: calc(var(--aspect-ratio, .5625) * 100%);

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

}

div[data-content-type="text"] {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 16px;
  }
}

div[data-background-type="image"] {
  margin: 0 !important;
  padding: 0 !important;
}

div[data-appearance="contained"] {
  width: 100%;
  max-width: 100%;
  padding: 0;
  @include breakpoint($desktop-mid) {
    max-width: var(--grid-max);
    margin: 0 auto;
    padding: 0;
  }
}

.text-content {
  &[data-background-type=image] {
    max-width: 868px;
    padding: 0 24px !important;
    margin: 0 auto 44px !important;
    @include breakpoint($desktop-mid) {
      margin: 0 auto 80px !important;
    }
    color: get-color(ui-dark, dark-80);

    h1 {
      @include thirdHeader;
      margin: 0 0 24px;
    }

    p {
      color: get-color(ui-dark, dark-80) !important;
    }
  }
}

.image-content {
  &[data-background-type=image] {
    padding: 0 0 24px !important;
    margin: 0 0 44px !important;
    @include breakpoint($desktop-mid) {
      margin: 0 0 80px !important;
    }
  }
}

.pagebuilder-column-group {
  @media screen and (max-width: 480px) {
    flex-wrap: wrap;
  }

  .pagebuilder-column, .pagebuilder-column-line {
      flex-wrap: wrap;
    @media screen and (max-width: 480px) {
      flex-wrap: wrap;
      width: 100% !important;
      flex: 100% !important;
    }
  }
}

// Image text block
.split-content {
  &[data-background-type=image] {
    margin: 0 0 24px !important;
    position: relative;
    padding: 0 24px !important;
    @include breakpoint($desktop-mid) {
      padding: 0 !important;
      margin: 0 0 120px !important;
    }

    .content-left,
    .content-right {
      background: get-color(ui-dark, dark-10);
      padding: 24px !important;
      color: get-color(ui-dark, dark-80) !important;
      @include breakpoint($desktop) {
        width: 525px !important;
        padding: 48px !important;
      }

      p {
        color: get-color(ui-dark, dark-80) !important;
      }

      .logo {
        img {
          height: 38px !important;
          width: auto !important;
          margin: 0 0 16px;
        }
      }

      div[data-content-type="button-item"] {
        display: block !important;
        @include breakpoint($desktop) {
          display: inline-block !important;
        }
      }

      div[data-content-type="buttons"] {
        margin: 16px 0 0 !important;
      }
    }

    .content-left {
      @include breakpoint($tablet) {
        margin: 0 -32px 0 24px !important;
      }
      @include breakpoint($desktop) {
        margin: 0 -32px 0 0 !important;
      }
    }

    .content-right {
      @include breakpoint($tablet) {
        margin: 0 24px 0 -32px !important;
      }
      @include breakpoint($desktop) {
        margin: 0 0 0 -32px !important;
      }
    }

    .image-wrapper:nth-child(even) {
      position: relative;
      z-index: -1;
      bottom: 24px;
      order: 2;
      right: -24px;
      @include breakpoint($desktop) {
        order: unset;
        width: 718px !important;
        bottom: -32px;
        right: 0;
      }
    }
    .image-wrapper:nth-child(odd) {
      position: relative;
      z-index: -1;
      bottom: 24px;
      order: 2;
      left: -24px !important;
      right: 0;
      @include breakpoint($desktop) {
        order: unset;
        width: 718px !important;
        bottom: -32px;
        left: 0 !important;
      }
    }
  }
}

.category-slider,
.brands-best-sellers {
  div[data-content-type="html"] {
    margin: 0 0 80px !important;
  }
}

.split-images {
  .pagebuilder-column-group {
    margin: 0 0 80px !important;
  }

  .pagebuilder-column:first-child {
    padding: 0 24px 16px !important;
    @include breakpoint($tablet) {
      margin: 0 12px 0 0 !important;
      padding: 0 !important;
    }
    @include breakpoint($desktop) {
      margin: 0 16px 0 0 !important;
    }
  }

  .pagebuilder-column:last-child {
    padding: 0 24px !important;
    @include breakpoint($tablet) {
      margin: 0 0 0 12px !important;
      padding: 0 !important;
    }
    @include breakpoint($desktop) {
      margin: 0 0 0 16px !important;
    }
  }
}
// sass-lint:enable-all
