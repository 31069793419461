.data.items {
  width: 100%;
  border-bottom: solid 1px get-color(ui-dark, dark-20);

  .tab-wrapper {
    border-top: solid 1px get-color(ui-dark, dark-20);
    display: flex;
    flex-wrap: wrap;

    .title {
      outline: none;
      padding: 25px 0;
      position: relative;
      flex-basis: 100%;

      &::after {
        @include forthHeader;
        content: "+";
        position: absolute;
        right: 0;
      }

      &:hover {
        cursor: pointer;
        opacity: .8;
      }

      &.active {
        background-color: $white;
        flex-basis: 50%;

        &::after {
          display: none;
        }

      }

      a {
        @include forthHeader;
        outline: none;

        &:active,
        &:hover,
        &:focus,
        &:visited {
          color: get-color(ui-dark, base);
          text-decoration: none;
        }
      }
    }

    .content {
      @include paragraph;
      padding: 25px 0 8px;
      flex-basis: 50%;
      color: get-color(ui-dark, dark-80);
    }

  }

}
