.form-create-account {
  max-width: 868px;
  border: solid 1px get-color(ui-dark, dark-20);
  padding: 32px;
  margin: 0 0 32px;

  fieldset {
    margin: 0;
    padding: 0;
    border: 0;

    &.account {
      .legend span {
        @include fontSize(24px);
        padding: 47px 0 0;
        display: block;
        margin: 0;
      }
    }

    .field {
      @include fontSize(14px);
      line-height: 22px;
      color: $headingText;

      &.password {
        margin-bottom: 16px;
      }
    }
  }

  legend span {
    @include fontSize(24px);
    font-weight: $font-bold;
    line-height: 32px;
    color: $headingText;
    margin-bottom: 32px;
    @include breakpoint($desktop) {
      @include fontSize(38px);
      line-height: 40px;
    }
  }

  label {
    display: none;
  }

  .name-container,
  .password-container {
    @include breakpoint($desktop) {
      display: flex;
      justify-content: space-between;

      .field {
        flex: 1;

        &-name-firstname,
        &.password {
          padding-right: 5px;
        }

        &-name-lastname,
        &.confirmation {
          padding-left: 5px;
        }
      }
    }
  }

  .actions-toolbar {
    > div {
      @include breakpoint($desktop) {
        display: inline-block;
      }
    }
    .secondary .action {
      @extend %secondary-button;
      display: inline-block;
      text-align: center;
    }
  }
  
  .newsletter {
    padding: inherit;
    background: none;
  }

  .actions-toolbar {
    margin: 32px 0 0;
  }

}

.customer-account-createpassword {
  .password-strength-meter {
    margin: 8px 0;
  }
}

.password-strength-meter {
  @include fontSize(12px);
  font-style: italic;
  color: get-color(ui-dark, dark-80);
  border: solid 1px get-color(ui-dark, dark-20);
  padding: 6px;
  margin: 0 0 8px;
  width: fit-content;
  float: right;
  line-height: 1;
  position: relative;
}

.password-strength-meter::before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  z-index: -1;
}

.password-weak .password-strength-meter::before {
  background-color: #ffafae;
  width: 25%;
}

.password-medium .password-strength-meter::before {
  background-color: #ffd6b3;
  width: 50%;
}

.password-strong .password-strength-meter::before {
  background-color: #c5eeac;
  width: 75%;
}

.password-very-strong .password-strength-meter::before {
  background-color: #81b562;
  width: 100%;
}
