.block-customer-login {
  border: solid 1px get-color(ui-dark, dark-20);
  margin: 0 0 24px;
  @include breakpoint($tablet) {
    margin: 0;
  }
  @include breakpoint($desktop) {
    margin: 0 16px 0 0;
  }

  fieldset {
    margin: 0;
    padding: 0;
    border: 0;
  }

  label,
  .label {
    display: none;
  }

  .block-title {
    @include fontSize(24px);
    font-weight: $font-bold;
    line-height: 32px;
    color: $headingText;
    margin-bottom: 32px;
    @include breakpoint($desktop-mid) {
      @include fontSize(32px);
      line-height: 40px;
    }
  }

  .field.note {
    @include fontSize(14px);
    line-height: 22px;
    color: $headingText;
    margin: 0 0 32px;
  }

  .actions-toolbar {
    > div {
      @include breakpoint($desktop) {
        display: inline-block;
      }

      &.secondary .action {
        @extend %secondary-button;
        text-align: center;
        display: inline-block;
        margin: 16px 0 0;
        @include breakpoint($desktop) {
          margin: 0 0 0 16px;
        }
      }
    }
  }
}
