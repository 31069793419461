.block-orders-returns {
  max-width: 568px;
  border-radius: 4px;
  padding: 32px;
  border: solid 1px get-color(ui-dark, dark-20);
  margin: 0 0 24px;
  .block-title {
    @include fontSize(24px);
    font-weight: $font-bold;
    line-height: 32px;
    color: $headingText;
    margin-bottom: 32px;
    @include breakpoint($desktop-mid) {
      @include fontSize(32px);
      line-height: 40px;
    }
  }
  fieldset {
    padding: 0;
    margin: 0;
    border: 0;
  }
  .field.find.required {
    display: none;
  }
  .returns-intro {
    @include fontSize(14px);
    margin: 0 0 32px;
  }
}

.sales-guest-view {
  .page-title-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  h1 {
    display: none;
  }
  h3 {
    margin: 0 0 64px;
  }
  .order-date {
    display: none;
  }
  .action.order.btn.primary {
    display: none;
  }
  .order-links {
    display: none;
  }
  .order-totals {
    @include breakpoint($desktop) {
      float: right;
      width: 50%;
      border-top: 0;
    }
    td.mark {
      display: none;
      @include breakpoint($desktop) {
        display: table-cell;
      }
    }
    td.amount {
      display: flex;
      justify-content: space-between;
      @include breakpoint($desktop) {
        display: table-cell;
        text-align: right;
      }
    }
  }
  .block-order-details-view {
    clear: both;
    padding: 24px 0 0;
    .block-content {
      margin: 24px 0 0;
      border-top: solid 1px get-color(ui-dark, dark-20);
    }
  }
}

.magento_rma-guest-create {
  .order-links,
  .block-order-details-view,
  .field.contact.email {
    display: none;
  }
  .form-create-return {
    max-width: 868px;
    border-radius: 4px;
    padding: 32px;
    border: solid 1px get-color(ui-dark, dark-20);
    margin: 0 0 24px;
    .legend {
      @include fontSize(24px);
      font-weight: $font-bold;
      line-height: 32px;
      color: $headingText;
      margin-bottom: 32px;
      @include breakpoint($desktop-mid) {
        @include fontSize(32px);
        line-height: 40px;
      }
    }
  }
  fieldset {
    padding: 0;
    margin: 0;
    border: 0;
  }
  .return-toolbar {
    background: get-color(ui-dark, dark-10);
    padding: 32px;
    .legend {
      display: none;
    }
    .fields.additional {
      .fieldset {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .field.item {
        @include breakpoint($desktop-mid) {
          flex: calc(80% - 16px);
          margin: 0 16px 16px 0;
        }
      }
      .field.qty {
        flex: 20%;
        .note {
          @include fontSize(12px);
        }
      }
      .field.resolution0 {
        flex: 100%;
      }
      .field.condition0,
      .field.reason0 {
        @include breakpoint($desktop-mid) {
          flex: calc(50% - 16px);
        }
      }
      .field.condition0 {
        @include breakpoint($desktop-mid) {
          margin: 0 8px 0 0;
        }
      }
      .field.reason0 {
        @include breakpoint($desktop-mid) {
          margin: 0 0 0 8px;
        }
      }
    }
    .actions-toolbar {
      margin: 16px 0 0;
    }
  }
  .actions-toolbar {
    margin: 16px 0 0;
  }
}

.returns-header {
  margin: 0 0 32px;
  @include breakpoint($desktop-mid) {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  &__item {
    @include breakpoint($desktop-mid) {
      flex: 50%;
    }
  }
  h6 {
    margin: 0 0 16px;
  }
}
