.usp-slick-container {
  .usp {
    align-items: center;
    color: get-color(ui-white);
    @include breakpoint($desktop-mid) {
      color: get-color(ui-dark, base);
    }
    .euronics {
      img {
        width: 104px;
      }
    }
    i {
      @include fontSize(16px);
      color: get-color(ui-red, base);
      margin-right: 8px;
    }
    &.trustpilot {
      padding: 20px 0;
    }
  }
}

.usp-slick-container:not(.slick-initialized) {
  display: flex;
  justify-content: space-evenly;
  .usp {
    display: none;
    padding: 1rem;
    @include breakpoint($desktop-mid) {
      display: flex;
    }
    &:first-child {
      display: flex;
    }
  }
  .hide {
    display: none;
  }
}

.usp-slick-container.slick-initialized {

  .slick-track {
    display: flex;
    align-items: center;
    height: 60px;
    @include breakpoint($desktop-mid) {
      height: 70px;
    }
    .slick-slide {
      display: flex !important;
      justify-content: center;
      align-items: center;
    }
  }
  .usp {
    display: flex !important;
  }
}

.usps-container {
  padding: 8px 16px;
  background: get-color(ui-dark, dark-90);
  margin: 0 0 16px;
  @include breakpoint($desktop-mid) {
    background: get-color(ui-white);
    padding: 0;
    border-bottom: solid 1px get-color(ui-dark, dark-20);
  }
}

.cms-home {
  .usps-container {
    margin: 0;
    @include breakpoint($desktop-mid) {
      margin: 32px 0 0;
    }
    border-bottom: 0;
  }
}
