.amrates-shipping-rates {

  .items.methods {
    display: block;
  }

  .item-title {
    display: none;
  }

  .item-options {
    display: block;
    border: 1px solid get-color(ui-dark, dark-20);
    border-radius: 2px;

    .field.choice.item {
      display: flex;
      flex-wrap: nowrap;
      padding: 0.5rem;
      margin-bottom: 0;
      border-bottom: 1px solid get-color(ui-dark, dark-20);

      &:last-of-type {
        border-bottom: none;
      }

      .label {
        flex-grow: 1;
        display: flex;
        justify-content: space-between;

        &::before {
          top: 7px;
          left: 7px;
        }
      }

      .radio {

        &:checked {
          z-index: 2;

          &+.label {
            z-index: 1;

            &::after {
              content: "";
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              background-color: get-color(ui-dark, dark-10);
              border-radius: 3px;
              z-index: -1;
            }
          }
        }
      }
    }
  }
}