.product-options-wrapper {
  margin: 0 0 32px;
}

.swatch-attribute-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 8px 0 0;
}

.swatch-wrapper {
  flex: 49%;
  border: solid 1px $border;
  border-radius: 2px;
  padding: 12px;
  margin-bottom: 8px;
  &:nth-child(odd) {
    margin-right: 4px;
  }
  &:nth-child(even) {
    margin-left: 4px;
  }
}

.swatch-attribute-label {
  @include fontSize(14px);
  padding: 0 8px 8px 0;
  font-weight: $font-bold;
  &::before {
    content: 'Choose ';
  }
}

.swatch-attribute-selected-option {
  @include fontSize(14px);
}

.swatch-attribute.color .swatch-wrapper {
  height: 100%;
  width: auto;
  border-radius: 50%;
  border: 1px solid $border;
  box-shadow: 0 1px 2px 0 $border;

  &:hover {
    border-color: $text;
  }
}

.swatch-option {
  &.color {
    height: 20px;
    width: 20px;
    border: 1px solid $border;
    border-radius: 50%;

    &:hover,
    &.selected {
      border: 1px solid get-color(ui-dark, dark-70);
    }
  }
  &.text {
    @include fontSize(12px);
    background: $white;
    border: 1px solid $fieldBorder;
    height: auto;
    width: auto;
    border-radius: 3px;
    box-shadow: 0 1px 2px rgba(33, 43, 54, .1);
    color: $textLight;
    text-transform: uppercase;
    font-weight: $font-bold;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    box-sizing: border-box;
    padding: 2px 6px;

    &:hover {
      border: 1px solid get-color(ui-dark, dark-70);
    }

    &.selected {
      border: 1px solid get-color(ui-dark, dark-70);
    }
  }
  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
}

.swatch-option-link-layered {
  position: relative;
  &:hover {
    position: relative;
    &::after {
      display: block;
    }
  }
  &::after {
    @include fontSize(12px);
    display: none;
    position: absolute;
    top: -1px;
    content: attr(aria-label);
    margin: 0 0 0 25px;
    border: solid 1px $border;
    background: get-color(ui-white);
    border-radius: 2px;
    z-index: 99999;
    padding: 2px 10px;
    text-align: center;
    line-height: 1.3;
  }
}

.swatch-input {
  display: none;
}
