//
// Utility classes
// Must use a u- prefix
// Keep to a minimum
//


// Hide visually, but still let Screen-readers read the content
// Placeholder which can be @extend 'ed where needed.
// Taken from the WebAIM site - https://webaim.org/techniques/css/invisiblecontent/
%visually-hidden,
.u-visuallay-hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

// undo visually-hidden styles
%visually-unhidden {
  position: static;
  width: auto;
  height: auto;
}


// Hide and show at certain breakpoints
// Leaving $tablet commented in case we decide to include a tablet breakpoint
%u-mobile-only,
.u-mobile-only {
  
  @include breakpoint($desktop) {
    display: none;
  }
}

%u-desktop-only,
.u-desktop-only {
  display: none;
  
  @include breakpoint($desktop) {
    // display: inline-block; For IE 11 support if needed
    display: initial;
  }
}

%u-not-desktop,
.u-not-desktop {
  
  @include breakpoint($desktop) {
    display: none;
  }
}


// For shifting stuff to the end of a flex container
.u-shift-right {
  margin-left: auto;
}

.u-shift-left {
  margin-right: auto;
}

.u-shift-bottom {
  margin-top: auto;
}

.show-desktop {
  display: none;
  @include breakpoint($desktop) {
    display: block;
  }
}

.show-mobile {
  display: block;
  @include breakpoint($desktop) {
    display: none;
  }
}
