.main-menu {
  order: 7;
  font-family: sans-serif;
  z-index: 40; // taken from what was needed on NL... likely to go higher on some projects
  background-color: #fff;
  width: 100%;
  position: absolute;
  top: 138px; // height of header
  left: -100%;
  transform: translate3d(0, 0, 0);
  transition: 600ms transform ease;

  @include breakpoint($desktop-mid) {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    width: 100%;
    flex-basis: 100%;
    background: get-color(ui-dark, base);
    transition: height 600ms ease;
    margin: 0;
    padding: 18px 0;
    // sass-lint:disable-all
    height: inherit !important;
    // sass-lint:enable-all
  }

  @at-root .show-nav & {
    // transform: translateX(100%);
    transform: translate3d(100%, 0, 0);
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1100; // taken from what was needed on NL where Amasty Label bumped everything up... might not need to be this high on some projects
  }

  &.desktop-mainmenu-visible {
    height: 48px;
    overflow: visible;
  }

  &.desktop-mainmenu-hidden {
    height: 0; // need to override inline style added by JS... which is also needed for cross breakpoint width changes
    overflow: hidden;
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style: none outside;

    @include breakpoint($desktop-mid) {
      max-width: var(--grid-max);
      margin: 0 auto;
      padding: 0;
      color: get-color(ui-white);
      display: flex;
      flex-flow: row;
      justify-content: center;
    }
  }

  li {

    @include breakpoint(max-width 1199px) {
      box-shadow: inset 0 -1px 0 0 $border;
      flex-basis: 100%;
    }

    @include breakpoint($desktop-mid) {
      flex-grow: 1;
      text-align: center;
    }

    &.main-menu__offers {
      a {
        color: get-color(ui-red, base);
        @include breakpoint($desktop-mid) {
          background: get-color(ui-red, base);
          color: get-color(ui-white);
        }

        &:visited,
        &:focus,
        &:hover,
        &:active {
          background: get-color(ui-red, red-90);
        }
      }
    }

    a {
      @include fontSize(16px);
      line-height: 24px;
      color: $headingText;
      padding: 16px 24px;
      display: block;
      font-weight: $font-bold;
      position: relative;
      outline: none;

      @include breakpoint($desktop-mid) {
        @include fontSize(14px);
        display: block;
        padding: 7.5px 14px;
        border-radius: 2px;
        line-height: inherit;
        color: get-color(ui-white);

        &:visited,
        &:focus,
        &:hover,
        &:active {
          color: get-color(ui-white);
          text-decoration: none;
          background: get-color(ui-dark, dark-80);
        }
      }

      @include breakpoint($desktop-mid) {
        &.main-menu__account {
          display: none;
        }
      }

      &.main-menu__account::after {
        content: "\f2bd";
        font-family: Font Awesome\ 5 Pro;
        font-weight: 900;
        position: absolute;
        right: 24px;
      }

    }

    .main-menu__icon {
      display: block;
      position: absolute;
      right: 20px;
      top: 14px;
      height: 24px;
      width: 24px;
      background: url(../images/icons/icon-chevron-right.svg) 50% 50% no-repeat;
      background-size: 24px;

      @include breakpoint($desktop-mid) {
        display: none;
      }
    }

    .main-menu__icon--back {
      display: block;
      position: absolute;
      left: 18px;
      top: 16px;
      height: 24px;
      width: 24px;
      background: url(../images/icons/icon-chevron-right.svg) 50% 50% no-repeat;
      background-size: 24px;
      transform: rotate(180deg);
      @include breakpoint($desktop-mid) {
        display: none;
      }
    }

    &.main-menu__inner-item--all {
      box-shadow: none;
      background: get-color(ui-dark, base);

      a {
        font-weight: $font-bold;
        color: get-color(ui-white);
      }
    }
  }

  .main-menu__inner-link--back {
    padding-left: 52px;
    background-color: #fff;
    width: 100%;
    font-weight: $font-bold;
    box-shadow: none;
    text-decoration: none;
  }

  &__item--parent,
  &__inner-item--parent {

    a {
      @include breakpoint($desktop-mid) {
        color: $headingText;

        &:visited,
        &:focus,
        &:hover,
        &:active {
          color: $headingText;
        }
      }
    }

    .main-menu__inner-list {
      display: none;
      padding: 0;
      list-style: none outside;
      width: 100%;
      background-color: #fff;

      @at-root .show-nav & {
        display: block;
      }

      @include breakpoint($desktop-mid) {
        a {
          color: get-color(ui-dark, base);
          background: none;

          &:visited,
          &:focus,
          &:active {
            color: get-color(ui-dark, base);
            background: none;
          }

          &:hover {
            color: get-color(ui-dark, dark-90);
            background: none;
          }

        }
      }

    }

    .main-menu__inner-list--level1 {

      @include breakpoint(max-width 1199px) {
        position: absolute;
        top: 0;
        right: 0;
        min-height: 100%;
        transform: translate3d(100%, 0, 0);
        transition: transform 600ms ease;
      }
      @include breakpoint($desktop-mid) {
        max-width: 1200px;
        position: absolute;
        top: 75px;
        left: 50vw;
        margin-left: calc(-50% - ((1200px - 100%) / 2));
        flex-flow: row wrap;
        padding: 48px 64px;
      }
    }

    li.main-menu__inner-item--level1 {
      @include breakpoint($desktop-mid) {
        width: calc(100% / 3);
        text-align: left;
        flex-grow: 0;
      }

      &:first-child {
        box-shadow: none;
      }

      &:last-child {
        @include breakpoint($desktop) {
          margin-right: 0;
        }
      }

      a {
        @include breakpoint($desktop) {
          padding: 0;
        }
      }

      > a {
        @include breakpoint($desktop) {
          @include fontSize(16px);
          line-height: 24px;
          font-weight: $font-bold;
          margin-bottom: 24px;
        }

        span {
          @include breakpoint($desktop) {
            height: 24px;
          }
        }
      }

      &.main-menu__inner-item--all {
        @include breakpoint($desktop) {
          display: none;
        }
      }

      &.main-menu__inner-item--parent {
        > a {
          @include breakpoint($desktop) {
            @include fontSize(16px);
            line-height: 24px;
            font-weight: $font-bold;
            margin-bottom: 24px;
          }
        }

        span {
          @include breakpoint($desktop) {
            height: 24px;
          }
        }

        .main-menu__icon {
          top: 17px;
          background: url(../images/icons/icon-chevron-down-dark.svg) 50% 50% no-repeat;
          background-size: 24px;
          transform: rotate(0deg);
          transition: transform 300ms ease;

          @include breakpoint($desktop) {
            display: none;
          }
        }
      }
    }

    .main-menu__inner-item--cms_block {
      position: relative;
      padding-left: 0;
      @include breakpoint($desktop) {
        align-self: flex-start;
        margin: 0;
      }

      p,
      a {
        // for the p tag that the WYSIWYG wraps around img tags
        margin-bottom: 0;
        line-height: 0;
        padding: 0;
      }

      & + .main-menu__inner-item {
        border-top: 1px solid $border;
        @include breakpoint($desktop) {
          border-top: 0;
        }
      }

    }

    .main-menu__inner-list--level2 {
      box-shadow: inset 0 1px 0 0 $border;
      padding-top: 1px;
      max-height: 400px;
      overflow: hidden;
      transition: max-height 600ms ease;
      @include breakpoint($desktop) {
        max-height: 800px;
        box-shadow: none;
        padding-top: 0;
      }
    }

    .main-menu__inner-item--level2 {
      background-color: $background-light;
      @include breakpoint($desktop) {
        background-color: $white;
        flex-grow: 0;
      }

      &.main-menu__inner-item--all {
        display: none;
      }

      a {
        @include fontSize(14px);
        font-weight: $font-regular;
        @include breakpoint($desktop) {
          line-height: 22px;
          margin-bottom: 12px;
          text-align: left;
        }

        &:hover {
          span {
            box-shadow: inset 0 -2px 0 0 $primary;
          }
        }
      }

      span {
        @include breakpoint($desktop) {
          height: 22px;
        }
      }
    }

    // gets added to mobile menu only
    &.show-nav {
      > .main-menu__inner-list--level1 {
        transform: translate3d(0, 0, 0);
        z-index: 100; // to make sure it is above the parent menu
      }

      &.main-menu__inner-item--level1 {
        > .main-menu__inner-list--level2 {
          max-height: 1000px;
        }

        a .main-menu__icon {
          transform: rotate(-180deg);
          top: 15px;
        }
      }
    }

    // gets added to desktop menu only
    &.item-hovered {
      .main-menu__inner-list {
        display: block;
      }

      .main-menu__inner-list--level1 {
        display: flex;
      }

      > a span {
        color: get-color(ui-white);
        text-decoration: none;
        background: get-color(ui-dark, dark-80);
      }
    }
  }

  &__screen {
    display: none;
    position: absolute;
    top: 144px;
    left: 0;
    width: 100%;
    height: 600px;
    background-color: transparent;
    z-index: 12;
  }

  .main-menu__sub-item--title a {
    font-weight: $font-bold;
  }

}

.menu-underlay {
  background: rgba(35, 31, 32, .4);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
}

.menu-hovered .main-menu__screen {
  @include breakpoint($desktop) {
    display: block;
  }
}

@include breakpoint(max-width 992px) {
  .brands-menu {
    ul {
      li {
        display: inline-block;
        align-items: center;
        box-shadow: none;

        &:first-child,
        &:nth-child(2) {
          display: block;
          flex-basis: 100%;
        }

        .title {
          display: block;
        }

        a {
          padding: 16px 8px;
        }

        .inner-list-header {
          padding: 16px 24px;
        }
      }
    }
  }
}

.brands-menu {
  .main-menu__inner-list--level1 {
    align-items: center;
    @include breakpoint($desktop-mid) {
      padding: 48px 64px 24px;
    }
  }
  li.main-menu__inner-item--cms_block {
    width: 100%;
  }
  &--inner {
    padding: 12px;
    @include breakpoint($desktop-mid) {
      padding: 0;
    }
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    img {
      width: 106px;
      max-height: 41px;
    }

    .main-menu__inner-item--level2 {
      background-color: get-color(ui-white);
      padding: 12px;
      flex-basis: calc(100% / 3);
      @include breakpoint($desktop-mid) {
        flex-basis: calc(100% / 6);
      }
      &:first-child,
      &:nth-child(2) {
        display: block;
        @media screen and (max-width: 39.9375em) {
          flex-basis: unset;
        }
      }
    }

  }
}
