.store-message {
  p {
    margin: 0;
  }
}
.page-header {
  padding: 24px 0;
  @include breakpoint($desktop-mid) {
    padding: 24px 0 0;
  }
  z-index: 40;
  background: get-color(ui-dark, base);
  color: get-color(ui-white);

  .header.content {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-around;
    position: relative;
  }

  .switcher-currency {
    display: none;
  }

  .nav-toggle {
    @include fontSize(19.2px);
    margin: 0 gutter();
    order: 4;
    min-width: 16.81px;
    @include breakpoint($desktop-mid) {
      display: none;
    }
    &.open i::before {
      content: "\f00d";
    }
  }

  .logo {
    margin: 0 gutter();
    @include breakpoint($tablet) {
      flex-basis: 73%;
    }
    @include breakpoint($desktop-mid) {
      flex-basis: 263px;
      order: 1;
      margin: 0;
    }

    .logo {
      width: auto;
      margin: 0;
    }

    img {
      width: 100%;
      height: auto;
      max-width: 184px;
      @include breakpoint($desktop-mid) {
        max-width: 263px;
      }
    }
  }

  .minicart-wrapper {
    order: 3;
    color: get-color(ui-white);
    @include breakpoint($desktop-mid) {
      order: 6;
    }
    .showcart {
      display: block;
      color: get-color(ui-white);
      text-align: right;
    }
  }

  .block-search {
    @extend %vertical-spacer-top;
    margin: 16px gutter() 0;
    order: 5;
    flex-basis: 100%;
    @include breakpoint($desktop-mid) {
      order: 2;
      margin: 0;
      flex-basis: 389px;
    }
  }

  .euronics {
    display: none;
    @include breakpoint($desktop-mid) {
      display: block;
      order: 3;
      margin: 0;
      text-align: center;
    }

    img {
      height: 18px;
    }
  }

  .phone {
    margin: 0;
    order: 2;
    @include breakpoint($desktop-mid) {
      order: 4;
    }
    span {
      display: none;
      @include breakpoint($desktop-mid) {
        display: inline-block;
      }
    }
    a {
      color: get-color(ui-white);
      &:hover {
        text-decoration: none;
      }
    }
  }

  .my-account {
    margin: 0;
    order: 3;
    position: relative;
    @include breakpoint($desktop-mid) {
      order: 5;
    }
    a {
      @include fontSize(19.2px);
      color: get-color(ui-white);
    }
  }

  .header.links {
    @include fontSize(16px);
    padding: 23px;
    margin: 0;
    background: $white;
    color: get-color(ui-dark, base);
    z-index: 10;
    border: 1px solid $border;
    border-radius: 4px;
    line-height: 1.4;
    width: 300px;
    right: 17px;
    top: 62px;
    position: fixed;
    list-style: none;
    display: none;
    @include breakpoint($desktop) {
      width: calc(100% - #{44px} - #{16px}); // 100% - {distance from right edge} - {left hand window padding}
      top: 47px;
      position: absolute;
      right: 59px;
      max-width: 300px;
      height: inherit;
    }
    @include breakpoint($desktop-mid) {
      right: 0;
      top: 43px;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: -22px;
      right: 79px;
      @include breakpoint($tablet) {
        right: 93px;
      }
      @include breakpoint($desktop) {
        right: 80px;
      }
      @include breakpoint($desktop-mid) {
        right: 71px;
      }
      height: 0;
      width: 0;
      border: 11px solid;
      border-color: transparent transparent $white transparent;
      z-index: 99;
    }

    &::after {
      content: "";
      display: none;
      position: absolute;
      top: -24px;
      right: 79px;
      @include breakpoint($tablet) {
        right: 93px;
      }
      @include breakpoint($desktop) {
        right: 80px;
      }
      @include breakpoint($desktop-mid) {
        right: 71px;
      }
      height: 0;
      width: 0;
      border: 12px solid;
      border-color: transparent transparent $border transparent;
      z-index: 98;
      @include breakpoint($desktop) {
        display: block;
      }
    }

    &.active {
      display: block;
      z-index: 99;
    }

    li {
      a,
      span {
        display: block;
        padding: 0 0 10px;
      }
      &:last-child {
        a {
          padding: 0;
        }
      }
    }

  }

}
