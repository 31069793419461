.shop-by-brand {

  &__container {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .slick-dotted.slick-slider {
    margin-bottom: 0;
  }

  .slick-slide {
    margin: 12px;
    @include breakpoint($desktop-mid) {
      margin: 16px;
    }
  }

  &__item {
    border: solid 1px get-color(ui-dark, dark-20);
    border-radius: 4px;
    flex-basis: calc((100% / 2) - 24px);
    margin: 12px;
    display: flex;
    align-items: center;
    height: 108px;
    @include breakpoint($tablet) {
      flex-basis: calc((100% / 3) - 24px);
      height: 106px;
    }
    @include breakpoint($desktop-mid) {
      flex-basis: calc((100% / 3) - 32px);
      margin: 16px;
    }
    text-align: center;

    a {
      display: block;
      padding: 30px;
      margin: 0 auto;
    }

    img {
      width: 108px;
      max-height: 42px;
    }

    &:hover {
      box-shadow: 0 12px 24px -4px rgba(35, 31, 32, .1), 0 8px 16px -8px rgba(35, 31, 32, .1);
    }

  }

  &__controls {
    position: relative;
    margin: 20px auto 0;
    width: 90%;
    padding: 0;
    @include breakpoint($desktop-mid) {
      width: 568px;
      margin: 0 auto;
      padding: 0;
    }
  }
  &__dots {
    .slick-dots {
      @include breakpoint($desktop-mid) {
        width: 504px;
      }
      li {
        width: calc(100% / 18);
        button {
          width: 100%;
        }
      }
    }
  }
  &__nav {

    .nav-prev {
      position: absolute;
      left: 0;
      top: 5px;
      z-index: 9;
    }

    .nav-next {
      position: absolute;
      right: 0;
      top: 5px;
      z-index: 9;
    }
  }

}

// Brand Category Slider
.brand-category-slider__wrapper {

  .brand-slick-slider {
    .slick-slide {
      margin: 0 24px;
      @include breakpoint($desktop-mid) {
        margin: 0 16px;
      }
    }
    &__item {
      padding: 24px;
      background: get-color(ui-dark, dark-10);
      text-align: center;
      margin: 82px 0 0;
      a {
        display: block;
      }
      &:hover {
        box-shadow: 0 12px 24px -4px rgba(35, 31, 32, .1), 0 8px 16px -8px rgba(35, 31, 32, .1);
      }
    }
    &__image {
      position: relative;
      margin: -82px 0 32px;
      z-index: 9;
      img {
        max-height: 220px;
        width: auto;
        margin: 0 auto;
      }
    }
    &__controls {
      position: relative;
      margin: 20px auto 0;
      width: 90%;
      padding: 0;
      @include breakpoint($desktop-mid) {
        width: 568px;
        margin: 40px auto 0;
        padding: 0;
      }
    }
    &__dots {
      .slick-dots {
        @include breakpoint($desktop-mid) {
          width: 504px;
        }
        li {
          width: calc(100% / 8);
          @include breakpoint($desktop-mid) {
            width: calc(100% / 8);
          }
          button {
            width: 100%;
          }
          &.slick-active {
            button {
              background: get-color(ui-dark, base);
            }
          }
        }
      }
    }
    &__nav {

      .nav-prev {
        position: absolute;
        left: 0;
        top: 5px;
        z-index: 9;
      }

      .nav-next {
        position: absolute;
        right: 0;
        top: 5px;
        z-index: 9;
      }
    }
  }

}
