// Checkout
.checkout-index-index {
  overflow-x: hidden;

  .page-wrapper {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }

  .page-main {
    flex: 1;
  }

  .main-menu,
  .phone,
  .my-account,
  .page-bottom,
  .authentication-wrapper,
  .action.nav-toggle {
    display: none;
  }

  .usps-container {
    margin: 0 -24px 48px;
    @include breakpoint($desktop-mid) {
      margin: 0 -16px 48px;
    }
  }

  .page-header {
    padding: 21px 0;

    .logo {
      margin: 0 auto;
    }

    .euronics {
      margin: 0;
    }
  }

  .checkout-shipping-address {
    .step-title:first-child {
      display: none;
    }
  }

  .checkout-container {
    display: flex;
    flex-direction: column;
    @include breakpoint($desktop-mid) {
      display: unset;
      flex-direction: unset;
    }
  }

  .checkout-container {
    .opc-wrapper {
      width: 100%;
      order: 2;
      @include breakpoint($desktop-mid) {
        width: 718px;
        float: left;
        order: 1;
      }

      .opc {
        margin: 0;
        padding: 0;
        list-style: none;
      }
    }

    .opc-sidebar {
      order: 1;
      margin: 0 0 24px;
      @include breakpoint($desktop-mid) {
        width: 418px;
        float: right;
        order: 2;
        margin: 0 0 0 32px;
      }

      .modal-header {
        display: none;
      }

      #paybyfinance-placeholder {
        padding: 24px 24px 0;
        border: solid 1px $border;
        border-top: 0;
        @include fontSize(14px);

        p {
          @include fontSize(14px);
        }

        h3,
        h5 {
          @include fontSize(16px);
        }

        .footnote {
          display: none;

          p {
            @include fontSize(11px);
          }
        }

        [data-bind="visible:financeEnabled() == '1'"] {
          margin: 16px 0 0;
        }
      }

      .pbf-field-control {
        margin: 5px 0 0;

        input[type=radio] {
          margin-right: 4px;
        }

        label {
          position: relative;
          top: -4px;
        }
      }

      .shipping-information {
        margin: 24px 0 0;
        display: none;
        @include breakpoint($desktop) {
          display: block;
        }
      }

      .ship-email,
      .ship-to,
      .ship-via {
        @include fontSize(14px);
        padding: 24px;
        border: solid 1px $border;

        .shipping-information-title {
          font-weight: $font-bold;
          margin: 0 0 10px;
        }

        .shipping-information-content {
          display: flex;
          justify-content: space-between;
          line-height: 18px;
        }

        .action-edit {
          @include fontSize(14px);
          padding: 0;
          background: none;
          font-weight: $font-regular;
          text-decoration: underline;
        }
      }

      .ship-to {
        border-bottom: 0;
        border-top: 0;
      }
    }
  }

  .checkout-billing-address {
    border: solid 1px $border;
    padding: 32px;
    border-radius: 4px;
    margin: 0 0 24px;

    h4 {
      margin: 0 0 32px;
    }

    .billing-address-same-as-shipping-block.field {
      margin: 0;
      border: solid 1px $border;
      padding: 10px 16px 5px;
      border-radius: 4px;

      label {
        position: relative;
        top: -4px;
        font-weight: $font-regular;
      }
    }

    .billing-address-container {
      padding: 24px 16px;
      border: solid 1px $border;
      border-top: 0;

      .fieldset.address {
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
  }

  #paybyfinance-main-block {
    padding: 0;

    .type-widget.switcher .ruler .ruler-item:nth-child(1) {
      margin-left: unset;
    }

    .paybyfinance-sum td {
      @include fontSize(14px);
    }
  }

  .opc-block-summary {
    display: flex;
    flex-direction: column;
    border: solid 1px $border;
    border-top: 0;

    .title {
      display: none;
    }

    .items-in-cart {
      order: 1;
      padding: 24px;
    }

    .opc-sidebar-shipping {
      order: 2;
      padding: 24px;
      border-top: solid 1px $border;
      .totals-tax {
        display: none;
      }
    }

    .opc-order-total {
      @include fontSize(16px);
      @include breakpoint($desktop) {
        display: none;
      }
      padding: 17px 24px;
      border-bottom: solid 1px $border;
      background: get-color(ui-dark, dark-10);
      border-radius: 0 0 4px 4px;
      position: relative;
      &::after {
        @include fontSize(16px);
        font-family: 'Font Awesome 5 Pro';
        content: "\f054";
        position: absolute;
        right: 17px;
      }
    }

    .table-totals {
      &::after {
        @include fontSize(12px);
        content: "* All prices include VAT applied at your country's local rate";
        padding: 16px 0 0;
        display: block;
        line-height: 1;
      }
      tbody tr {
        background: transparent;
        border: 0;

        th,
        td {
          @include fontSize(14px);
          padding: 0 0 8px;
          font-weight: $font-regular;
          display: table-cell;
        }

        td {
          text-align: right;

          &::before {
            display: none;
          }
        }
      }

      .totals.shipping.excl {
        .value {
          display: none;
        }
      }

      .grand.totals {
        .mark {
          @include fontSize(16px);
          padding: 16px 0 0;
        }

        .amount {
          padding: 16px 0 0;

          strong {
            @include fontSize(16px);
            font-weight: $font-bold;
            display: block;
          }
        }
      }
    }

    .content.minicart-items {
      // sass-lint:disable-all
      display: block !important;
      // sass-lint:enable-all
      .action.viewcart {
        @include fontSize(12px);
        display: block;
        margin: 16px 0 0;
        text-decoration: underline;
      }
    }

    .minicart-items {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        padding: 0 0 16px;
        border-bottom: solid 1px $border;
        margin: 0 0 16px;

        &:last-of-type {
          border-bottom: 0;
          padding: 0;
          margin: 0;
        }
      }
    }

    .product {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .sidebar-cart__image {
      width: 69px;
      text-align: center;
      margin: 0 16px 0 0;
    }

    .product-image-container {
      display: block;
      margin: 0 16px 0 0;
      // sass-lint:disable-all
      width: 64px !important;
      // sass-lint:enable-all
      img {
        width: 64px;
        max-width: 64px;
        max-height: 64px;
      }
    }

    .product-item-inner {
      display: flex;

      .details-qty {
        margin: 8px 0 0;
        float: right;
        width: 55px;
        text-align: right;
      }

      .product-item-name-block {
        padding: 0 10px 0 0;
      }
    }

    strong {
      font-weight: $font-regular;
      display: block;
    }

    .subtotal {
      font-weight: $font-bold;
    }

    .details-qty {
      @include fontSize(12px);
      border: solid 1px $border;
      border-radius: 2px;
      color: get-color(ui-dark, dark-80);
      padding: 3px 6px;
      display: inline-block;
    }
  }

  .paybyfinance-info,
  .btn.finance,
  #paybyfinance-not-shown {
    display: none;
  }

  .opc-progress-bar {
    list-style: none;
    margin: 0;
    padding: 24px;
    border: solid 1px $border;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    font-weight: $font-bold;
    @include breakpoint($desktop-mid) {
      width: 418px;
      float: right;
    }

    li {
      color: get-color(ui-dark, dark-20);
      flex: 50%;

      &:first-child {
        margin: 0 1px 0 0;
      }

      &:last-child {
        text-align: right;
        margin: 0 0 0 1px;
      }

      &._active {
        color: get-color(ui-dark, base);

        &::after {
          background: get-color(ui-red, base);
        }
      }

      &._complete {
        &::after {
          background: get-color(ui-red, base);
        }
      }
    }

    li::after {
      content: '';
      width: 100%;
      height: 4px;
      background: get-color(ui-dark, dark-20);
      margin: 16px 0 0;
      display: block;
    }

    li:first-child::before {
      content: 'Step 1.';
    }

    li:last-child::before {
      content: 'Step 2.';
    }
  }

  .opc-estimated-wrapper {
    display: none;
  }

  .cart-totals {
    order: 2;
    width: 100%;
    border: solid 1px $border;
    border-top: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 17px 24px;
    margin: 0 0 32px;
    background: get-color(ui-dark, dark-10);
    @include breakpoint($desktop-mid) {
      background: none;
      width: 418px;
      float: right;
      padding: 24px;
    }

    table tbody tr {
      background: unset;
      border: 0;

      th,
      td {
        @include fontSize(14px);
        padding: 0;
        @include breakpoint($desktop-mid) {
          padding: 0 0 8px;
        }
        color: get-color(ui-dark, dark-80);
        font-weight: $font-regular;
      }

      .amount {
        text-align: right;
        margin: 0;
      }

      &.totals.sub,
      &.totals.shipping.excl {
        display: none;
        @include breakpoint($desktop-mid) {
          display: table-row;
        }
      }

      &.grand.totals {
        @include fontSize(16px);

        th,
        td {
          @include fontSize(16px);
        }

        th strong {
          font-weight: $font-regular;
          margin: 0;
        }

        th {
          display: table-cell;
        }

        td::before {
          display: none;
        }
      }
    }
  }

  .sagepaysuite-tokens {
    margin: 0;
    padding: 0;
    background-color: transparent;

    .token-list {
      border: solid 1px $border;
      border-radius: 4px;
      margin: 0;
    }

    .token-row {
      border: 0;
      padding: 10px 16px;
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;

      label {
        display: block;
        width: calc(100% - 30px);
        font-weight: $font-regular;
      }

      img {
        position: absolute;
        right: 16px;
        margin: 0;
      }

      a {
        @include fontSize(14px);
        text-transform: capitalize;
        padding: 0 0 0 16px;
      }
    }

    .add-new-card-link {
      display: block;
      margin: 16px 0 0;
    }

    p {
      @include fontSize(14px);
    }
  }

  fieldset {
    margin: 0;
    padding: 0;
    border: 0;

    .legend {
      display: none;
    }
  }

  .form-login,
  .fieldset.address,
  .checkout-shipping-container,
  #checkout-shipping-method-load,
  .payment-group,
  .amazon-payment {
    margin: 0 0 32px;
    padding: 32px;
    border: solid 1px $border;
    border-radius: 4px;

    label,
    .label {
      display: none;
    }

    .field-tooltip {
      .label {
        display: unset;
      }
    }

    .field.choice {
      label,
      .label {
        display: inline-block;
      }
    }

    .note {
      @include fontSize(12px);
      margin: 8px 0 0;
      color: get-color(ui-dark, dark-80);
    }

    div[name="shippingAddress.region_id"] {
      .select {
        font-weight: bold;
        color: #505152;
      }
      &::after {
        content: "\f078";
        position: absolute;
        right: 10px;
        top: 25%;
        font-family: 'Font Awesome 5 Pro';
      }
    }

  }

  .amazon-payment {
    .amazon-express-title {
      font-size: 1.5rem;
      line-height: 28px;
      font-weight: 500;
    }
    #PayWithAmazon_amazon-pay-button {
        & > div {
            margin: 0 auto;
        }
    }
  }

  .amazon-divider {
      margin-top: 1rem;
      margin-bottom: 1rem;
      text-align: center;
  }

  .checkout-shipping-method {
    h4 {
      margin: 0 0 32px;
    }
  }

  .form-login {
    padding: 32px 32px 16px;

    .field-tooltip {
      display: none;
    }

    h4 {
      margin: 0 0 32px;
    }

    .password-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      input[type="password"] {
        margin: 0 8px 0 0;
      }
    }

    .actions-toolbar {
      display: flex;
      align-items: center;
    }

    .primary {
      margin: 0 8px 0 0;
    }

    .account-exists {
      @include fontSize(14px);
      padding: 14px;
      border-radius: 4px;
      text-align: center;
      margin: 24px 0 0;
      background: rgba(68, 207, 108, .10);

      &::before {
        @include fontSize(14px);
        content: '\f05a';
        font-family: 'Font Awesome 5 Pro';
        margin: 0 8px 0 0;
      }
    }

    .payment-option-inner {
      label {
        @include fontSize(12px);
        display: flex;
        align-items: center;
        line-height: 18px;
      }
    }
  }

  .form-shipping-address {
    h4 {
      margin: 0 0 32px;
    }
  }

  .step-title {
    @include forthHeader;
    margin: 0 0 32px;
  }

  table {
    border: 0;
    padding: 0;
    border-collapse: collapse;

    thead {
      display: none;
    }

    tbody {
      tr {
        box-shadow: none;
        border: solid 1px get-color(ui-dark, dark-20);
        background: get-color(ui-dark, dark-10);
        &.sub,
        &.shipping,
        &.totals-tax {
          display: none;
          @include breakpoint($desktop) {
            display: table-row;
          }
        }
      }

      tr:first-child td:first-child {
        border-top-left-radius: 4px;
      }

      tr:first-child td:last-child {
        border-top-right-radius: 4px;
      }

      tr:last-child td:first-child {
        border-bottom-left-radius: 4px;
      }

      tr:last-child td:last-child {
        border-bottom-right-radius: 4px;
      }

      td {
        border: 0;
        padding: 10px 16px;
      }
    }
  }

  .form.methods-shipping {
    .primary {
      text-align: center;
    }

    button {
      @include breakpoint($desktop-mid) {
        width: 654px;
      }
      margin: 0 auto;
    }
  }

  #checkout-step-shipping {
    .action-show-popup {
      margin: 0 0 16px;
    }
  }

  .shipping-address {
    &-wrapper {
      padding: 32px;
      border: solid 1px $border;
      border-radius: 4px;

      h4 {
        margin: 0 0 32px;
      }
    }

    &-items {
      border: solid 1px $border;
      border-radius: 4px;
    }

    &-item {
      display: flex;
      flex-direction: row;
      padding: 12px 16px;
      border-bottom: solid 1px $border;

      &.selected-item {
        background: get-color(ui-dark, dark-10);
      }

      &:last-child {
        border-bottom: 0;
      }

      .address {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        flex: 100%;
      }
    }
  }

  button.ship-link {
    @include fontSize(0px);
    border-radius: 100%;
    background: get-color(ui-white);
    border: solid 1px get-color(ui-dark, dark-20);
    text-indent: -999999px;
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0 8px 0 0;
    position: relative;

    &._active::after {
      content: '';
      position: absolute;
      background: get-color(ui-dark, base);
      width: 10px;
      height: 10px;
      display: block;
      border-radius: 100%;
      margin: 5px auto;
      top: calc(50% - 10px);
      left: calc(50% - 10px);
      right: calc(50% - 10px);
      bottom: calc(50% - 10px);
    }
  }

  .new-address-popup {
    padding: 0 32px 32px;
    border: solid 1px $border;
    border-top: 0;
    border-radius: 4px;
    margin: -16px 0 32px;
  }

  .table-checkout-shipping-method {
    .col-radio {
      width: 28px;
      padding-right: 0;

      input[type=radio] {
        margin-top: 3px;
      }
    }

    .col-name {
      padding-left: 0;
    }

    .col-price {
      text-align: right;

      .price.free-shipping {
        color: get-color(ui-success, base);
        text-transform: uppercase;
      }
    }

    .free-delivery {
      color: get-color(ui-success, base);
    }
  }

  .payment-group-items {
    border: solid 1px $border;
    border-radius: 4px;

    .payment-method._active {
      .payment-method-title {
        background: get-color(ui-dark, dark-10);
      }

      .payment-method-content {
        display: block;
      }
    }

    .payment-method-title {
      position: relative;
      border-bottom: solid 1px $border;
      padding: 10px 16px 6px;
      margin: 0;

      img,
      .payment-icon {
        position: absolute;
        right: 16px;
        height: 24px;
          width: auto;
      }

      input[type=radio]:checked + .label:before {
        left: 15px;
        top: 9px;
      }

      .action.action-help {
        display: none;
      }
    }

    .opayo-payment-method-logo {
      background-image: url('../Ebizmarts_SagePaySuite/images/opayo_logo.png');
    }

    .payment-method-content {
      display: none;
      padding: 24px 16px;
      border-bottom: solid 1px $border;

      #sagepay-pi-remembertoken-container,
      #sp-container,
      .payment-method-note {
        margin: 0 0 16px;

        label {
          position: relative;
          top: -5px;
          display: inline-block;
          font-weight: $font-regular;
        }
      }

      button {
        width: 100%;
        min-width: 100%;

        &::before {
          content: '\f058';
          font-family: 'Font Awesome 5 Pro';
          font-weight: $font-regular;
        }
      }
    }
  }

  .copyright {
    margin: 32px 0 0;
    @include breakpoint($desktop-mid) {
      margin: 48px 0 0;
    }
  }

  .table-checkout-shipping-method {
    td {
      display: table-cell;

      &::before {
        display: none;
      }
    }
  }

  // Braintree Specific Styles
  .payment-method-braintree {

    .label {
      display: block;
    }

    .hosted-control {
      height: 40px;
      border: 1px solid $border;
      padding: 0 0.5rem;

      &.braintree-hosted-fields-invalid:not(.braintree-hosted-fields-focused) {
        border: 1px solid red;
      }

      &.braintree-hosted-fields-invalid:not(.braintree-hosted-fields-focused) ~ .hosted-error {
        height: auto;
        opacity: 1;
      }
    }

    .hosted-error {
      flex: 1 0 100%;
      opacity: 0;
      height: 0;
    }

    .braintree-card-control {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      & > img {
        width: 48px;
        height: 48px;
      }

      .hosted-control {
        flex: 1 0 auto;
        width: calc(100% - 60px);
        margin-left: 0.5rem;
      }
    }

    .braintree-credit-card-types {
      height: 0;
      width: 0;
      list-style: none;
    }
  }

  .terms-conditions-link {
    margin-bottom: 1rem;

    a {
      font-weight: bold;
    }
  }
}

// Checkout Success
.checkout-success {
  &__inner {
    @include breakpoint($desktop) {
      display: flex;
      flex-direction: row;
    }
  }

  &__left-col {
    @include breakpoint($desktop) {
      flex-basis: 718px;
      margin: 0 32px 0 0;
    }
  }

  &__right-col {
    margin: 24px 0;
    @include breakpoint($desktop) {
      flex-basis: 418px;
      margin: 0;
    }
  }

  &__sub-heading {
    @include thirdHeader;
    margin: 0 0 32px;
  }

  &__actions-toolbar {
    a {
      display: block;
      margin: 0 0 16px;
      @include breakpoint($tablet) {
        display: unset;
        margin: 0;
      }
      text-align: center;
    }

    .primary {
      @include breakpoint($tablet) {
        margin: 0 16px 0 0;
      }
    }
  }

  &__newsletter-signup {
    .checkout-success__sub-heading {
      display: none;
    }
  }

  &__order-confirmation,
  &__newsletter-signup {
    border: solid 1px get-color(ui-dark, dark-20);
    border-radius: 4px;
    padding: 32px;
    margin: 0 0 24px;

    p {
      @include fontSize(14px);
      margin: 0 0 32px;
    }

    .newsletter__heading,
    .newsletter__label-text {
      display: none;
    }

    .newsletter__form {
      @include breakpoint($tablet) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      input {
        margin: 0 0 16px;
        @include breakpoint($tablet) {
          margin: 0;
        }
      }
    }

    .newsletter__label {
      display: block;
      width: 100%;
      margin: 0 8px 0 0;
    }
  }

  &__map {
    height: 300px;

    .leaflet-container {
      height: 100%;
    }
  }

  &__order-details {
    border: solid 1px get-color(ui-dark, dark-20);
    border-radius: 4px;

    h6 {
      margin: 0 0 12px;
    }

    p {
      @include fontSize(14px);
    }
  }

  &__order-details-section {
    padding: 32px 32px 16px;
    border-bottom: solid 1px get-color(ui-dark, dark-20);
  }

  &__right-col-section {
    border: solid 1px get-color(ui-dark, dark-20);
    border-bottom: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 24px;

    .item-details {
      @include fontSize(14px);
      border-bottom: solid 1px get-color(ui-dark, dark-20);
      padding: 0 0 16px;
      margin: 0 0 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &:last-child {
        border: 0;
        margin: 0;
        padding: 0;
      }

      &__image {
        width: 64px;
        margin: 0 16px 0 0;
      }

      &__product {
        width: 215px;
        margin: 0 16px 0 0;
      }

      &__price {
        @include fontSize(14px);
        margin: 0;
        text-align: right;

        .price {
          display: block;
          font-weight: $font-bold;
          margin: 0 0 8px;
        }

        .qty-wrapper {
          @include fontSize(12px);
          display: inline-block;
          padding: 6px;
          border: solid 1px get-color(ui-dark, dark-20);
          border-radius: 3px;
        }
      }
    }
  }

  &__right-col-totals {
    @include fontSize(14px);
    border: solid 1px get-color(ui-dark, dark-20);
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: 24px 24px 18px;

    .col {
      display: flex;
      justify-content: space-between;
      margin: 0 0 8px;
    }

    .grantTotal {
      @include fontSize(16px);
      margin: 16px 0 0;

      span {
        font-weight: $font-bold;
      }
    }
  }
}

.empty-shipping-reminder {
  border: 3px solid $error;
  font-weight:$font-bold;
  padding: 8px 8px;
  margin: 0 0 15px;
}

.no-checkout {
  pointer-events: none;
}

.checkout-index-index {
    .amazon-express-title {
        margin: 0 0 6px;
    }
    .amazon-button-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 450px;
        margin: 0;
    }
    .amazon-button-column-tooltip {
        margin: 0 0 0 8px;
    }
}

//
//  Google Pay
//  _____________________________________________

button.braintree-googlepay-button {
    background-origin: content-box;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    border: 0;
    border-radius: 4px;
    box-shadow: rgba(60, 64, 67, 0.3) 0 1px 1px 0, rgba(60, 64, 67, 0.15) 0 1px 3px 1px;
    cursor: pointer;
    height: 40px;
    min-height: 40px;
    padding: 11px 24px;
}

button.braintree-googlepay-button.black {
    background-color: #000;
    box-shadow: none;
    padding: 12px 24px;
}

button.braintree-googlepay-button.white {
    background-color: #ffffff;
}

button.braintree-googlepay-button.short {
    min-width: 90px;
    width: 160px;
}

button.braintree-googlepay-button.black.short {
    background-image: url(https://www.gstatic.com/instantbuy/svg/dark_gpay.svg);
}

button.braintree-googlepay-button.white.short {
    background-image: url(https://www.gstatic.com/instantbuy/svg/light_gpay.svg);
}

button.braintree-googlepay-button.black:active {
    background-color: #5f6368;
}

button.braintree-googlepay-button.black:hover {
    background-color: #3c4043;
}

button.braintree-googlepay-button.white:active {
    background-color: #ffffff;
}

button.braintree-googlepay-button.white:focus {
    box-shadow: #e8e8e8 0 1px 1px 0, #e8e8e8 0 1px 3px;
}

button.braintree-googlepay-button.white:hover {
    background-color: #f8f8f8;
}

button.braintree-googlepay-button.long {
    min-width: 152px;
    width: 240px;
}

button.braintree-googlepay-button.white.long {
    background-image: url(https://www.gstatic.com/instantbuy/svg/light/en.svg);
}

button.braintree-googlepay-button.black.long {
    background-image: url(https://www.gstatic.com/instantbuy/svg/dark/en.svg);
}

.googlepay-minicart-logo {
    margin-top: 15px;
    text-align: center;
}

//
//  Apple Pay button
//  _____________________________________________

.braintree-applepay-container-product {
    display: inline-block;

    .braintree-apple-pay-button {
        padding: 10px 0;
        margin: 0;
        margin-bottom: 15px;
    }
}

.braintree-apple-pay-button {
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-type: plain;
    display: block;
    width: 200px;
    min-height: 40px;
    border: 1px solid black;
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-size: 100%;
    background-repeat: no-repeat;
    background-color: black;
    background-position: 50% 50%;
    border-radius: 5px;
    padding: 2px;
    margin: 20px auto;
    transition: background-color .15s;
    cursor: pointer;
}
