body.minicart-open {
  overflow: hidden;
  @include breakpoint($desktop) {
    overflow: inherit;
  }
}

.minicart-wrapper {
  position: relative;

  .showcart {
    @include fontSize(19.2px);
    position: relative;

    .counter.qty {
      @include fontSize(12px);
      font-weight: $font-bold;
      background: get-color(ui-red, base);
      color: $white;
      margin: 0;
      padding: 0;
      height: 20px;
      width: 20px;
      line-height: 20px;
      border-radius: 50%;
      text-align: center;
      position: absolute;
      top: -4px;
      right: -10px;
      &.empty {
        display: none;
      }

      .counter-label {
        display: none;
      }
    }
  }

  .mobile-heading {
    display: flex;
    background: #f9fafb;
    padding: 20px 24px;
    margin-left: -23px;
    margin-right: -23px;
    @include breakpoint($desktop) {
      display: none;
    }

    .title {
      flex: 1;
    }

    .close {
      width: 18px;
    }
  }

  .block-minicart {
    @include fontSize(16px);
    padding: 0 23px 23px;
    margin: 0;
    background: $white;
    color: get-color(ui-dark, base);
    z-index: 10;
    border: 1px solid $border;
    border-radius: 4px;
    line-height: 1.4;
    display: none;
    width: 100%;
    right: 0;
    top: 0;
    position: fixed;
    max-width: 100%;
    height: 100%;
    @include breakpoint($desktop) {
      width: calc(100% - #{44px} - #{16px}); // 100% - {distance from right edge} - {left hand window padding}
      top: 35px;
      position: absolute;
      right: -10px;
      min-width: 360px;
      height: inherit;
      padding: 23px;
      z-index: 99;
    }

    &::before {
      content: "";
      display: none;
      position: absolute;
      top: -22px;
      right: 9px;
      height: 0;
      width: 0;
      border: 11px solid;
      border-color: transparent transparent $white transparent;
      z-index: 99;
      @include breakpoint($desktop) {
        display: block;
      }
    }

    &::after {
      content: "";
      display: none;
      position: absolute;
      top: -24px;
      right: 8px;
      height: 0;
      width: 0;
      border: 12px solid;
      border-color: transparent transparent $border transparent;
      z-index: 98;
      @include breakpoint($desktop) {
        display: block;
      }
    }

    .block-title {
      display: none;
    }

    .block-content {
      .minicart__count-and-total {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;

        .items-total,
        .subtotal {
          display: inline-block;
          color: $fieldBorder;
        }

        .items-total {
          font-weight: $font-bold;
        }

        .subtotal {
          font-weight: $font-regular;

          .label,
          .amount {
            display: inline-block;

            span {
              @include fontSize(16px);
              font-weight: $font-regular;
              text-transform: capitalize;
            }
          }
        }
      }

      .minicart-header,
      .minicart-footer {

        flex-wrap: wrap;
        justify-content: space-between;

        .items-total {
          flex: 1;
        }

        .subtotal {
          display: flex;

          .label {
            margin-right: 8px;
          }
        }

        .actions {
          width: 100%;

          .action.checkout {
            width: 100%;
          }

          .paypal.checkout {
            display: none;
          }
        }
      }

      .minicart-footer {
        #minicart-amazon-pay-button {
          width: 100%;
          #PayWithAmazon-Cart > div {
            width: 100%;
          }
        }
      }

      .minicart-header {
        @include fontSize(14px);
        display: none;
        margin: -23px -23px 0;
        background: #f9fafb;
        padding: 20px 20px 0;
        #minicart-amazon-pay-button {
          display: none;
        }
        @include breakpoint($desktop) {
          display: flex;
        }
          .actions {
              display: none;
          }
      }

      .minicart-footer {
        display: flex;
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 20px;
        background-color: #F9FAFB;
        @include breakpoint($desktop) {
          position: relative;
          background-color: transparent;
          padding: 0;
        }

        .items-total,
        .subtotal {
          @include breakpoint($desktop) {
            display: none;
          }
        }

        .actions {
          text-align: center;
          padding: 0;
          margin: 16px 0 0;

          .secondary {
            @extend %secondary-button;
            a {
              display: block;
              &:hover {
                text-decoration: none;
              }
            }
          }
        }
      }

      >.subtitle {
        display: none;
      }

      .minicart-items-wrapper {
        height: unset !important;

        .minicart-items {
          padding: 0;

          .item {
            display: inline-block;
            margin-top: 16px;
            padding-bottom: 16px;
            border-bottom: 1px solid #e5e5e5;
            width: 100%;

            .product {
              display: flex;
            }

            .product-item-photo {
              vertical-align: top;

              &:focus {
                outline: none;
                box-shadow: none;
              }

              img {
                vertical-align: top;
              }
            }

            .product-item-details {
              display: inline-block;
              margin-left: 16px;
              flex: 1;
              position: relative;

              .product.options {
                color: $textLight;
              }

              .product-item-pricing {
                display: flex;
                justify-content: space-between;
                margin-top: 8px;

                .price-container {
                  display: inline-block;

                  span {
                    font-weight: $font-bold;
                  }
                }

                .product.actions {
                  display: inline-block;
                  padding: 0;
                  border-bottom: 0;

                  a {
                    @include fontSize(14px);
                    padding: 0;
                    line-height: 20px;
                    font-weight: $font-regular;
                  }
                }
              }

              .details-qty.qty {
                @include quantityBox;
                @include inputQuantity;
                @include resetQuantity;
                width: auto;
                font-size: 0;
                @include breakpoint($desktop) {
                  position: relative;
                  margin-top: 8px;
                }

                input,
                .qty-box {
                  color: $text;
                  margin: 0;
                }

                button {
                  background: $white;
                  padding: 0;
                  color: $textLight;
                  text-align: left;
                  @include breakpoint($desktop) {
                    background: none;
                    padding: 0;
                    text-align: center;
                  }

                  span {
                    @include fontSize(14px);
                    font-weight: $font-regular;
                    color: $headingText;
                    @include breakpoint($desktop) {
                      text-decoration: underline;
                    }
                  }
                }

                .update-cart-item {
                  display: inline-block;
                  margin: 5px;
                }
              }
            }
          }
        }
      }
    }

    .subtitle.empty {
      @include fontSize(14px);
      text-align: center;
      width: 100%;
      display: block;
    }
  }
}

.amazon-button-container {
    .checkout-methods-items {
        display: none;
    }
}

.minicart-wrapper .block-minicart .block-content .minicart-footer #minicart-amazon-pay-button, .minicart-wrapper .block-minicart .block-content .minicart-footer #minicart-amazon-pay-button #PayWithAmazon-Cart>div {
    margin: 0 auto;
}
