.catalog-category-view {

  .category {
    &-cms {
      @include breakpoint($tablet) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }
    &__wrapper {
      background: get-color(ui-dark, dark-10);
      padding: 32px 52px;
      margin: 0 0 74px;
      @include breakpoint($tablet) {
        flex-basis: calc(50% - 16px);
        padding: 32px 60px;
      }
    }
    &__image {
      height: 139px;
      width: 139px;
      margin: -74px auto 24px;
      @include breakpoint($desktop) {
        height: 220px;
        width: 220px;
        margin: -74px auto 32px;
      }
    }
    &__title {
      text-align: center;
      margin: 0 0 20px;
    }
    &__description {
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
      }
      li {
        background: get-color(ui-dark, dark-80);
        font-weight: $font-bold;
        border-radius: 16px;
        margin: 0 4px 8px;
        border: solid 1px get-color(ui-dark, dark-80);
        &:hover {
          background: get-color(ui-white);
          border: solid 1px get-color(ui-dark, dark-80);
        }
      }
      a {
        color: get-color(ui-white);
        padding: 5px 12px;
        display: block;
        &:hover {
          color: get-color(ui-dark, dark-80);
          text-decoration: none;
        }
      }
    }
  }

  .toolbar-footer {
    display: none;
  }

}

.amscroll-page-num {
  @include fontSize(14px);
  text-align: center;
  color: get-color(ui-dark, dark-80);
  margin: 0 0 15px;
  display: flex;
  position: relative;
  justify-content: center;
  span {
    line-height: 22px;
    background: get-color(ui-white);
    color: get-color(ui-dark, dark-80);
    padding: 0 16px;
  }
  &::after {
    content: "";
    display: block;
    border-bottom: 1px solid #e2e3e4;
    width: 100%;
    position: absolute;
    top: 50%;
    z-index: -1;
  }
  &:nth-child(odd) {
    display: none;
  }
}

.amscroll-loading-block {
  display: none;
}

.amscroll-load-button {
  display: block;
  background-color: get-color(ui-white);
  color: get-color(ui-dark, base);
  border-radius: 16px;
  text-align: center;
  width: 192px;
  margin: 0 auto 16px;
  text-transform: capitalize;
  svg {
    display: none;
  }

  .amscroll-text {
    pointer-events: none;
  }
}
