.cart-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0 50px;
  @include breakpoint($desktop) {
    flex-direction: row-reverse;
  }
  .form-cart {
    width: 100%;
    @include breakpoint($desktop) {
      width: span(10);
      margin: 0 32px 0 0;
    }
  }
}
.product-grid {
  border: 1px solid $border;
  border-radius: 4px;
  padding: 22px 14px;
  @include breakpoint($desktop) {
    padding: 32px;
  }
  &__item {
    border-top: 1px solid $border;
    margin: 16px 0 0;
    padding: 16px 0 0;
    &:first-child {
      border: 0;
      margin: 0;
      padding: 0;
    }
  }
  &__name {
    margin-right: auto;

    a {
      @include fontSize(16px);
      text-decoration: none;
    }
  }
  &__quantity {
    margin: 16px 0;
    @include breakpoint($desktop) {
      margin: 0 0 0 10px;
    }
    &-text {
      display: none;
    }
    input.input-text {
      @include fontSize(16px);
      width: 40px;
      height: 40px;
      text-align: center;
      padding: 0;
      border: 0;
      border-radius: 0;
      appearance: none;
      @include breakpoint($desktop) {
        width: 40px;
      }
    }
    button {
      width: 40px;
      height: 40px;
      background: none;
      font-size: 0;
      cursor: pointer;
      padding: 9px 19px;
      &.product-qty-minus {
        border-radius: 2px 0 0 2px;
        &::before {
          @include fontSize(12px);
          content: "\f068";
          font-family: "Font Awesome 5 Pro";
          position: relative;
          left: -5px;
        }
      }
      &.product-qty-plus {
        border-radius: 0 2px 2px 0;
        &::before {
          @include fontSize(12px);
          content: "\f067";
          font-family: "Font Awesome 5 Pro";
          position: relative;
          left: -5px;
        }
      }
    }
    label {
      display: flex;
    }
  }
  .col {
    &.price {
      display: none;
    }
    &.subtotal {
      @include fontSize(14px);
      margin-right: auto;
      .standard-price {
        font-weight: $font-bold;
      }
      .special-price {
        display: inline-block;
        font-weight: $font-bold;
      }
      .saving-price {
        margin: 0 12px;
      }
    }
  }
  &__item-wrapper {
    display: flex;
  }
  &__item-info {
    width: 100%;
  }
  &__item-row {
    @include breakpoint($desktop) {
      display: flex;
    }
  }
  &__image {
    margin-right: 16px;
    width: 80px;

    img {
      width: 100%;
    }
  }
  // Edit/remove buttons
  .action {
    @include fontSize(14px);
    padding: 0;
    border: 0;
    text-align: right;
    color: $primary;
    margin: 16px 16px 0 0;
    display: inline-block;
    text-decoration: underline;
    @include breakpoint($desktop) {
      margin: 16px 0 0 16px;
    }
    &:hover {
      text-decoration: underline;
    }
  }
  //input::-webkit-outer-spin-button,
  //input::-webkit-inner-spin-button {
  //  appearance: none;
  //  margin: 0;
  //}
  input[type=number] {
    appearance: textfield;
  }
}
// Product Configuration
.product-config {
  $this: &;
  margin: 10px 0 0;
  &.active {
    #{$this}__button {
      &::before {
        transform: rotate(180deg);
      }
    }
    #{$this}-table {
      display: block;
    }
  }
  &__button {
    @include fontSize(14px);
    padding: 0 20px 0 0;
    margin: 0;
    position: relative;
    background: $white;
    color: $textLight;
    font-weight: $font-bold;
    text-align: left;
    border: 0;
    cursor: pointer;
    width: auto;
    &::before {
      content: "";
      background: url("../images/icons/arrow-down.svg") no-repeat center;
      display: block;
      width: 10px;
      height: 6px;
      position: absolute;
      right: 0;
      top: 50%;
      margin: -3px 0 0;
    }
  }
  &-table {
    display: none;
    &__row {
      @include fontSize(13px);
      display: flex;
      justify-content: space-between;
      margin: 8px 0;
      &-label {
        font-weight: $font-bold;
      }
    }
  }
}
