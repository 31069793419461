.page-footer {
  background: get-color(ui-dark, base);
  color: get-color(ui-white);
  padding: 32px 24px;
  @include breakpoint($desktop-mid) {
    padding: 64px 0;
  }

  a,
  p {
    @include fontSize(14px);
    color: get-color(ui-white);
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .footer-menus {
    display: flex;
    flex-direction: column;
    @include breakpoint($desktop-mid) {
      flex-direction: row;
    }
    .footer-menu {
      padding: 14px 0 0;
      border-bottom: solid 1px get-color(ui-dark, dark-80);
      @include breakpoint($desktop-mid) {
        flex-basis: calc(100% / 3);
        border: 0;
        padding: 0;
      }
      &:last-child {
        @include breakpoint($desktop-mid) {
			order: 3;
            padding-left: 70px;
		}
      }
      &:first-child {
        order: 2;
		@include breakpoint($desktop-mid) {
            padding-left: 70px;
        }
      }
      &:nth-child(2) {
        order: 1;
		@include breakpoint($desktop-mid) {
            padding-left: 70px;
        }
      }
      &:nth-child(5) {
        order: 3;
      }
      &__list {
        @include breakpoint($desktop-mid) {
          // sass-lint:disable-all
          display: block !important;
          // sass-lint:enable-all
        }
      }
      &__list li {
        display: inline-block;
        width: 49%;
        margin: 0 0 14px;
      }
	  .social-icons {
		width: 10% !important;
		padding: 20px 0 0;
	  }
	  @media screen and (max-width: 767px) {
		.social-icons {
		  width: 15% !important;
		}
	  }
	  @media (min-width: 768px) and (max-width: 1024px) {
		.social-icons {
		  width: 16% !important;
		  text-align: center;
		  margin-top:20px;
		  padding: 0;
		}
	  }
      .footer-contact {
        width: 100%;
      }
    }
    &__inner-item:not(&--cms_block) {
      margin: 0 0 16px;
      display: inline-block;
      width: 49%;
    }
    &__inner-item--cms_block {
      width: 100%;
    }
    &__title,
    .menu__title {
      @include fontSize(16px);
      margin: 0 0 14px;
      font-weight: $font-bold;
      display: block;
      width: 100%;
      position: relative;
      @include breakpoint($desktop-mid) {
        margin: 0 0 24px;
      }
      &::after {
        content: "\f067";
        font-family: Font Awesome\ 5 Pro;
        position: absolute;
        right: 0;
        @include breakpoint($desktop-mid) {
          display: none;
        }
      }
      &.open {
        &::after {
          content: "\f068";
          font-family: Font Awesome\ 5 Pro;
          position: absolute;
          right: 0;
          @include breakpoint($desktop-mid) {
            display: none;
          }
        }
      }
    }
  }
  .footer-bottom {
    display: flex;
    flex-direction: row;
	@media (max-width: 767px) {
	  flex-direction: column;
	}
    justify-content: space-between;
    margin: 25px 0 0;
    align-items: center;
    flex-wrap: wrap;
    @include breakpoint($desktop-mid) {
      flex-wrap: nowrap;
    }
	@media (min-width: 1024px) {
	  div {
		flex: 0 calc(100% / 2);
	  }
	}  
    &__socialmedia {
      order: 1;
	  @include breakpoint($desktop-mid) {
		padding-left: 70px;
	  }
	  @media (min-width: 768px) and (max-width: 1024px) {
		padding-left: 125px;
	  }
	  @media screen and (max-width: 767px) {
		padding-left: 30px;
	  }
      ul {
        display: flex;
        flex-direction: row;
      }
      li {
        margin: 0 24px 0 0;
        a {
          @include fontSize(19.2px);
        }
        &:last-child {
          margin: 0;
        }
      }
    }
    &__euronics {
      order: 3;
      text-align: center;
      padding: 24px 0 0;
	  @media (max-width: 767px){
		padding: 30px 0 0;
	  }
      @include breakpoint($desktop-mid) {
        order: 2;
        padding: 0;
      }
      img {
        width: 139px;
      }
    }
    &__sslsecure {
      order: 2;
      text-align: center;
      padding: 0;
      @include breakpoint($desktop-mid) {
        order: 3;
      }
	  @media (min-width: 768px) and (max-width: 1024px) {
		padding: 28px 0 0;
	  }
      i {
        color: get-color(ui-greenssl);
        margin: 0 6px 0 0;
      }
    }
    &__paymentmethods {
      order: 4;
      text-align: right;
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      padding: 24px 0 0;
	  @media (max-width: 767px) {
		padding: 30px 0 0;
	  }
      line-height: 1;
      @include breakpoint($desktop-mid) {
        padding: 0;
      }
      ul {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
      li {
        margin: 0 4px 0 0;
        &:last-child {
          margin: 0;
        }
      }
      img {
        // sass-lint:disable-all
        width: 37px !important;
        height: auto !important;
        // sass-lint:enable-all
      }
    }
  }
}

.copyright {
  padding: 24px 24px 10px;
  @include breakpoint($desktop) {
    padding: 32px 0 16px;
  }
  background: get-color(ui-dark, dark-90);
  text-align: center;
  line-height: 18px;

  &--content {
    @include breakpoint($desktop) {
      max-width: var(--grid-max);
      margin: 0 auto;
      padding: 0;
    }
    p {
      @include fontSize(14px);
      color: get-color(ui-white);
    }
  }
}
