.product-info-price .price,
.modal-content__product--price .price,
.add-to-cart-stocky__actions .price,
.widget-viewed-grid .price,
.col.subtotal .price {
  &-box {
    .special-price,
    .old-price {
      .price-label {
        display: none;
      }
    }
    .special-price {
      display: block;
      margin: 0 0 4px;
      .price {
        @include fontSize(14px);
        color: get-color(ui-red, base);
      }
    }
    .saving-price {
      @include fontSize(14px);
      border-radius: 2px;
      background: get-color(ui-red, red-10);
      color: get-color(ui-red, base);
      padding: 2px 4px;
      display: inline-block;
    }
    .old-price {
      display: inline-block;
      margin: 0 0 8px;
      .price-wrapper {
        @include fontSize(14px);
        font-weight: $font-regular;
        color: get-color(ui-dark, dark-70);
        text-decoration: line-through;
      }
      .price {
        @include fontSize(14px);
        font-weight: $font-regular;
        color: get-color(ui-dark, dark-70);
        text-decoration: line-through;
      }
      .rrp {
        @include fontSize(14px);
        font-weight: $font-regular;
        color: get-color(ui-dark, dark-70);
      }
    }
  }
  &-label {
    @include fontSize(14px);
    display: none;
    text-transform: uppercase;
    color: $text;
    margin: 0 0 6px;
  }
  &-wrapper {
    @include fontSize(16px);
    font-weight: $font-bold;
  }
}

.catalog-product-view .product-info-price {
  .price-final_price {
    .price {
      @include fontSize(24px);
    }
    .discontinued {
      @include fontSize(24px);
      color: get-color(ui-dark, dark-20);
      font-weight: $font-bold;
    }
    .old-price {
      .price {
        @include fontSize(14px);
      }
    }
    .special-price {
      .price {
        @include fontSize(24px);
      }
    }
  }
}

.widget-viewed-grid .price-box {
  margin: 16px 0 0;
  // sass-lint:disable-all
  display: block !important;
  // sass-lint:enable-all
}
