.tooltip:hover,
[data-tooltip-bottom]:hover,
[data-tooltip-left]:hover,
[data-tooltip-right]:hover,
[data-tooltip]:hover {
	position: relative;
}
[data-tooltip-bottom]:hover::after,
[data-tooltip-left]:hover::after,
[data-tooltip-right]:hover::after,
[data-tooltip]:hover::after {
	font-family: $inter;
	display: inline-block;
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 10px solid #1a1a1a;
	position: absolute;
	bottom: 100%;
	content: '';
	left: 50%;
	transform: translate(-50%, 0);
	margin-bottom: 5px;
}
[data-tooltip-right]:hover::after {
	font-family: $inter;
	margin-bottom: 0;
	bottom: auto;
	transform: rotate(90deg) translate(0, -50%);
	left: 100%;
	top: 50%;
	margin-left: -5px;
	margin-top: -5px;
}
[data-tooltip-left]:hover::after {
	margin-bottom: 0;
	bottom: auto;
	transform: rotate(-90deg) translate(0, -50%);
	left: auto;
	right: 100%;
	top: 50%;
	margin-right: -5px;
	margin-top: -5px;
}
[data-tooltip-bottom]:hover::after {
	margin-bottom: 0;
	bottom: auto;
	transform: rotate(180deg) translate(-50%, 0);
	top: 100%;
	margin-top: 5px;
}
.tooltip:hover .tooltip-dynamic-bottom,
.tooltip:hover .tooltip-dynamic-left,
.tooltip:hover .tooltip-dynamic-right,
.tooltip:hover .tooltip-dynamic,
[data-tooltip-bottom]:hover::before,
[data-tooltip-left]:hover::before,
[data-tooltip-right]:hover::before,
[data-tooltip]:hover::before {
	font-family: $inter;
	all: initial;
	display: inline-block;
	border-radius: 5px;
	padding: 10px;
	background-color: get-color(ui-black);
	content: attr(data-tooltip);
	color: #ffffff;
	position: absolute;
	bottom: 100%;
	width: 200px;
	left: 50%;
	transform: translate(-50%, 0);
	margin-bottom: 15px;
	text-align: center;
	font-size: 14px;
}
.tooltip:hover .tooltip-dynamic-right,
[data-tooltip-right]:hover::before {
	font-family: $inter;
	line-height: 22px;
	margin-bottom: 0;
	bottom: auto;
	transform: translate(0, -50%);
	left: 100%;
	top: 50%;
	content: attr(data-tooltip-right);
	margin-left: 10px;
}
.tooltip:hover .tooltip-dynamic-left,
[data-tooltip-left]:hover::before {
	margin-bottom: 0;
	bottom: auto;
	transform: translate(0, -50%);
	left: auto;
	right: 100%;
	top: 50%;
	content: attr(data-tooltip-left);
	margin-right: 15px;
}
.tooltip:hover .tooltip-dynamic-bottom,
[data-tooltip-bottom]:hover::before {
	margin-bottom: 0;
	bottom: auto;
	top: 100%;
	content: attr(data-tooltip-bottom);
	margin-top: 15px;
}
.tooltip .tooltip-dynamic-bottom,
.tooltip .tooltip-dynamic-left,
.tooltip .tooltip-dynamic-right,
.tooltip .tooltip-dynamic {
	font-family: $inter;
	display: none;
	width: auto !important;
}
