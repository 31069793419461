.promo-slots {
  padding: 0 24px 48px;
  @include breakpoint($desktop-mid) {
    padding: 80px 0 56px;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    @include breakpoint($tablet) {
      flex-direction: row;
    }
    justify-content: space-between;
  }
  &__item {
    flex-basis: 100%;
    margin: 0 0 24px;
    @include breakpoint($tablet) {
      flex-basis: calc(50% - 16px);
    }
    &.full-width {
      flex-basis: 100%;
      text-align: center;
    }
  }
}
