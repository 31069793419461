// sass-lint:disable-all
.klevu-searching-area-l2 {
  width: 91% !important;
  margin-top: 6px !important;
  @include breakpoint($desktop-mid) {
    width: 388px !important;
  }
}

.klevuSuggestionHeading {
  margin-top: 0 !important;
  margin-bottom: 12px !important;
  padding: 8px 15px !important;
  background: get-color(ui-dark, dark-10);
  font-weight: $font-bold;
  border-top: solid 1px get-color(ui-dark, dark-20);

  .klevuHeadingText {
    font-size: 14px !important;
    color: get-color(ui-dark, dark-70);
  }
}

.klevuSuggestionHeading a {
  font-size: 14px !important;
  color: get-color(ui-dark, dark-70) !important;
  text-decoration: underline !important;
  margin-right: 0 !important;
  position: relative;
  top: -2px;
}

.klevuAutoSuggestion-l2 ul li:hover {
  background-color: transparent !important;
}

.klevuAutoSuggestion-l2 ul li a {
  padding: 0 15px 12px !important;
  display: block;
}

.klevuAutoSuggestion-l2 ul li a:hover {
  text-decoration: underline;
}

.klevuSearchResults-l2 ul li .klevu-name-l2 {
  font-weight: $font-regular !important;
  margin: 0 0 8px;
}

.klevuSearchResults-l2 ul li .klevu-desc-l2 {
  display: none !important;
}

.klevuSearchResults-l2 ul li {
  border-bottom: 0 !important;
}

.klevuSearchResults-l2 ul li:hover {
  background-color: transparent !important;
}
// sass-lint:enable-all
