.amexfee-block-content {
  border: 1px solid get-color(ui-dark, dark-20);
  border-radius: 2px;

  .title {
    padding: 0.75rem;
    font-size: 14px;
    font-weight: bold;

    &::before {
      content: "\f0ad";
      font-family: Font Awesome\ 5 Pro;
      font-weight: 300;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;
      margin-right: 0.5rem;
    }
  }

  .content {
    background-color: #fff;
  }
  
  .amexfee-form-container {
    padding-top: 0.5rem;    
  }

  .amexfee-fieldset {
    border: none;
    padding-top: 0;
    padding-bottom: 0;
  }

  .amexfee-item-element {
  
    .amexfee-title {
      display: block;
      margin-bottom: 0.5rem;
    }
  }

  .control {
    border: 1px solid get-color(ui-dark, dark-20);
    border-radius: 4px;
  }

  .field-wrapper {
    display: flex;
    align-items: flex-start;
    position: relative;
    padding: 0.5rem;
    border-bottom: 1px solid get-color(ui-dark, dark-20);

    &:last-of-type {
      border-bottom: none;
    }
  }

  .amexfee-input {
    flex-shrink: 0;

    &:checked {
      z-index: 1;

      &+.amexfee-label {

         &::before {
          content: "";
          position: absolute;
          top: 7px;
          left: 7px;
          height: 20px;
          width: 20px;
          border: 1px solid #c4cdd5;
          border-radius: 50%;
          z-index: 1;
        }

        &::after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: get-color(ui-dark, dark-10);
          border-radius: 3px;
        }
      }
    }
  }

  .amexfee-label {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;

    .text-label,
    .price-label {
      font-weight: normal;
      z-index: 1;
    }
  }
}

.cart-totals .table-wrapper .table,
.opc-block-summary .opc-sidebar-shipping {
  .amexfee-collapsible-block {
    display: none;
  }

  .amexfee-totals-details {

    .mark {
      text-align: left;
      font-weight: normal;
    }
  }
}


.page-layout-checkout {

  .amexfee-block-content {
    padding: 1rem;
    border: none;
    border-bottom: 1px solid black;
    border-radius: 0;
  }
}