:root {
  @include fontSize(16px);
}

body {
  font-family: $inter;
  color: get-color(ui-dark, base);

  * {
    outline: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1 {
  @include mainHeader;
}

h2 {
  @include subHeader;
}

h3 {
  @include thirdHeader;
}

h4 {
  @include forthHeader;
}

h5 {
  @include fifthHeader;
}

h6 {
  @include sixthHeader;
}

p {
  @include paragraph;

  &.smallParagraph {
    @include paragraphSmall;
  }

  &.largeParagraph {
    @include paragraphLarge;
  }
}

small {
  @include paragraphSmall;
}

address {
  @include fontSize(14px);
  font-style: normal;
  line-height: 1.6;
  color: get-color(ui-dark, base);
}

dl {
  display: inline-block;
  margin: 0;
}

dt {
  @include fontSize(14px);
  width: auto;
  color: $textLight;
  font-weight: $font-bold;
  letter-spacing: 0;
  line-height: 22px;
}

dd {
  @include fontSize(14px);
  width: auto;
  color: $textLight;
  letter-spacing: 0;
  line-height: 22px;
}

dt,
dd {
  display: inline-block;
  margin: 0;
}

label {
  @include fontSize(14px);
  color: $headingText;
  letter-spacing: 0;
  line-height: 22px;
  font-weight: $font-regular;
}

a {
  @include fontSize(14px);
  text-decoration: none;
  color: $headingText;
  letter-spacing: 0;
  line-height: 22px;

  &.hovered,
  &:hover {
    color: $primary;
    text-decoration: underline;
  }
}

ul,
ol {
  padding-left: 20px;
  margin: 0;

  li {
    @include fontSize(14px);
    letter-spacing: 0;
    line-height: 22px;
  }
}

.my-account-block .block-title {
  @include mainHeader;
  margin: 32px;

  @include breakpoint($desktop) {
    margin-bottom: 64px;
  }
}

figure {
  figcaption {
    @include fontSize(14px);
  }
}

blockquote {
  @include fontSize(16px);
  border-left: solid 5px get-color(ui-red, base);
  padding: 20px;
  line-height: 22px;
}

.page-title-wrapper {
  @extend %vertical-spacer-bottom;
  h1 {
    @include fontSize(28px);
    margin: 0 0 48px;
    line-height: 1;
    @include breakpoint($tablet) {
      @include fontSize(28px);
      margin: 0 0 48px;
    }
    @include breakpoint($desktop-mid) {
      @include fontSize(32px);
      margin: 0 0 64px;
    }
  }
}

.note {
  @include fontSize(14px);
  margin: 16px 0 0;
}

.loading-mask {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, .4);
  z-index: 9;
  .loader {
    width: 300px;
    text-align: center;
    position: absolute;
    left: 50%;
    right: 50%;
    top: 50%;
    bottom: 50%;
  }
}

.action.skip {
  @extend %visually-hidden;
}

.clearfix::after {
  content: " ";
  clear: both;
  display: table;
}
