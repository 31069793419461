// Generic styles for modals.

//
// START
// Do not remove or override this block
// JS will not remove the _has-modal class from the body if removed.
// Modals will then fail after first use.
//

.modal-slide .modal-inner-wrap,
.modal-popup .modal-inner-wrap {
  opacity: 1;
}

//
// END
//

.modal-slide,
.modal-popup {
  width: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  padding: 0;
  bottom: 0;
  // transform: translateX(100%); - not sure if this is needed still. Might be an IE11 thing.
  transform: translate3d(100%, 0, 0);
  transition: transform 600ms ease;
  z-index: 1010;
  min-height: 100vh;
  height: 100vh;
  background: $white;
  outline: none;

  //@include breakpoint(500px) {
  max-width: 480px; // so its never wider than the mobile design
  //}
}

.modal-slide._show,
.modal-popup._show {
  width: 100%; // needs !important here to override the inline style that is there on page load
  // transform: translateX(0);
  transform: translate3d(0, 0, 0);
  overflow: auto;
  visibility: visible; // needs !important here to override the inline style that is there on page load
  opacity: 1; // needs !important here to override the inline style that is there on page load
}

.modal-slide._noshow,
.modal-popup._noshow {
  width: 0; // no !important here
  visibility: hidden;
  opacity: 0;
}

.modals-overlay {
  background: rgba(0, 0, 0, .4);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.modal-header {
  background: get-color(ui-dark, dark-90);
  color: get-color(ui-white);
  padding: 0 24px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  height: 64px;

  .modal-title {
    @include fontSize(16px);
    font-weight: $font-bold;
    display: inline-block;
    line-height: 64px;
    height: 64px;
    color: $white;
    margin-bottom: 0;
    margin-top: 0;
  }

  .action-close {
    @include fontSize(16px);
    color: get-color(ui-white);
    background: transparent;
    width: 24px;
    height: 24px;
    align-self: center;
    margin: 20px 0;
    padding: 0;

    &::before {
      content: "\f00d";
      font-family: "Font Awesome 5 Pro";
      font-weight: $font-regular;
    }

    span {
      @extend %visually-hidden;
    }
  }
}

.modal-content {
  padding: 0;
}

.modal-footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  padding: 0 24px;

  .action.secondary {
    margin-left: 16px;
  }
}

.modal-popup.confirm {

  &._show {

    @include breakpoint($desktop) {
      min-height: auto;
    }
  }

  & + .modals-overlay {
    z-index: 1195; // needs important to override inline style added by js. And needs to be slightly less than the modal, but still higher than the other elements
  }

  .modal-header {

    .action-close {
      margin-left: auto;
    }
  }

  .modal-content {
    padding: 16px;
  }

  .modal-footer {

    @include breakpoint($desktop) {
      padding: 0 32px;
    }
  }

  .action-primary {
    order: 1;
    margin-right: 16px;
  }

  .action-secondary {
    order: 2;
  }
}

// Add to basket popup
.modal-addtobasket {
  max-width: 868px;
  background: transparent;

  &._show {
    top: 50%;
    left: 0;
    margin-top: -150px;
    @include breakpoint($desktop-mid) {
      left: 50%;
      margin-left: -434px;
    }
  }

  .modal-inner-wrap {
    max-width: 380px;
    margin: 0 16px;
    background: get-color(ui-white);
    @include breakpoint($tablet) {
      margin: 0 auto;
    }
    @include breakpoint($desktop-mid) {
      margin: 0;
      max-width: 868px;
    }
  }
}

.add-to-basket-modal {

  .modal-title {
    i {
      margin: 0 16px 0 0;
    }
  }

  .modal-content__footer {
    margin: 8px 0 0;
    padding: 0;
    display: block;
    @include breakpoint($desktop-mid) {
      display: flex;
      justify-content: space-between;
    }

    a,
    button {
      display: block;
      width: 100%;
      @include breakpoint($desktop-mid) {
        width: 402px;
      }
      text-align: center;
    }
    button {
      margin: 16px 0 0;
      padding: 16px 32px;
    }
  }

  .modal-content {
    padding: 24px;

    &__product {
      border: solid 1px get-color(ui-dark, dark-20);
      border-radius: 4px;
      padding: 32px;
    }

    &__product--text {
      @include breakpoint($desktop-mid) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    &__product--image {
      img {
        width: 64px;
        margin: 0 16px 0 0;
        float: left;
      }
    }

    &__product--name {
      @include fontSize(16px);
      max-width: 426px;
    }

    &__product--price {
      font-weight: $font-bold;
      margin: 16px 0 0;
      @include breakpoint($desktop-mid) {
        text-align: right;
        margin: 0;
      }
      .price-final_price {
        width: 100%;
      }
    }
  }

}
