.login-container {
  display: flex;
  flex-flow: column nowrap;
  margin: 0 0 64px;
  @include breakpoint($tablet) {
    flex-flow: row nowrap;
    justify-content: space-between;
    margin: 0 0 80px;
  }
  .block-customer-login,
  .block-new-customer {
    width: 100%;
    flex-basis: span(4);
    border-radius: 4px;
    padding: 32px;
    @include breakpoint($tablet) {
      flex-basis: span(8);
    }
  }
  .block-new-customer {
    background: get-color(ui-dark, dark-10);
    padding: 32px;
    margin: 0;
    @include breakpoint($tablet) {
      margin: 0 0 0 16px;
    }
    .block-title {
      @include fontSize(24px);
      font-weight: $font-bold;
      line-height: 32px;
      color: $headingText;
      margin-bottom: 32px;
      @include breakpoint($desktop) {
        @include fontSize(32px);
        line-height: 40px;
      }
    }
    .block-content {
      @include fontSize(14px);
      line-height: 22px;
      border: 0;
      &,
      a {
        text-decoration: none;
      }
      p {
        @include fontSize(14px);
      }
      .actions-toolbar {
        margin: 43px 0 0;
      }
      .action {
        //@extend button;
        margin-top: 32px;
        &.create {
          //@extend .action-register;
          text-align: center;
        }
      }
    }
  }
}
