.page-print {
  .page-main {
    width: 842px;
    margin: 0 auto;
    .action.nav-toggle,
    .euronics,
    .phone,
    .my-account,
    .page-title {
      display: none;
    }
    .order-totals {
      td {
        display: table-cell;
      }

      td:before {
        display: none;
      }

      tr {
        border-bottom: solid 1px #dfe3e8;
      }

      th {
        font-weight: normal;
        padding: 10px 0 0;
      }

      tr:first-of-type,
      td:first-of-type {
        border-top: unset;
      }
    }
    .block-order-details-view {
      .block-content {
        display: flex;
      }
      .box {
        flex-basis: calc(100% / 2);
      }
    }
  }
}

@media print {

}
