.block.filter {

  .filter-title,
  .filter-subtitle {
    @extend %visually-hidden;
  }

  .filter-options {
    display: flex;
    flex-flow: column nowrap;
  }

  .filter-options-title {
    @include fontSize(14px);
    line-height: 22px;
    font-weight: $font-bold;
    color: $text;
    padding: 19px 24px;
    box-shadow: 0 -1px 0 0 $border;
    position: relative;

    @include breakpoint($desktop) {
      padding: 13px 8px;
    }

    &:hover {
      cursor: pointer;
      background-color: $blockBg;
    }

    &::after {
      @include fontSize(16px);
      content: "\f067";
      font-family: "Font Awesome 5 Pro";
      font-weight: 300;
      position: absolute;
      right: 24px;
      top: 16px;
      z-index: 1;

      @include breakpoint($desktop) {
        right: 8px;
        top: 12px;
      }
    }

    &.filter-open::after {
      @include fontSize(16px);
      content: "\f068";
      font-family: "Font Awesome 5 Pro";
      font-weight: 300;
    }

    &.filter-open:hover {
      background-color: $white;
    }
  }

  .filter-options-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 600ms;

    &.filter-open {
      max-height: 1000px; // arbitrary large number to trigger the animation

      @include breakpoint($desktop) {
        margin-top: 3px;
      }
    }
    .block.price-slider {
      width: 90%;
      margin: 0 auto;
      padding-bottom: 16px;
      .js-price-slider {
        .price-bar {
          .js-bar.ui-slider {
            height: 15px;
            .ui-slider-range {
              position: absolute;
              background-color: #ef1c24;
              border-radius: 10px;
              height: 15px;
            }
            .ui-slider-handle {
              width: 15px;
              height: 15px;
              margin: 0;
              border: solid 1px #ffff;
              background-color: #ef1c24;
              &:last-child {
                margin-left: -10px;
              }
            }
          }
        }
      }
    }
    @include breakpoint($desktop) {
      .block.price-slider {
        width: 95%;
        .js-price-slider {
            .price-min::before {
              content: "From: ";
            }
            .price-max::before {
                content: "To: ";
            }
            .price-bar {
            .js-bar.ui-slider {
              height: 10px;
              .ui-slider-handle {
                width: 10px;
                height: 10px;
              }
              .ui-slider-range {
                height: 10px;
              }
            }
          }
        }
      }
    }
  }

  .items {
    padding: 0 24px 24px;
    list-style: none outside;

    @include breakpoint($desktop) {
      padding: 0 8px 17px;
    }

  }

  .item {
    margin-bottom: 6px;

    a {
      padding: 6px 0;
      position: relative;
      display: flex;
      align-items: center;

      &:hover {
        color: $text;
        text-decoration: none;
        border-color: $primary;
      }

      &::before {
        content: "";
        display: inline-block;
        margin: 0 8px 0 0;
        width: 20px;
        height: 20px;
        border: solid 1px get-color(ui-dark, dark-20);
        border-radius: 4px;
        box-shadow: 0 1px 2px 0 rgba(35, 31, 32, .1);
      }

      .count {
        margin: 0 0 0 4px;
      }
    }
  }

  .swatch-attribute-options {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    padding: 0 24px 24px;

    @include breakpoint($desktop) {
      padding: 0 50px 17px 0;
    }

    .swatch-option-link-layered {
      margin: 0 6px 6px 0;

      &:hover {
        cursor: pointer;
        text-decoration: none;
      }
    }

    .swatch-option.text {
      padding: 4px 8px;
    }
  }

  .swatch-layered.color {

    .swatch-option-link-layered {
      border: 1px solid $fieldBorder;
      border-radius: 50%;

      &:hover {
        border-color: $text;
      }
    }
  }

  .filter-current {
    padding: 16px 24px;
    color: $text;
    @include breakpoint($desktop) {
      padding: 0 0 16px;
    }

    .filter-current-subtitle {
      display: none;
    }

    .items {
      padding: 0;
    }

    .item {
      border: 1px solid get-color(ui-dark, dark-20);
      box-shadow: 0 1px 2px 0 rgba(35, 31, 32, .1);
      padding: 8px 14px 8px 8px;
      border-radius: 2px;

      @include breakpoint($desktop) {
        display: block;
      }

      span {
        display: inline-block;
      }

      a {
        display: inline-block;
        border: 0;
        background: transparent url(../images/icons/icon-close-dark.svg) 50% 50% no-repeat;
        background-size: 12px;
        width: 22px;
        height: 22px;
        padding: 0;
        border-radius: 0;
        vertical-align: bottom;

        &::before {
          display: none;
        }

        span {
          @extend %visually-hidden;
        }
      }
    }

    .filter-label {
      font-weight: $font-bold;
      margin-right: 8px;

      &::after {
        content: ":";
      }
    }
  }

  .filter-clear {
    display: none; // not on design - need to check that this was deliberate and not an omission
  }
}

.modal-filter-by {
  .modal-header {
    h1::before {
      @include fontSize(16px);
      content: "\f1de";
      font-family: "Font Awesome 5 Pro";
      font-weight: 300;
      color: get-color(ui-white);
      margin: 0 18px 0 0;
    }
  }
}
