.styleguide {

  &__header {
    padding: 25px;
    background: $white;
    color: $black;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    border-bottom: solid 1px #e4e7eb;
    box-shadow: 3.716px 3.346px 40px rgba(43, 43, 43, .1);

    img {
      width: 157px;
    }

    span {
      padding: 0 0 0 50px;
    }

  }

  &__wrapper {
    display: flex;
    flex-direction: row;
  }

  &__menu {
    flex-basis: 300px;
    padding: 30px 30px 30px 0;
    background: #ededed;
    border-right: solid 1px #e4e7eb;
    box-shadow: 3.716px 3.346px 40px rgba(43, 43, 43, .1);

    ul {
      position: fixed;
      list-style: none;
      margin: 0;
      padding: 0;

      a {
        display: block;
        padding: 10px 0 10px 30px;
        border-left: solid 5px #ededed;
        &:hover {
          font-weight: $font-bold;
          text-decoration: none;
          border-left: solid 5px get-color(ui-red, base);
        }
      }

    }
  }

  &__mainbody {
    flex-basis: calc(100% - 200px);
    padding: 30px 30px 0 30px;

    .section {
      margin: 0 0 50px;

      header {
        @include fontSize(20px);
        border-bottom: solid 1px $background-mid;
        margin: 0 0 50px;
        padding: 0 0 15px;
        font-weight: $font-bold;
      }

      &--heading {
        margin: 30px 0;
      }

      code {
        @include fontSize(14px);
        border: solid 1px $background-mid;
        padding: 10px;
        color: $error;
        background: $background-light;
        display: block;
        margin: 20px 0;
        line-height: 22px;
      }

      .swatch {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &-item {
          flex-basis: calc(20% - 5px);

          .colour-swatch {
            width: 150px;
            height: 150px;
            display: block;
            margin: 20px 0;

            &.red-base {
              background: get-color(ui-red, base);
            }

            &.red-90 {
              background: get-color(ui-red, red-90);
            }

            &.red-80 {
              background: get-color(ui-red, red-80);
            }

            &.dark {
              background: get-color(ui-dark, base);
            }

            &.dark-90 {
              background: get-color(ui-dark, dark-90);
            }

            &.dark-80 {
              background: get-color(ui-dark, dark-80);
            }

            &.dark-70 {
              background: get-color(ui-dark, dark-70);
            }

            &.dark-20 {
              background: get-color(ui-dark, dark-20);
            }

            &.dark-10 {
              background: get-color(ui-dark, dark-10);
            }

            &.white {
              background: get-color(ui-white);
            }

            &.green {
              background: get-color(ui-green);
            }

          }

        }

      }

    }

  }

}
