//
// Layout "resets"
//

body {
  box-sizing: border-box;

  * {
    box-sizing: inherit;
  }
}


//
// 100% width page blocks
// With _no_ gutter
//

%full-width-no-buffer,
.page-footer {
  width: 100%;
  max-width: 100%;
  padding: 0;
}

//
// 100% width page blocks
// _With_ outer gutter/buffer
//

%full-width-with-buffer,
.page-header,
.panel.wrapper,
.page-main {
  width: 100%;
  max-width: 100%;
  padding: 0 var(--window-buffer);
}

//
// Constrained page blocks
// These are the containers for Alt Grid to make its calculations
// Keep adding containers here or set them in individual partials as required
// @extend %grid-container, or,
// @include container();
//

%grid-container,
.header.content,
.footer.content,
.columns,
.page-title-wrapper,
.newsletter__container,
.usps,
.category-cms,
.breadcrumbs,
.homepage-banner__container,
.block-viewed-products-grid,
.reviews-slider-wrapper,
.promo-slots,
.block-products-list,
.popular-categories__wrapper {
  width: 100%;
  max-width: 100%;
  padding: 0;
  @include breakpoint($desktop-mid) {
    max-width: var(--grid-max);
    margin: 0 auto;
    padding: 0;
  }
}

.page-layout-2columns-left {

  .columns {
    display: flex;
    justify-content: space-between;
  }

  .column.main {
    flex-basis: 100%;
    @include breakpoint($desktop-mid) {
      flex-basis: span(11);
      order: 2;
    }
  }
  .sidebar {
    @include breakpoint($desktop-mid) {
      flex-basis: span(5);
      order: 1;
      margin: 0 32px 0 0;
    }
  }
}

.page-main::after {
  content: "";
  clear: both;
  display: table;
}


// Vertical spacers might be more useful as mixins
//
%vertical-spacer-top,
.vertical-spacer-top {
  margin-top: 24px;

  @include breakpoint($desktop-mid) {
    margin-top: 48px;
  }
}

%vertical-spacer-bottom,
.vertical-spacer-bottom {
  margin-bottom: 24px;

  @include breakpoint($desktop-mid) {
    margin-bottom: 48px;
  }
}
