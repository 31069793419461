//
// Oringal colours I was working with... rename and add if needed
//

$ui-colours: (
  ui-red: (
    base: #EF1C24,
    red-90: #F2333A,
    red-80: #F34950,
    red-10: #ffe5e6
  ),
  ui-dark: (
    base: #231F20,
    dark-90: #393536,
    dark-80: #4F4C4D,
    dark-75: #767676,
    dark-70: #656263,
    dark-20: #D3D2D2,
    dark-10: #F6F6F6,
    dark-05: #f4f4f4,
    dark-0: #cdcccc
  ),
  ui-success: (
    base: #155724,
    success-50: #44cf6c,
    success-40: #1ac967,
    success-30: #c3e6cb,
    success-20: #d4edda,
    success-10: #e8f9ee
  ),
  ui-error: (
    base: #721c24,
    error-30: #f8d7da,
    error-20: #f8d7da
  ),
  ui-green: #09812B,
  ui-greenssl: #1ac967,
  ui-white: #ffffff,
  ui-blue: #1a7fcc,
  ui-black: #000000,
  ui-gold: #D2BF37
);

$background-dark: get-color(ui-dark, base);
$background-mid: #929eaa;
$background-light: #F4F6F8;

$primary: #231F20;
$accent: #e0f5f5;

$white: #fff;
$black: get-color(ui-dark, base);

$fieldBorder: #c4cdd5;
$fieldSelected: #47C1BF;
$fieldErrorShadow: #FBEAE6;

$border: #dfe3e8;
$headingText: $black;
$text: get-color(ui-dark, base);
$textLight: get-color(ui-dark, base);
$shade: #F4F6F8;
$blockBg: #F9FAFB;

$error: #EF1C24;
