.stock-status {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;

    &__icon {
        width: 20px;
        height: 20px;
        margin-right: 0.5rem;
        font-size: 16px;
        text-align: center;
    }

    &__label {
        font-weight: bold;
        color: get-color(ui-dark, dark-80);
    }

    &--available {
        .stock-status__icon {
            color: get-color(ui-green);
        }
    }

    &--unavailable {
        .stock-status__icon {
            color: get-color(ui-error, base);
        }
    }
}